<template>
  <div class="spinner"></div>
  <div class="spinner-status mb-6" role="status">{{ $t('formular.document.upload-in-progress') }}</div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
