<template>
  <div :class="{ 'form-error': visFejl }" class="form-group">
    <template v-if="textLabelOverride">
      <FeltLabel
        :for-id="dropdownId"
        :label="textLabelOverride"
        :name="name"
        :rules="rules"
        :disabled="disabled"
        :er-e-forms="erEForms"
        :show-hint="showHint"
        :show-tooltip="showTooltip"
        :force-vis-obligatorisk="forceVisObligatorisk"
        :required="inputUtil.isRequired(props.rules as Record<string, unknown>)" />
    </template>
    <template v-else-if="showLabel">
      {{ $t(`${textPrefix}${textLabelAppend}`) }}
    </template>
    <span v-if="visFejl" :data-cy="dropdownId + '-error'" :id="dropdownId + '-error'" class="form-error-message">
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span>
      <template v-if="extraError">
        <span>{{ $t(extraError) }}</span>
      </template>
      <template v-else>
        <span v-for="e in errors" :key="e">
          {{ $t(customErrorMessages ? textPrefix : 'input' + '.' + e) }}
        </span>
      </template>
    </span>
    <select
      :value="inputValue"
      :name="name"
      :class="['form-select', selectClass, delAfVaerdiMedEnhedFelt ? 'del-af-vaerdi-med-enhed-felt' : '']"
      :disabled="disabled"
      :id="dropdownId"
      :data-cy="name"
      @change="handleOnChange"
      @blur="handleOnBlur">
      <option
        v-for="option in options"
        :key="option.key"
        :label="option.label != null ? (brugi18n || option.label == 'dropdown.default-label' ? $t(option.label) : option.label) : option.key"
        :value="option.value">
        {{ option.label != null ? (brugi18n || option.label == 'dropdown.default-label' ? $t(option.label) : option.label) : option }}
      </option>
    </select>
  </div>
</template>
<script lang="ts" setup>
import { RuleExpression, useField } from 'vee-validate';
import type { PropType } from 'vue';
import { computed, onMounted, ref } from 'vue';

import FeltLabel from '@/main/components/input/FeltLabel.vue';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { inputUtil } from '@/main/utils/input-util';

const props = defineProps({
  id: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  textPrefix: {
    type: String,
    default: ''
  },
  erEForms: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array as PropType<DropdownOption[]>,
    required: true
  },
  preselectedValue: {
    type: Object as PropType<DropdownOption>,
    default: null
  },
  label: {
    type: Boolean,
    default: true
  },
  textLabelAppend: {
    type: String,
    default: '.label',
    required: false
  },
  textLabelOverride: {
    type: String,
    default: null,
    required: false
  },
  brugi18n: {
    type: Boolean,
    default: true
  },
  selectClass: {
    type: String,
    default: ''
  },
  rules: {
    type: Object as PropType<RuleExpression<unknown>>,
    default: {} as RuleExpression<unknown>
  },
  disabled: {
    type: Boolean,
    default: false
  },
  customErrorMessages: {
    type: Boolean,
    default: false
  },
  extraError: {
    type: String as PropType<string>,
    default: undefined
  },
  showHint: {
    type: Boolean,
    default: true
  },
  showTooltip: {
    type: Boolean,
    default: true
  },
  delAfVaerdiMedEnhedFelt: {
    type: Boolean,
    default: false
  },
  showLabel: {
    type: Boolean,
    default: true
  },
  forceVisObligatorisk: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([StandardEmitEvent.CHANGE]);
const visFejl = computed(() => inputUtil.skalViseFejlPaaEFormsFelt(props.extraError, props.erEForms, props.rules, meta.touched, errors.value));
const rules = ref(props.rules);

const name = ref(props.name);
const {
  errors,
  meta,
  value: inputValue,
  setValue,
  setErrors,
  handleBlur,
  handleChange,
  validate
} = useField(name, rules.value, {
  validateOnValueUpdate: true
});

const dropdownId = ref(props.id ?? `${props.name}-id`);

onMounted(() => {
  if (props.preselectedValue) {
    setValue(props.preselectedValue.value, false);
  } else {
    setValue(props.options ? props.options[0].value : null, false);
  }
});

function handleOnChange(e: Event) {
  validate();
  handleChange(e, true);
  emit(StandardEmitEvent.CHANGE, inputValue.value);
}

function handleOnBlur(e: Event) {
  handleBlur(e, true);
}

defineExpose({
  inputValue,
  validate,
  setErrors,
  setValue
});
</script>
<style lang="scss">
.form-select:disabled {
  background-image: none;
  color: #1a1a1a !important;
}
</style>
