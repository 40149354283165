import { AxiosError, AxiosResponse } from 'axios';

import { GemtSoegningDTO, SoegeresultatDto, SoegningQueryDto } from '@/main/models/generated';
import { getGemtSoegningController, HentAlleResult } from '@/main/services/generated/gemt-soegning-controller/gemt-soegning-controller';
import { getSoegeresultatController } from '@/main/services/generated/soegeresultat-controller/soegeresultat-controller';

import { Komponent } from '../enums/komponent.enum';
import AuthService from './s9-oidc/auth.service';

export class SoegningService {
  public async hentSoegeresultater(queryDto: SoegningQueryDto): Promise<SoegeresultatDto> {
    try {
      const controller = getSoegeresultatController();
      if (queryDto.pagineringDto) {
        if (Number.isNaN(queryDto.pagineringDto.maksElementer)) {
          queryDto.pagineringDto.maksElementer = 25;
        }
        if (Number.isNaN(queryDto.pagineringDto.aktuelSide)) {
          queryDto.pagineringDto.aktuelSide = 1;
        }
      }
      const axiosResponse = await controller.hentSoegeresultater(queryDto, this.config);

      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error fetching search results:', error);
      }
      throw error;
    }
  }

  public async gemNySoegning(soegningDto: GemtSoegningDTO): Promise<AxiosResponse> {
    try {
      const controller = getGemtSoegningController();
      return await controller.gemNy<AxiosResponse>(soegningDto, this.config);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error saving new search:', error);
      }
      throw error;
    }
  }

  public async hentAlle(): Promise<GemtSoegningDTO[]> {
    try {
      const controller = getGemtSoegningController();
      const axiosResponse = await controller.hentAlle<HentAlleResult>(this.config);
      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error fetching all saved searches:', error);
      }
      throw error;
    }
  }

  public async slet(id: number): Promise<boolean> {
    try {
      const response: AxiosResponse<void> = await getGemtSoegningController().slet(id, this.config);
      console.log(response);
      return true;
    } catch (e) {
      console.error(`Slet fejlede for id: ${id}`, e);
      return false;
    }
  }

  public async afmeld(navn: string, key: string): Promise<boolean> {
    try {
      const response: AxiosResponse<void> = await getGemtSoegningController().afmeld(navn, key, this.config);
      console.log(response);
      return true;
    } catch (e) {
      console.error(`Afmeld fejlede for navn: ${ navn } & key: ${ key }`, e);
      return false;
    }
  }

  private get config() {
    return AuthService.getConfig(Komponent.Soegning, false);
  }
}
export const soegningService = new SoegningService();
