<template>
  <div v-show="elementVisible && !erLoadet">
    <div class="spinner"></div>
    <div class="spinner-status" role="status">{{ $t(loadingText) }}</div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
  loadingText: {
    default: '',
    required: false,
    type: String
  },
  timerLength: {
    type: Number,
    default: 0,
    required: false
  },
  erLoadet: {
    default: false,
    required: false,
    type: Boolean
  }
});

const elementVisible = ref(false);

onMounted(() => {
  setTimer(props.timerLength);
});

function setTimer(timerLength: number) {
  setTimeout(() => {
    elementVisible.value = true;
  }, timerLength);
}
</script>
