<template>
  <div class="modal-body" data-cy="login-udloeber-modal">
    <ContentfulRigTekst :document="$tm('login.modal.login-udloeber.rig-tekst')" />
  </div>
  <div class="modal-footer">
    <button @click="onLogin()" class="button button-primary mt-4" data-cy="login-udloeber-fortsaet" type="button">
      <svg class="icon-svg" focusable="true" aria-hidden="true">
        <use xlink:href="#lock" />
      </svg>
      {{ $t('login.modal.login-udloeber.fortsaet') }}
    </button>
    <button @click="onSaveAndLogout()" class="button button-secondary logout-btn mt-4" data-cy="login-udloeber-logout" type="button">
      <svg class="icon-svg" focusable="true" aria-hidden="true">
        <use xlink:href="#lock-open" />
      </svg>
      {{ $t('login.modal.login-udloeber.gem-og-logud') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { bekendtgoerelseKladdeService } from '@/main/services/eforms/bekendtgoerelse-kladde.service';
import { AUTOLOGOUT_LOCALSTORAGE_KEY, getAutologoutTimeSeconds } from '@/main/services/s9-oidc/auth.config.service';
import { authState } from '@/main/stores/auth.state';
import { useEformsModelStore } from '@/main/stores/eforms-model.state';
import { useModalStore } from '@/main/stores/modal.state';

let timeoutId: ReturnType<typeof setTimeout> | undefined;
const modalStore = useModalStore(window.pinia);

onMounted(() => {
  const millisIndtilAutoLogud = ((authState.state?.user?.expires_in ?? 0) - getAutologoutTimeSeconds()) * 1000;
  console.log(`Logger automatisk ud om ~${millisIndtilAutoLogud} ms`);
  if (millisIndtilAutoLogud > 0) {
    timeoutId = setTimeout(async () => {
      console.log('Logger automatisk ud pga. timeout.');
      localStorage.setItem(AUTOLOGOUT_LOCALSTORAGE_KEY, 'true');
      await onSaveAndLogout();
    }, millisIndtilAutoLogud);
  }
});

async function onSaveAndLogout() {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  const eformsModelStore = useEformsModelStore(window.pinia);
  if (eformsModelStore.bekendtgoerelseNoegle) {
    await bekendtgoerelseKladdeService.forceOpdaterKladde(eformsModelStore.bekendtgoerelseNoegle);
  }
  await authState?.state
    ?.logout()
    .then(x => {
      modalStore.closeModal();
    })
    .catch(err => {
      console.error(err);
      modalStore.closeModal();
    });
}

function onLogin(loginType: 'mitId' | 'eidNatural' | 'eidLegal' | 'ssi' | 'default' = 'default') {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  localStorage.setItem('fornyelseTidspunkt', `${Date.now()}`);

  sessionStorage.setItem('loginType', loginType);

  authState.initState().then(() => {
    const { pathname, search } = window.location;
    const basePath = pathname.startsWith('/udbud') ? pathname.replace('/udbud', '') : pathname;
    const redirectPath = `${basePath}${search}`;
    localStorage.removeItem('authPersistence');
    authState?.state?.login({ redirectPath }).catch(err => {
      console.error(err);
      modalStore.closeModal();
    });
  });
}
</script>
<style scoped lang="scss">
@import 'src/main/styles/_modal';
</style>
