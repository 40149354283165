import { defineStore } from 'pinia';

import { Komponent } from '@/main/enums/komponent.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { CodeValue, Field } from '@/main/models/generated';
import { getSdkController } from '@/main/services/generated/sdk-controller/sdk-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { useEformsModelStore } from '@/main/stores/eforms-model.state';
import { useSprogStore } from '@/main/stores/sprog.state';

import { SprogLocale } from '../enums/sprog.enum';

export interface CachedEFormsKodelisteVaerdier {
  codeListId: string;
  kodelisteVaerdier: CodeValue[];
  promise: Promise<void> | null;
}
export interface EformsKodelistStoreIndhold {
  sprog: string;
  vaerdier: CachedEFormsKodelisteVaerdier[];
}

export const useEFormsKodelisterStore = defineStore('eformsKodelister', {
  state: () => ({
    cachedSprog: [] as EformsKodelistStoreIndhold[]
  }),
  actions: {
    async hentEFormsKodelister(field: Field, noticeTypeContent?: KonfigureretContent, includeDefault = false): Promise<DropdownOption[]> {
      const id = field.codeList?.value.id;
      if (!id) {
        throw new Error('Mangler id ...');
      }
      return await this.hentEFormsKodelisterFraKodelisteId(id, noticeTypeContent, includeDefault);
    },

    async hentKodelisteVaerdierMaaskeFraCache(sprog: SprogLocale, codeListId: string): Promise<CodeValue[]> {
      let cachedSprog = this.cachedSprog.find(x => x.sprog === sprog);
      if (cachedSprog) {
        const cachedListed = cachedSprog?.vaerdier.find(x => x.codeListId === codeListId);
        if (cachedListed) {
          if (cachedListed.promise !== null) {
            await cachedListed.promise;
          }
          return cachedListed.kodelisteVaerdier;
        }
      } else {
        cachedSprog = {
          sprog: sprog,
          vaerdier: []
        } as EformsKodelistStoreIndhold;
        this.cachedSprog.push(cachedSprog);
      }

      const eformsModelStore = useEformsModelStore();
      const sdkVersion = eformsModelStore.$state.sdkVersion;
      const config = AuthService.getConfig(Komponent.Udbud);
      const cachedkodeliste = {
        codeListId: codeListId,
        kodelisteVaerdier: [],
        promise: null
      } as CachedEFormsKodelisteVaerdier;
      cachedSprog.vaerdier.push(cachedkodeliste);
      cachedkodeliste.promise = getSdkController()
        .hentCodeList(sdkVersion, codeListId, sprog, config)
        .then(response => {
          const codes = response.data.codes;
          cachedkodeliste.kodelisteVaerdier = codes;
        })
        .finally(() => {
          cachedkodeliste.promise = null;
        });

      await cachedkodeliste.promise;
      return cachedkodeliste.kodelisteVaerdier;
    },

    async hentEFormsKodelisterFraKodelisteId(
      codeListId: string,
      noticeTypeContent?: KonfigureretContent,
      includeDefault = false
    ): Promise<DropdownOption[]> {
      const sprogStore = useSprogStore();

      try {
        const whitelist = noticeTypeContent?.codeListFilter?.whitelist;
        let kodelisteVaerdier: DropdownOption[] = [];

        if (codeListId == undefined) {
          throw new Error('Kan ikke identificere kodeliste ...');
        }

        let filteredResponse = await this.hentKodelisteVaerdierMaaskeFraCache(sprogStore.hentSprog, codeListId);

        if (whitelist != null) {
          filteredResponse = filteredResponse.filter(code => whitelist.includes(code.codeValue));
        }

        if (includeDefault) {
          kodelisteVaerdier.push({ key: 'dropdown.default-label', value: '', label: 'dropdown.default-label' });
        }

        kodelisteVaerdier = [
          ...kodelisteVaerdier,
          ...filteredResponse.map(code => ({
            key: code.codeValue,
            value: code.codeValue,
            label: code[sprogStore.hentSprog] || ''
          }))
        ];
        return kodelisteVaerdier;
      } catch (error) {
        console.error('Fejl ved hentning af codelister:', error);
        throw error;
      }
    }
  }
});
