import { SpaConfigDto } from '@/main/models/spaConfigDto';

import { SpaConfigService } from './spa_config_service/spaConfig.service';

export class EnvironmentConfigService {
  variables: SpaConfigDto = {};

  configLoaded = false;

  load(): boolean {
    try {
      if (this.configLoaded) {
        return true;
      }
      this.variables = SpaConfigService.getSpaConfig();
      this.configLoaded = true;
    } catch (error) {
      console.log('Environment Config kunne ikke hentes', error);
    }
    return this.configLoaded;
  }
}

export const environmentConfig = new EnvironmentConfigService();
