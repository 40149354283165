/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { SoegeresultatDto, SoegningQueryDto } from '../../../models/generated';

export const getSoegeresultatController = () => {
  /**
 * Ikke beskyttet da alle skal kunne søge.

 * @summary Henter søgeresultater fra Elastic.
 */
  const hentSoegeresultater = <TData = AxiosResponse<SoegeresultatDto>>(
    soegningQueryDto: SoegningQueryDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/public/soegeresultat`, soegningQueryDto, options);
  };
  return { hentSoegeresultater };
};
export type HentSoegeresultaterResult = AxiosResponse<SoegeresultatDto>;
