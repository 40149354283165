/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { DriftsmeddelelseDto, OpretDriftsmeddelelseDto } from '../../../models/generated';

export const getDriftsmeddelelseController = () => {
  /**
   * @summary Henter en specifik driftsmeddelelse.
   */
  const hentEnDriftsmeddelelse = <TData = AxiosResponse<DriftsmeddelelseDto>>(id: number, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/driftsmeddelelse/${id}`, options);
  };
  /**
   * @summary Opdaterer en specifik driftsmeddelelse.
   */
  const redigerDriftsmeddelelse = <TData = AxiosResponse<DriftsmeddelelseDto>>(
    id: number,
    opretDriftsmeddelelseDto: OpretDriftsmeddelelseDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.put(`/driftsmeddelelse/${id}`, opretDriftsmeddelelseDto, options);
  };
  /**
   * @summary Opdaterer en specifik driftsmeddelelse.
   */
  const sletDriftsmeddelelse = <TData = AxiosResponse<void>>(id: number, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/driftsmeddelelse/${id}`, options);
  };
  /**
   * @summary Henter alle driftsmeddelelser, også udgåede.
   */
  const hentAlleDriftsmeddelelser = <TData = AxiosResponse<DriftsmeddelelseDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/driftsmeddelelse`, options);
  };
  /**
   * @summary Opretter en enkel driftmeddelelse.
   */
  const opretDriftsmeddelelse = <TData = AxiosResponse<DriftsmeddelelseDto>>(
    opretDriftsmeddelelseDto: OpretDriftsmeddelelseDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/driftsmeddelelse`, opretDriftsmeddelelseDto, options);
  };
  /**
   * Kan bruges af alle brugere, også uden login - ikke-indloggede brugere skal også se driftsmeddelelser.

   * @summary Ubeskyttet endepunkt til at hente aktuelle driftsmeddelelser.
   */
  const hentAktuelle = <TData = AxiosResponse<DriftsmeddelelseDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/driftsmeddelelse:aktuelle`, options);
  };
  return { hentEnDriftsmeddelelse, redigerDriftsmeddelelse, sletDriftsmeddelelse, hentAlleDriftsmeddelelser, opretDriftsmeddelelse, hentAktuelle };
};
export type HentEnDriftsmeddelelseResult = AxiosResponse<DriftsmeddelelseDto>;
export type RedigerDriftsmeddelelseResult = AxiosResponse<DriftsmeddelelseDto>;
export type SletDriftsmeddelelseResult = AxiosResponse<void>;
export type HentAlleDriftsmeddelelserResult = AxiosResponse<DriftsmeddelelseDto[]>;
export type OpretDriftsmeddelelseResult = AxiosResponse<DriftsmeddelelseDto>;
export type HentAktuelleResult = AxiosResponse<DriftsmeddelelseDto[]>;
