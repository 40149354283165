<template>
  <div class="modal-body" data-cy="brugerprofil-oprettet-modal">
    <div>
      <RichTextRenderer v-if="$tm('brugerprofil-oprettet.rig-tekst')" :document="$tm('brugerprofil-oprettet.rig-tekst')" />
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" @click="store.closeModal()" class="button button-primary" style="margin-top: 1rem" data-cy="brugerprofil-success-fortsaet">
      {{ $t('brugerprofil-oprettet.luk-og-fortsaet.button') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

import { useModalStore } from '@/main/stores/modal.state';

const store = useModalStore(window.pinia);
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
