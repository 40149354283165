<template>
  <div class="modal-body" data-cy="gem-soegning-modal">
    <ContentfulRigTekst :document="$tm('udbudsagent.broedtekst')" />
    <div class="gray-100 p-4">
      <span class="bold">{{ $t('udbudsagent.din-soegning') }}</span>
      <ul class="ml-4">
        <li>
          <span v-if="soegning.fritekstQuery"
            >{{ $t('udbudsagent.tekstsoegning') }}
            <i data-cy="udbudsagent-fritekst-query" class="fritekst-streng">{{ soegning.fritekstQuery }}</i></span
          >
          <span v-else
            >{{ $t('udbudsagent.tekstsoegning') }} <i>{{ $t('udbudsagent.ingen-soegord-anvendt') }}</i></span
          >
        </li>
        <li>
          <span data-cy="udbudsagent-antal-filtre">{{ $t('udbudsagent.antal-filtre') }} {{ antalFiltre }} {{ $t('udbudsagent.filtre') }}</span>
        </li>
      </ul>
    </div>
    <ContentfulRigTekst :document="$tm('udbudsagent.broedtekst-to')" />
    <FormGroup ref="form" @handle-submit="gemSoegningSubmit" :show-opsummering="false">
      <span v-if="navnEksistererAllerede" class="form-error-message">{{ $t('udbudsagent.fejlbesked.navn-eksisterer-allerede') }}</span>
      <span v-if="tomtNavnFejl" class="form-error-message">{{ $t('udbudsagent.fejlbesked.tomt-navn-fejl') }}</span>
      <span v-if="generiskBackendFejl" class="form-error-message">{{ $t('soegning.fejlbesked') }}</span>
      <InputFelt
        name="gemSoegningNavn"
        ref="gemSoegningFelt"
        :text-prefix="'udbudsagent.navn.input'"
        :show-hint="false"
        rules="required|max:100"
        :maxlength="100"
        :preselected-value="soegning.fritekstQuery"
        :is-textarea="false"
        @input="handleInput" />
      <BaseRadioButtonGroup
        class="my-4"
        :options="adviseringsFrekvens"
        name="advisering-frekvens"
        :preselected-value="selectedAdviseringsFrekvens"
        @radio-button-update="handleAdviseringsFrekvens"
        :legend="$t('udbudsagent.radiobutton.legend')"></BaseRadioButtonGroup>
    </FormGroup>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      @mousedown="gemSoegningSubmit"
      @keydown.enter="gemSoegningSubmit"
      class="button button-primary"
      data-cy="gem-soegning-gem-knap">
      {{ $t('opretUdbudsagent.tekst') }}
    </button>
    <button type="button" @click="modalStore.closeModal" class="button button-secondary" data-cy="gem-soegning-annuller-knap">
      {{ $t('annuller.tekst') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import BaseRadioButtonGroup from '@/main/components/base/BaseRadioButtonGroup.vue';
import FormGroup from '@/main/components/input/FormGroup.vue';
import InputFelt from '@/main/components/input/InputFelt.vue';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { GemtSoegningDTO, GemtSoegningDTOAdvisering } from '@/main/models/generated';
import { soegningService } from '@/main/services/soegning.service';
import { useModalStore } from '@/main/stores/modal.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';

const { t } = useI18n();
const soegningStore = useSoegningValgStore(window.pinia);
const { soegning } = storeToRefs(soegningStore);
const antalFiltre = computed(() => soegningStore.totalCount);

const gemSoegningFelt = ref<HTMLInputElement | undefined>();
const navnEksistererAllerede = ref(false);
const tomtNavnFejl = ref(false);
const generiskBackendFejl = ref(false);
const modalStore = useModalStore(window.pinia);
const form = ref();
type RadioButtonValue = string | number | boolean | Record<string, any>;

const selectedAdviseringsFrekvens = ref<RadioButtonValue>(GemtSoegningDTOAdvisering.DAGLIGT);

const adviseringsFrekvens: RadioOgCheckboxModel[] = [
  { value: GemtSoegningDTOAdvisering.DAGLIGT, label: t('udbudsagent.advisering.dagligt') },
  { value: GemtSoegningDTOAdvisering.UGENTLIGT, label: t('udbudsagent.advisering.ugentligt') },
  { value: GemtSoegningDTOAdvisering.NAAR_NYT, label: t('udbudsagent.advisering.naarNyt') }
];

const handleAdviseringsFrekvens = (selectedOption: RadioButtonValue) => {
  selectedAdviseringsFrekvens.value = selectedOption as GemtSoegningDTOAdvisering;
  generiskBackendFejl.value = false;
};

const handleInput = (event: Event) => {
  navnEksistererAllerede.value = false;
  tomtNavnFejl.value = false;
  generiskBackendFejl.value = false;

  if (gemSoegningFelt.value) {
    gemSoegningFelt.value.value = (event.target as HTMLInputElement).value;
  }
};

async function gemSoegningSubmit() {
  const validationResult = await form.value?.validate();
  if (!validationResult?.valid) {
    handleValidationErrors(validationResult?.errors);
    return;
  }

  const gemtSoegningDTO = prepareGemtSoegningDTO();

  try {
    const response = await soegningService.gemNySoegning(gemtSoegningDTO);
    if (response.status === 200) {
      modalStore.openSoegningGemtModal();
    }
  } catch (error) {
    handleSubmissionError(error);
  }
}

function handleValidationErrors(errors: any) {
  if (errors?.gemSoegningNavn === 'fejlbesked.paakraevet') {
    tomtNavnFejl.value = true;
  }
}

function prepareGemtSoegningDTO() {
  const soegningQueryDTO = soegning.value;

  const gemtSoegningDTO: GemtSoegningDTO = {
    soegningNavn: gemSoegningFelt.value?.value || soegningQueryDTO.fritekstQuery!,
    query: soegningQueryDTO
  };

  gemtSoegningDTO.advisering = selectedAdviseringsFrekvens.value as GemtSoegningDTOAdvisering;
  soegningStore.setGemtSoegningNavn(gemSoegningFelt.value?.value || soegningQueryDTO.fritekstQuery!);

  return gemtSoegningDTO;
}

function handleSubmissionError(error: any) {
  const axiosError = error as AxiosError;
  if (axiosError.response) {
    switch (axiosError.response.status) {
      case 409:
        navnEksistererAllerede.value = true;
        break;
      case 400:
        tomtNavnFejl.value = true;
        break;
      default:
        generiskBackendFejl.value = true;
        break;
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../styles/_modal';
</style>
