import { CPVListeItem } from '@/main/components/eForms/formular/custom/EFormsCPVKodeVaelger.vue';
import { DropdownOption } from '@/main/models/base/DropdownOption';

export function applyFilter(cpvKodelisteVaerdier: DropdownOption[], query: string[], antalAfSoegeResultater: { value: number }): CPVListeItem {
  const cpvItemListe = cpvKodelisteVaerdier.map(
    x =>
      ({
        codeValue: x.value,
        label: x.label,
        searchLabel: x.label?.toLocaleLowerCase(),
        children: [],
        barnMatch: false,
        filterMatch: false
      } as CPVListeItem)
  );

  const root: CPVListeItem = {
    codeValue: '0000000',
    label: 'root',
    searchLabel: 'root',
    children: []
  };

  let currentParents = [root];
  let currentDepth = 0;
  let count = 0;

  for (const item of cpvItemListe) {
    if (item.codeValue.substring(2) === '000000') {
      currentParents = [root];
      currentDepth = 0;
    }
    while (!arePrefixesEqual(currentParents[currentDepth].codeValue, item.codeValue, currentDepth + 1) && currentDepth > 1) {
      currentParents.pop();
      currentDepth--;
    }

    const isMatch = query.some(q => item.codeValue.includes(q));
    const isLabelMatch = query.every(q => item.searchLabel.includes(q));

    if (isMatch || isLabelMatch) {
      item.filterMatch = true;
      count++;
      currentParents.forEach(parent => {
        parent.barnMatch = true;
      });
    } else {
      item.filterMatch = false;
    }

    currentParents[currentDepth].children.push(item);
    currentParents.push(item);
    currentDepth++;
  }

  antalAfSoegeResultater.value = count;
  return root;
}

export function arePrefixesEqual(array1: string, array2: string, prefixLength: number): boolean {
  if (prefixLength < 0 || prefixLength > array1.length || prefixLength > array2.length) {
    throw new Error('Invalid prefix length');
  }
  for (let i = 0; i < prefixLength; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }
  return true;
}

export function highlightSearchTerms(query: string[], item: CPVListeItem): string {
  let originalString = `${item.codeValue} ${item.label}`;
  for (const q of query) {
    let nextMatchOffset = 0;
    let startOfMatchIndex = -1;
    while ((startOfMatchIndex = originalString.toLocaleLowerCase().indexOf(q.toLocaleLowerCase(), nextMatchOffset)) !== -1) {
      if (startOfMatchIndex === -1) {
        // No match, break
        break;
      }
      const beforeMatch = originalString.substring(0, startOfMatchIndex);
      const replacementBefore = originalString.substring(startOfMatchIndex, startOfMatchIndex + q.length);
      const originalQueryReplaced = '<span class="highlight">' + replacementBefore + '</span>';
      const afterMatch = originalString.substring(startOfMatchIndex + q.length);

      // Næste match skal være mindst start positionen + længen af erstatningen inde.
      nextMatchOffset = startOfMatchIndex + originalQueryReplaced.length;

      originalString = beforeMatch + originalQueryReplaced + afterMatch;
    }
  }

  return originalString;
}
