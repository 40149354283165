import { AxiosResponse } from 'axios';

import { Komponent } from '@/main/enums/komponent.enum';
import { DriftsmeddelelseDto, OpretDriftsmeddelelseDto } from '@/main/models/generated';
import { getDriftsmeddelelseController } from '@/main/services/generated/driftsmeddelelse-controller/driftsmeddelelse-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';

export class DriftsmeddelelseService {
  public async hentAlleDriftsmeddelelser(): Promise<DriftsmeddelelseDto[]> {
    return (await getDriftsmeddelelseController().hentAlleDriftsmeddelelser(this.config)).data;
  }

  public async slet(id: number): Promise<boolean> {
    try {
      const response: AxiosResponse<void> = await getDriftsmeddelelseController().sletDriftsmeddelelse(id, this.config);
      console.log(response);
      return true;
    } catch (e) {
      console.error(`Slet fejlede for id: ${id}`, e);
      return false;
    }
  }

  public async opret(dto: OpretDriftsmeddelelseDto): Promise<boolean | AxiosResponse<DriftsmeddelelseDto>> {
    try {
      return await getDriftsmeddelelseController().opretDriftsmeddelelse(dto, this.config);
    } catch (e) {
      console.error(`Opret fejlede`, e);
      return false;
    }
  }

  public async rediger(id: number, dto: OpretDriftsmeddelelseDto): Promise<boolean> {
    try {
      const response: AxiosResponse<DriftsmeddelelseDto> = await getDriftsmeddelelseController().redigerDriftsmeddelelse(id, dto, this.config);
      console.log(response);
      return true;
    } catch (e) {
      console.error(`Opret fejlede`, e);
      return false;
    }
  }

  private get config() {
    return AuthService.getConfig(Komponent.Konfiguration, false);
  }
}

export const driftsmeddelelseService = new DriftsmeddelelseService();
