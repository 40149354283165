/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

export const getKonfigurationCacheController = () => {
  /**
 *    Kaldes ved ændringer til tekster i Contentful.
   Kalder alle instanser af alle servere.

 * @summary Rydder tekster for alle komponenter for at sikre, at de bygges om.
 */
  const rydAlleTeksterCache = <TData = AxiosResponse<void>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/konfigurationCache/rydAlleTeksterCache`, undefined, options);
  };
  return { rydAlleTeksterCache };
};
export type RydAlleTeksterCacheResult = AxiosResponse<void>;
