<template>
  <!-- Skip Link -->
  <router-link :to="$route.path" @click.prevent="focusPageContainer" class="skipnav">{{ $t('sr-skip-nav') }}</router-link>
  <SvgIcons />
  <GaaTilToppenKnap />
  <Header />
  <div class="page-container pt-0" tabindex="-1" :class="{ 'mobile-view': isMobile }">
    <Suspense>
      <router-view />
    </Suspense>
  </div>
  <Footer />
  <ModalWindow></ModalWindow>
</template>
<script lang="ts" setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';

import Footer from '@/main/components/Footer.vue';
import Header from '@/main/components/Header.vue';
import SvgIcons from '@/main/components/SvgIcons.vue';
import ModalWindow from '@/main/components/modals/ModalWindow.vue';

import GaaTilToppenKnap from './components/GaaTilToppenKnap.vue';
import { Visning } from './enums/visning.enum';

const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => screenWidth.value < Visning.GRID_BREAKPOINT_MD);

const updateScreenWidth = () => {
  screenWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener('resize', updateScreenWidth);
});

onUnmounted(() => {
  window.removeEventListener('resize', updateScreenWidth);
});

const focusPageContainer = () => {
  nextTick(() => {
    const pageContainer = document.querySelector('.page-container') as HTMLElement;
    if (pageContainer) {
      pageContainer.focus();
      pageContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });
};
</script>

<style lang="scss" scoped>
@import '../main/styles/main.scss';
</style>
