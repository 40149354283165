<template>
  <div class="modal-body">
    <BaseBesked
      :type="'error'"
      :titel="$t('fejlopsummering.titel')"
      v-if="errorMessages && errorMessages.length > 0"
      id="errorSummary-driftmeddelelse-sletning"
      data-module="error-summary"
      class="mt-0 mb-8">
      <ul class="alert-text nobullet-list">
        <li v-for="error in errorMessages" :key="error.message">
          <!-- //NOSONAR --><a class="function-link" :href="'#' + error.id">{{ $t(error.message) }}</a>
        </li>
      </ul>
    </BaseBesked>
    <p>{{ $t('internal.driftsmeddelelese-redigering-bekraftelse.beskrivelse') }}</p>
  </div>
  <div class="modal-footer">
    <button @click="gemDriftsmeddelelse()" class="button button-primary" type="button">
      {{ $t('internal.driftsmeddelelese-redigering-bekraftelse.rediger') }}
    </button>
    <button @click="onAnnuller()" class="button button-secondary" type="button">
      {{ $t('internal.driftsmeddelelese-redigering-bekraftelse.annuller') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import * as DKFDS from 'dkfds';
import { PropType, Ref, ref } from 'vue';

import BaseBesked from '@/main/components/base/BaseBesked.vue';
import { DriftsmeddelelseDto } from '@/main/models/generated';
import { driftsmeddelelseService } from '@/main/services/driftsmeddelelse.service';
import { useModalStore } from '@/main/stores/modal.state';

const modalStore = useModalStore(window.pinia);

const props = defineProps({
  element: {
    type: Object as PropType<DriftsmeddelelseDto>,
    required: true
  }
});

function onAnnuller() {
  const store = useModalStore(window.pinia);
  store.closeModal();
}

async function gemDriftsmeddelelse() {
  updateErrorMessages('', 'generel');
  let hentDriftsmeddelelseSuccesfuldt = null;
  // Rediger
  if (props.element.id) {
    hentDriftsmeddelelseSuccesfuldt = await driftsmeddelelseService.rediger(props.element.id, props.element);
  }
  if (!hentDriftsmeddelelseSuccesfuldt) {
    updateErrorMessages('internal.driftsmeddelelese-oprettelse.fejlbesked.generel', 'generel');
  } else {
    modalStore.closeModal();
  }
}

const errorMessages: Ref<{ id: string; message: string }[]> = ref([]);

const updateErrorMessages = (error: string, inputId: string) => {
  if (error) {
    errorMessages.value.push({ message: error, id: inputId });
  } else {
    errorMessages.value = errorMessages.value.filter(e => e.id !== inputId);
  }
};

new DKFDS.ErrorSummary(document.getElementById('errorSummary-driftsmeddelelese-redigering-bekraftelse')).init();
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
