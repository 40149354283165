<template>
  <div class="form-group" :class="{ 'form-error': visFejl || visFejlmeddelelse }">
    <FeltLabel
      :for-id="datovaelgerId"
      :label="label"
      :name="name"
      :show-hint="showHint"
      :text-prefix="textPrefix"
      :er-e-forms="erEForms"
      :force-vis-obligatorisk="forceVisObligatorisk"
      :required="inputUtil.isRequired(props.rules)" />
    <span v-if="visFejl && !visGenerelFejlmeddelse" :data-cy="datovaelgerId + '-error'" :id="datovaelgerId + '-error'" class="form-error-message">
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span>
      <template v-if="extraError">
        {{ $t(extraError) }}
      </template>
      <template v-else>
        {{ $t(errors[0]!) }}
      </template>
    </span>
    <div class="date-picker" data-dateformat="DD-MM-YYYY">
      <input
        :id="datovaelgerId"
        :name="datovaelgerId"
        :data-cy="name"
        class="form-input"
        type="text"
        :aria-label="$t(textPrefix)"
        @blur="handleInput" />
    </div>
  </div>
</template>
<script setup lang="ts">
import * as DKFDS from 'dkfds';
import { RuleExpression, useField } from 'vee-validate';
import { PropType, computed, nextTick, onMounted, ref, watch } from 'vue';

import FeltLabel from '@/main/components/input/FeltLabel.vue';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils/date-util';
import InputFormatUtil from '@/main/utils/input-format-util';
import { inputUtil } from '@/main/utils/input-util';

const props = defineProps({
  id: {
    type: String,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  bindFormat: {
    type: String,
    default: 'dd-MM-yyyy'
  },
  textPrefix: {
    type: String,
    default: ''
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  syncValue: {
    type: String,
    default: undefined
  },
  minDate: {
    type: String,
    default: ''
  },
  maxDate: {
    type: String,
    default: ''
  },
  visKontekstnaerHjaelp: {
    type: Boolean,
    default: false
  },
  visFejlmeddelelse: {
    type: Boolean,
    default: false
  },
  visGenerelFejlmeddelse: {
    type: Boolean,
    default: false
  },
  fejlmeddelelseTextkey: {
    type: String,
    default: ''
  },
  showHint: {
    type: Boolean,
    default: true
  },
  rules: {
    type: [Object, String] as PropType<RuleExpression<unknown> | undefined>,
    required: false,
    default: () => ''
  },
  extraError: {
    type: String as PropType<string>,
    default: undefined
  },
  erEForms: {
    type: Boolean,
    default: false
  },
  filterCleared: {
    type: Boolean,
    default: false
  },
  forceVisObligatorisk: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits<{
  (e: StandardEmitEvent.CHANGE, value: string | undefined): void;
  (e: StandardEmitEvent.ERROR_CHANGE, value: string): void;
  (e: StandardEmitEvent.VALID_CHANGE, value: string): void;
}>();
const datovaelgerId = ref(props.id ?? props.name + '-id');
const visFejl = computed(() => inputUtil.skalViseFejlPaaEFormsFelt(props.extraError, props.erEForms, props.rules, true, errors.value));
const name = ref(props.name);
const rules = ref(props.rules);
const soegningStore = useSoegningValgStore(window.pinia);

const {
  errors,
  value: fieldValue,
  validate,
  setValue
} = useField<string>(name, rules, {
  validateOnValueUpdate: false
});

onMounted(async () => {
  await nextTick();
  DKFDS.datePicker.init(document.body);
  const datovaelger = document.getElementById(datovaelgerId.value) as HTMLInputElement;
  if (datovaelger) {
    datovaelger.addEventListener('blur', () => handleInput(datovaelger.value));
  }
  if (props.preselectedValue) {
    setDato(props.preselectedValue!);
  }
});

watch(
  () => props.syncValue,
  () => {
    if (props.filterCleared) {
      setDato(props.syncValue);
      soegningStore.resetCurrentFilterCleared();
      emit(StandardEmitEvent.CHANGE, props.syncValue as string);
    }
    emit(StandardEmitEvent.CHANGE, props.syncValue as string);
  }
);

async function setDato(dato: string | undefined) {
  const datovaelger = document.getElementById(datovaelgerId.value) as HTMLInputElement;
  if (dato) {
    const validFormat = dateUtil.getValidDateformatFromString(InputFormatUtil.fjernWhitespace(dato));
    datovaelger.value = validFormat;
    setValue(dateUtil.setFormat(validFormat, props.bindFormat), true);
    validate().then(validation => {
      console.log('InputDatovaelger: "' + fieldValue.value + '" med ' + errors.value.length + ' fejl');
      if (validation.valid) {
        emit(StandardEmitEvent.VALID_CHANGE, fieldValue.value as string);
      } else {
        emit(StandardEmitEvent.ERROR_CHANGE, fieldValue.value as string);
      }
    });
  } else {
    datovaelger.value = '';
    setValue('', true);
  }
}

async function handleInput(date: string) {
  setDato(date);
  emit(StandardEmitEvent.CHANGE, date);
}

defineExpose({
  fieldValue
});
</script>
<style lang="scss" scoped>
@import 'src/main/styles/_datovaelger';
</style>
