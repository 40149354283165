/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Hvilket felt det sorteres efter
 */
export type PagineringDtoSorteringFelt = (typeof PagineringDtoSorteringFelt)[keyof typeof PagineringDtoSorteringFelt];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PagineringDtoSorteringFelt = {
  RELEVANCE: 'RELEVANCE',
  PUBLIKATION_DATO: 'PUBLIKATION_DATO',
  TILBUDSFRIST_DATO: 'TILBUDSFRIST_DATO'
} as const;
