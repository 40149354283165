<template>
  <div class="form-group" :class="{ 'form-error': visFejl }" :id="tidsFeltId + '-container'">
    <FeltLabel
      :for-id="tidsFeltId"
      :label="label"
      :name="name"
      :show-hint="showHint"
      :text-prefix="textPrefix"
      :er-e-forms="erEForms"
      :required="required" />
    <span v-if="visFejl" class="form-error-message">
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span>
      <template v-if="extraError">
        {{ $t(extraError) }}
      </template>
      <template v-else>
        {{ $t(customErrorMessages ? textPrefix : 'input' + '.' + errors[0]!) }}
      </template>
    </span>
    <div @change.stop="emitSelected">
      <input
        :id="tidsFeltId"
        v-model="displayValue"
        :aria-describedby="visFejl ? tidsFeltId + '-error' : undefined"
        :aria-label="$t(textPrefix)"
        :class="['form-input', inputClass]"
        :disabled="disabled"
        :name="tidsFeltId"
        :type="type"
        @change.stop="handleChange" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { RuleExpression, useField } from 'vee-validate';
import { PropType, computed, onMounted, ref, watch } from 'vue';

import FeltLabel from '@/main/components/input/FeltLabel.vue';
import { FeltBredde } from '@/main/enums/feltbredde.enum';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { dateUtil } from '@/main/utils/date-util';
import inputFormatUtil from '@/main/utils/input-format-util';
import { inputUtil } from '@/main/utils/input-util';

const props = defineProps({
  id: {
    type: String,
    default: undefined
  },
  inputClass: {
    type: String as PropType<string | FeltBredde>,
    default: FeltBredde.FELTBREDDE_XXS
  },
  label: {
    type: String,
    default: ''
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  rules: {
    type: String as PropType<RuleExpression<unknown>>,
    default: {} as RuleExpression<unknown>
  },
  textPrefix: {
    type: String,
    required: true
  },
  showHint: {
    type: Boolean,
    default: true
  },
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  customErrorMessages: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  extraError: {
    type: String as PropType<string>,
    default: undefined
  },
  erEForms: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  (e: 'change', value: string): void;
  (e: 'error-change', value: string): void;
  (e: 'valid-change', value: string): void;
}>();
const tidsFeltId = ref(props.id ?? props.textPrefix + '-id');
const visFejl = computed(() => inputUtil.skalViseFejlPaaEFormsFelt(props.extraError, props.erEForms, rules.value, true, errors.value));
const rules = ref(props.rules);
const name = ref(props.name);
const displayValue = ref('');
const {
  errors,
  value: fieldValue,
  handleChange,
  validate,
  setValue
} = useField<string>(name, rules, {
  initialValue: props.preselectedValue ? dateUtil.getValidTimeformatFromString(props.preselectedValue) : '',
  validateOnValueUpdate: false
});

onMounted(() => {
  displayValue.value = '';
  if (props.preselectedValue) {
    setTid(props.preselectedValue!);
  }
});

watch(fieldValue, () => {
  setTid(inputFormatUtil.fjernWhitespace(fieldValue.value));
  emit(StandardEmitEvent.CHANGE, fieldValue.value);
  validate().then(validation => {
    console.log('InputTidsFelt: "' + fieldValue.value + '" med ' + errors.value.length + ' fejl');
    if (validation.valid) {
      emit(StandardEmitEvent.VALID_CHANGE, fieldValue.value);
    } else {
      emit(StandardEmitEvent.ERROR_CHANGE, fieldValue.value);
    }
  });
});

async function setTid(tid: string) {
  const tidsvaelger = document.getElementById(tidsFeltId.value) as HTMLInputElement;
  const validFormat = dateUtil.getValidTimeformatFromString(tid);
  tidsvaelger.value = validFormat as string;
  displayValue.value = validFormat as string;
  setValue(validFormat as string);
}

function emitSelected(e: Event) {
  if ((e.target as HTMLInputElement).value.length > 0) {
    fieldValue.value = dateUtil.getValidTimeformatFromString((e.target as HTMLInputElement).value);
  }
  if ((fieldValue.value as string).length > 0) {
    fieldValue.value = '';
  }
}

defineExpose({
  fieldValue
});
</script>
<style lang="scss" scoped>
@import '../../styles/_input';
</style>
