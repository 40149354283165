import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';

export interface ErrorState {
  error: unknown;
  status: number;
  haandterFejlGlobalt: boolean | undefined;
}

export const useErrorStore = defineStore('Error', {
  state: (): Ref<ErrorState> =>
    ref({
      error: {} as unknown,
      status: 0,
      haandterFejlGlobalt: true
    }),
  getters: {
    hentError: state => state.error,
    hentStatus: state => state.status,
    hentHaandterFejlGlobalt: state => state.haandterFejlGlobalt
  },
  actions: {
    opdaterError(error: unknown) {
      this.error = error;
    },
    opdaterStatus(status: number) {
      this.status = status;
    },
    opdaterHaandterFejlGlobalt(value: boolean | undefined) {
      this.haandterFejlGlobalt = value;
    }
  }
});
