/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { CodeListDefinition, FieldsModel, HentOversaettelser200, NoticeType, NoticeTypeList } from '../../../models/generated';

export const getSdkController = () => {
  /**
   * @summary Henter oversættelser fra specifik SDK-version. Returnerer kun et enkelt sprog.
   */
  const hentOversaettelser = <TData = AxiosResponse<HentOversaettelser200>>(
    sdkVersion: string,
    translation: string,
    langCode: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/sdk/${sdkVersion}/translations/${translation}/lang/${langCode}`, options);
  };
  /**
   * @summary Henter listen over alle noticetypes.
   */
  const listNoticeTypes = <TData = AxiosResponse<NoticeTypeList>>(sdkVersion: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/sdk/${sdkVersion}/notice-types`, options);
  };
  /**
   * @summary Henter definitionen for en specifik noticetype.
   */
  const hentNoticeType = <TData = AxiosResponse<NoticeType>>(
    sdkVersion: string,
    noticeTypeId: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/sdk/${sdkVersion}/notice-types/${noticeTypeId}`, options);
  };
  /**
   * @summary Henter fields.json fra specifik SDK-version som FieldsModel
   */
  const hentFieldsModelJson = <TData = AxiosResponse<FieldsModel>>(sdkVersion: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/sdk/${sdkVersion}/fields`, options);
  };
  /**
   * @summary Henter kodeliste fra specifik SDK-version. Returnerer kun et enkelt sprog.
   */
  const hentCodeList = <TData = AxiosResponse<CodeListDefinition>>(
    sdkVersion: string,
    codelist: string,
    langCode: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/sdk/${sdkVersion}/codelists/${codelist}/lang/${langCode}`, options);
  };
  return { hentOversaettelser, listNoticeTypes, hentNoticeType, hentFieldsModelJson, hentCodeList };
};
export type HentOversaettelserResult = AxiosResponse<HentOversaettelser200>;
export type ListNoticeTypesResult = AxiosResponse<NoticeTypeList>;
export type HentNoticeTypeResult = AxiosResponse<NoticeType>;
export type HentFieldsModelJsonResult = AxiosResponse<FieldsModel>;
export type HentCodeListResult = AxiosResponse<CodeListDefinition>;
