import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

import { Path } from '@/main/enums/path.enum';
import { filterUtil } from '@/main/utils/filter-util';

import { routeBeforeEach } from './route-guards';
import defaultRoutes from './routes';

const routes: RouteRecordRaw[] = [...defaultRoutes];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash
      };
    }
    return { top: 0 };
  }
});

/**
 * Router guard
 */
router.beforeEach((to, from, next) => {
  if (to.path !== Path.SOEG && to.path !== Path.DETALJEVISNING) {
    filterUtil.clearFilterChild();
  }
  routeBeforeEach(to, from, next);
});

export default router;
