import { ComputedRef, computed } from 'vue';

import { ClientSuffix } from '@/main/enums/clients.enum';
import { Bruger } from '@/main/models/bruger.model';
import { authState } from '@/main/stores/auth.state';

/**
 * Returnere Bruger ud fra authState
 */
export function computedBruger(): ComputedRef<Bruger> {
  return computed(() => {
    let loginMethod = 'MITID';
    const aud = authState.state?.getProfile('aud');
    if (aud?.includes(ClientSuffix.EID_NATURAL)) {
      loginMethod = 'EIDAS_NATURAL';
    } else if (aud?.includes(ClientSuffix.EID_LEGAL)) {
      loginMethod = 'EIDAS_LEGAL';
    } else if (aud?.includes(ClientSuffix.SSI)) {
      loginMethod = 'SSI';
    }

    const bruger: Bruger = {
      loggetInd: authState.state?.isLoggedIn === true,
      privatPerson:
        aud?.includes(ClientSuffix.EID_NATURAL) ||
        (authState.state?.getProfile('dk:gov:cvr') === undefined && !aud?.includes(ClientSuffix.EID_LEGAL)),
      loginMethod: loginMethod,
      cvrNummer: authState.state?.getProfile('dk:gov:cvr'),
      navn: authState.state?.getProfile('name') ?? '',
      email: authState.state?.brugerProfilDto?.email ?? authState.state?.getProfile('dk:gov:virk:email') ?? '',
      expire: authState.state?.accessTokenExpiresAt,
      virksomhedsnavn: '',
      entitetsid: authState.state?.getProfile('sub') ?? '',
      roller: (authState.state?.getProfile<string[]>('dk:gov:virk:roles') as string[]) ?? [],
      organisationsProfil: authState.state?.brugerProfilDto?.organisationsprofilDto,
      virksomhedRolleDto: authState.state?.virksomhedRolleDto,
      brugerProfilDto: authState.state?.brugerProfilDto
    };

    return bruger;
  });
}
