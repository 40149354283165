import stringify from 'json-stable-stringify';
import { includes } from 'lodash';
import { storeToRefs } from 'pinia';
import { GenericObject } from 'vee-validate';

import FilterAnslaaetVaerdi from '@/main/components/soegning/filter/FilterAnslaaetVaerdi.vue';
import FilterCPVKoder, { SelectedCheckbox } from '@/main/components/soegning/filter/FilterCPVKoder.vue';
import FilterCVRSelector from '@/main/components/soegning/filter/FilterCVRSelector.vue';
import FilterCodelistGeneric from '@/main/components/soegning/filter/FilterCodelistGeneric.vue';
import FilterDateGeneric from '@/main/components/soegning/filter/FilterDateGeneric.vue';
import {
  CodeListFilterKonfigurationNavn,
  CustomFilterKonfigurationNavn,
  FilterKonfigurationNavn
} from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { SprogLocale } from '@/main/enums/sprog.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { FilterComponentMap } from '@/main/models/componentMap';
import { AnslaaetVaerdi } from '@/main/models/filter/anslaaetvaerdi.model';
import { Filter } from '@/main/models/filter/filter.model';
import { Publiceringsdato } from '@/main/models/filter/publiceringsdato.model';
import { Tilbudsfrist } from '@/main/models/filter/tilbudfrist.model';
import { FilterDto, FilterDtoFormularTypeItem, SoegningQueryDto } from '@/main/models/generated';
import { useEFormsKodelisterStore } from '@/main/stores/eforms-kodelister.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils//date-util';
import InputFormatUtil from '@/main/utils/input-format-util';

class FilterUtil {
  replacer = (k: string, v: any) => {
    const isArray = Array.isArray(v);
    const isChildren = Array.isArray(v) && v.every(o => JSON.stringify(o) === '{}');
    if (isArray && v.length === 0) {
      return undefined;
    }
    return isArray && isChildren ? undefined : v;
  };

  public components: FilterComponentMap = {
    [CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE]: FilterCodelistGeneric,
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE]: FilterCodelistGeneric,
    [CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO]: {
      component: FilterDateGeneric,
      fromDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Publiceringsdato).publikationDatoFra = filteredDate;
        } else {
          (item?.filterChild as Publiceringsdato).publikationDatoFra = undefined;
        }
      },
      toDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO);
        const filteredDate = this.getFilterDato(value);

        if (filteredDate) {
          (item?.filterChild as Publiceringsdato).publikationDatoTil = filteredDate;
        } else {
          (item?.filterChild as Publiceringsdato).publikationDatoTil = undefined;
        }
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST]: {
      component: FilterDateGeneric,
      fromDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = filteredDate;
        } else {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoFra = undefined;
        }
      },
      toDateCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST);
        const filteredDate = this.getFilterDato(value);
        if (filteredDate) {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = filteredDate;
        } else {
          (item?.filterChild as Tilbudsfrist).tilbudsfristDatoTil = undefined;
        }
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI]: {
      component: FilterAnslaaetVaerdi,
      fromValueCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        if (value === '') {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = undefined;
        } else {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiFra = InputFormatUtil.formaterCurrency(value);
        }
      },
      toValueCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        if (value === '') {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = undefined;
        } else {
          (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiTil = InputFormatUtil.formaterCurrency(value);
        }
      },
      valutaCallback: (value: string) => {
        const soegningStore = useSoegningValgStore(window.pinia);
        const { filterItems } = storeToRefs(soegningStore);
        const item = filterItems.value.find(x => x.konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI);
        (item?.filterChild as AnslaaetVaerdi).anslaaetVaerdiValuta = value;
      }
    },
    [CustomFilterKonfigurationNavn.FILTER_ORDREGIVER]: {
      component: FilterCVRSelector
    },
    [CustomFilterKonfigurationNavn.FILTER_CPV_KODER]: {
      component: FilterCPVKoder
    }
  } as FilterComponentMap;

  public konfigurationNavnToCodelistIdMap: { [key in FilterKonfigurationNavn]: string | RadioOgCheckboxModel[] } = {
    // CodeListFilterKonfigurationNavn mappings
    [CodeListFilterKonfigurationNavn.FILTER_SMV_VENLIGHED]: 'indicator',
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_OPGAVE]: 'eforms-contract-nature',
    [CodeListFilterKonfigurationNavn.FILTER_TYPE_AF_PROCEDURE]: 'procurement-procedure-type',
    [CodeListFilterKonfigurationNavn.FILTER_FORMULARTYPE]: Object.getOwnPropertyNames(FilterDtoFormularTypeItem).map(x => ({
      label: `filter.FilterFormulartype.${x.toLowerCase()}.label`,
      value: x
    })),

    // CustomFilterKonfigurationNavn mappings
    [CustomFilterKonfigurationNavn.FILTER_PUBLICERINGSDATO_DATO]: '',
    [CustomFilterKonfigurationNavn.FILTER_TILBUDSFRIST]: '',
    [CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI]: 'currency',
    [CustomFilterKonfigurationNavn.FILTER_ORDREGIVER]: '',
    [CustomFilterKonfigurationNavn.FILTER_CPV_KODER]: ''
  };

  public getCodelistIdByKonfigurationNavn(key: FilterKonfigurationNavn): string | RadioOgCheckboxModel[] {
    return this.konfigurationNavnToCodelistIdMap[key];
  }

  public filterAsJsonString(soegning: SoegningQueryDto) {
    return stringify(soegning, {
      replacer: this.replacer
    });
  }

  public openFilter(selectedItem: Filter) {
    useSoegningValgStore().filterItems.forEach(item => {
      item.current = item === selectedItem;
      item.active = item === selectedItem;
    });
  }

  public resetActiveAndCurrentState() {
    useSoegningValgStore().filterItems.forEach(item => {
      item.current = false;
      item.active = false;
    });
  }

  public clearActiveFilter() {
    const soegningStore = useSoegningValgStore();
    soegningStore.filterItems.forEach((item, index) => {
      if (item.current === true) {
        soegningStore.filterItems[index].filterChild = {};
        // Special case med CPV.
        if (soegningStore.filterItems[index].konfigurationNavn == 'FilterCPVKoder') {
          soegningStore.cpvValg = {
            positivListe: [],
            negativListe: []
          };
        }
      }
    });
  }

  public clearAllFilter(doNewSearch = true) {
    const soegningStore = useSoegningValgStore();

    soegningStore.cpvValg = {
      positivListe: [],
      negativListe: []
    };
    soegningStore.filterItems.forEach((item, index) => {
      soegningStore.filterItems[index].filterChild = {};
    });
    if (doNewSearch) {
      this.searchWithFilter();
      soegningStore.setValidationError(false);
    }
  }

  public clearFilterChild() {
    useSoegningValgStore().filterItems.forEach((item, index) => {
      useSoegningValgStore().filterItems[index].filterChild = {};
    });
  }

  public getFilterDato(value: string): string {
    return dateUtil.validFilterDTOFormat(value);
  }

  public async getAlleCPVKoder(): Promise<DropdownOption[]> {
    const eformsKodelisterStore = useEFormsKodelisterStore(window.pinia);
    const CPVKodelisteData = await eformsKodelisterStore.hentKodelisteVaerdierMaaskeFraCache(SprogLocale.Dansk, 'cpv');

    const alleCpvKoder = CPVKodelisteData.map(x => ({
      value: x.codeValue,
      label: x.da! || x.en!
    }));

    return alleCpvKoder;
  }

  public async getFinalFilterDtoList(): Promise<FilterDto> {
    const soegningStore = useSoegningValgStore();
    const filterDtoList: FilterDto[] = [];

    soegningStore.filterItems.forEach((item, index) => {
      if (soegningStore.filterItems[index].filterChild) {
        const filterDto: FilterDto = { ...soegningStore.filterItems[index].filterChild };
        if (JSON.stringify(filterDto, this.replacer) !== '{}' && JSON.stringify(filterDto, this.replacer) !== '[]') {
          filterDtoList.push(filterDto);
        }
      }
    });
    const finalFilterDtoList: FilterDto = Object.assign({}, ...filterDtoList);

    const alleCpvKoder = await this.getAlleCPVKoder();

    // Sæt CPV koder
    finalFilterDtoList.cpvKoder = filterUtil.beregnOptimaleCpvKoder(
      soegningStore.cpvValg.positivListe,
      soegningStore.cpvValg.negativListe,
      alleCpvKoder
    );

    return finalFilterDtoList;
  }

  public async searchWithFilter(resetActiveAndCurrent = true) {
    const soegningStore = useSoegningValgStore();
    const finalFilterDtoList = this.getFinalFilterDtoList();

    useSoegningValgStore().soegning.filterDto = await finalFilterDtoList;
    soegningStore.search(undefined);
    if (resetActiveAndCurrent) {
      this.resetActiveAndCurrentState();
    }
  }

  public async countfilter(index: number): Promise<string | number> {
    const soegningStore = useSoegningValgStore();
    let filterAdded = false;
    let count = 0;
    if (soegningStore.filterItems[index].filterChild) {
      const filterDto: FilterDto = { ...soegningStore.filterItems[index].filterChild };
      filterAdded = this.hasEmptyStringProperty(filterDto);
      if (soegningStore.filterItems[index].konfigurationNavn == 'FilterCPVKoder') {
        // Special case for CPV koder, skal være de CPV koder der kan findes som skal tælles, og ikke den minimale antal.
        const alleCpvKoder = await filterUtil.getAlleCPVKoder();
        count = this.beregnAktiveCpvKoder(soegningStore.cpvValg.positivListe, soegningStore.cpvValg.negativListe, alleCpvKoder).length;
      } else if (filterAdded) {
        for (const key in filterDto) {
          const value = filterDto[key as keyof FilterDto];
          if (Array.isArray(value)) {
            count += value.filter(val => val !== undefined && val.trim() !== '').length;
          } else if (value !== undefined && typeof value === 'string' && value.trim() !== '') {
            count++;
          }
        }
      }
    }
    soegningStore.updateTotalCount();
    return count > 99 ? '99+' : count;
  }

  public checkIfAnyfilterIsSet(): boolean {
    const soegningStore = useSoegningValgStore();
    soegningStore.updateTotalCount();
    return soegningStore.filterItems.some(item => {
      if (!item.filterChild) {
        return false;
      }
      return Object.values(item.filterChild).some(value => value !== '' && value !== undefined);
    });
  }

  public sortFilterFunc(
    selectedValue: string[],
    tiebreaker?: (a: DropdownOption | RadioOgCheckboxModel, b: DropdownOption | RadioOgCheckboxModel) => number
  ) {
    return (a: DropdownOption | RadioOgCheckboxModel, b: DropdownOption | RadioOgCheckboxModel) => {
      const isAIncluded = includes(selectedValue, a.value);
      const isBIncluded = includes(selectedValue, b.value);
      if (isAIncluded && !isBIncluded) {
        return -1;
      }
      if (!isAIncluded && isBIncluded) {
        return 1;
      }
      if (tiebreaker !== undefined) {
        // Vi ønsker at beholde input sortering
        return tiebreaker(a, b);
      }
      return (a.label ?? '') < (b.label ?? '') ? -1 : 1;
    };
  }

  public hasEmptyStringProperty<T>(obj: T): boolean {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key as keyof T];
        if (value !== '' && value !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  public compareFilterDto(a: FilterDto | undefined, b: FilterDto | undefined): boolean {
    if (a === undefined || b === undefined) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.formularType, b.formularType)) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.myndighedType, b.myndighedType)) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.smvVenligType as string[], b.smvVenligType as string[])) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.opgaveType as string[], b.opgaveType as string[])) {
      return false;
    }
    if (!filterUtil.arrayMatch(a.procedureType as string[], b.procedureType as string[])) {
      return false;
    }
    if (a.publikationDatoFra !== b.publikationDatoFra) {
      return false;
    }
    if (a.publikationDatoTil !== b.publikationDatoTil) {
      return false;
    }
    if (a.tilbudsfristDatoFra !== b.tilbudsfristDatoFra) {
      return false;
    }
    if (a.tilbudsfristDatoTil !== b.tilbudsfristDatoTil) {
      return false;
    }
    if (a.anslaaetVaerdiFra !== b.anslaaetVaerdiFra) {
      return false;
    }
    if (a.anslaaetVaerdiTil !== b.anslaaetVaerdiTil) {
      return false;
    }
    if (a.anslaaetVaerdiValuta !== b.anslaaetVaerdiValuta) {
      return false;
    }
    return true;
  }

  public arrayMatch(a: string[] | undefined, b: string[] | undefined): boolean {
    if (a === undefined || b === undefined) {
      return false;
    }
    return (
      a.length === b.length &&
      a.every(function (value, index) {
        return value === b[index];
      })
    );
  }

  public setOrDefault<T>(val: T | undefined, def: T): T {
    if (val !== undefined) {
      return val;
    } else {
      return def;
    }
  }

  // Når man aendrer fritekstsoegning
  public search(data: GenericObject) {
    const store = useSoegningValgStore();
    store.search(data);
  }

  public fromChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string) => {
    if (konfigurationNavn !== CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      this.components[konfigurationNavn].fromDateCallback(value);
    } else {
      this.components[konfigurationNavn].fromValueCallback(value);
    }
  };

  public toChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string) => {
    if (konfigurationNavn !== CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      this.components[konfigurationNavn].toDateCallback(value);
    } else {
      this.components[konfigurationNavn].toValueCallback(value);
    }
  };

  public valutaChangedHandler = (konfigurationNavn: CustomFilterKonfigurationNavn, value: string | undefined) => {
    if (konfigurationNavn === CustomFilterKonfigurationNavn.FILTER_ANSLAAET_VAERDI) {
      if (value === 'dropdown.default-label') {
        value = undefined;
      }
      this.components[konfigurationNavn].valutaCallback(value);
    }
  };

  public showAsBodyText(child: never, indexChild: never) {
    return (
      (child !== undefined && child && indexChild === 'publikationDatoFra') ||
      (child !== undefined && child && indexChild === 'publikationDatoTil') ||
      (child !== undefined && child && indexChild === 'tilbudsfristDatoFra') ||
      (child !== undefined && child && indexChild === 'tilbudsfristDatoTil')
    );
  }

  public showArrowIndicator(filterChild: FilterDto): 'from' | 'to' | 'both' | null {
    const hasTilbudsfristDatoFra = filterChild.tilbudsfristDatoFra;
    const hasTilbudsfristDatoTo = filterChild.tilbudsfristDatoTil;
    const hasPubliceringsDatoFra = filterChild.publikationDatoFra;
    const hasPubliceringsDatoTo = filterChild.publikationDatoTil;

    if ((hasTilbudsfristDatoFra && hasTilbudsfristDatoTo) || (hasPubliceringsDatoFra && hasPubliceringsDatoTo)) {
      return 'both';
    } else if (hasTilbudsfristDatoFra || hasPubliceringsDatoFra) {
      return 'from';
    } else if (hasTilbudsfristDatoTo || hasPubliceringsDatoTo) {
      return 'to';
    } else {
      return null;
    }
  }

  public mergeFilterItems(defaultItems: Filter[], storedItems: Filter[]): Filter[] {
    return defaultItems.map(defaultFilter => {
      const storedFilter = storedItems.find(item => item.konfigurationNavn === defaultFilter.konfigurationNavn);
      return storedFilter ? storedFilter : defaultFilter;
    });
  }

  public getCpvPrefix(cpvKode: string) {
    const match = cpvKode.match(/^(\d\d+?)0+$/);
    const prefix = match ? match[1] : cpvKode;
    return prefix;
  }

  countTrailingZeroes(s: string, max: number): number {
    const match = s.match(/0+$/);
    return match ? Math.min(match[0].length, max) : 0;
  }

  groupedFromList(liste: string[]) {
    const positiveListGrouped = Array.from({ length: 6 }, () => [] as string[]);

    // Group the strings
    for (const s of liste) {
      const zeroCount = this.countTrailingZeroes(s, 6); // Nogle kan godt ligne at de har mere end 6 karakterer som prefix: "60000000 Transporttjenester (ikke affaldstransport)"
      if (zeroCount >= 1 && zeroCount <= 6) {
        positiveListGrouped[6 - zeroCount].push(s);
      }
    }
    return positiveListGrouped;
  }

  public beregnOptimaleCpvKoder(positivListe: string[], negativListe: string[], alleVaerdier: DropdownOption[]): string[] {
    // Function to count the number of trailing zeroes in a string

    let res: Set<string> = new Set();
    // let res: string[] = [];

    // For at beregne alle de checkedBoxes:
    // - 1: Grouper positiv og negativ lister til lister af samme længde suffix.
    const positiveListGrouped = this.groupedFromList(positivListe);
    const negativeListGrouped = this.groupedFromList(negativListe);

    const negPrefixes = negativListe.map(x => this.getCpvPrefix(x));

    // - 2: For hver længde (stigende) tilføj og fjern cpv koder fra listen
    if (alleVaerdier != undefined) {
      for (let i = 0; i < 6; i++) {
        for (const cpv of positiveListGrouped[i]) {
          if (!negativListe.some(negCpv => negCpv.startsWith(this.getCpvPrefix(cpv)))) {
            // Der er ingen modsætning på negativ listen, tilføj som wildcard
            res.add(`${this.getCpvPrefix(cpv)}_`);
          } else {
            const prefix = cpv.substring(0, 2 + i);
            let alleMedPrefix = alleVaerdier.sort((a, b) => a.value.localeCompare(b.value)).filter(x => x.value.startsWith(prefix));
            for (let i = 0; i < alleMedPrefix.length; i++) {
              const vaerdi = alleMedPrefix[i];
              const prefix = this.getCpvPrefix(vaerdi.value);
              const revrseIsNegListed = negPrefixes.some(x => x.startsWith(prefix));
              if (revrseIsNegListed) {
                res.add(vaerdi.value);
              } else {
                // Der er ingen modsætning på negativ listen, tilføj som wildcard
                res.add(`${prefix}_`);

                // console.log('foer : ' + alleMedPrefix.map(x => x.value));
                // Vi har tilføjet et wildcard, undgå at behandle dem som hører herunder.
                alleMedPrefix = alleMedPrefix.filter(x => !x.value.startsWith(prefix) || x.value == vaerdi.value);
                // console.log('efter: ' + alleMedPrefix.map(x => x.value));
              }
            }
          }
        }

        for (const cpv of negativeListGrouped[i]) {
          const prefix = cpv.substring(0, 2 + i);
          res = new Set([...res].filter(x => !x.startsWith(prefix)));
        }
      }
    }

    return [...new Set(res)];
  }

  public beregnAktiveCpvKoder(positivListe: string[], negativListe: string[], alleVaerdier: DropdownOption[]): SelectedCheckbox[] {
    let res: Map<string, SelectedCheckbox> = new Map();

    // For at beregne alle de checkedBoxes:
    // - 1: Grouper positiv og negativ lister til lister af samme længde suffix.
    const positiveListGrouped = this.groupedFromList(positivListe);
    const negativeListGrouped = this.groupedFromList(negativListe);

    // - 2: For hver længde (stigende) tilføj og fjern cpv koder fra listen
    if (alleVaerdier != undefined) {
      for (let i = 0; i < 6; i++) {
        for (const cpv of positiveListGrouped[i]) {
          const prefix = cpv.substring(0, 2 + i);
          const alleMedPrefix = alleVaerdier.filter(x => x.value.startsWith(prefix));

          alleMedPrefix
            .map(x => ({
              label: x.label!,
              value: x.value
            }))
            .forEach(x => {
              res.set(x.value, x);
            });
        }

        for (const cpv of negativeListGrouped[i]) {
          const prefix = cpv.substring(0, 2 + i);
          // Collect keys that start with the prefix
          const keysToDelete: string[] = [];
          for (const key of res.keys()) {
            if (key.startsWith(prefix)) {
              keysToDelete.push(key);
            }
          }
          // Delete the collected keys from the map
          for (const key of keysToDelete) {
            res.delete(key);
          }
        }
      }
    }

    return [...res.values()].sort((a: SelectedCheckbox, b: SelectedCheckbox) => a.value.localeCompare(b.value));
  }
}

export const filterUtil = new FilterUtil();
