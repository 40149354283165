import { RuleExpression } from 'vee-validate';

import { Field } from '@/main/models/generated';

class InputUtil {
  public skalViseFejlPaaEFormsFelt(
    extraError: string | undefined,
    erEForms: boolean,
    rules: RuleExpression<unknown>,
    touched: boolean,
    errors: string[]
  ) {
    const externalError = extraError !== undefined && extraError !== '';
    if (this.erFeltEFormsOgMandatory(erEForms, rules)) {
      // MUD-2184 & MUD-1472: Hvis feltet er eforms og mandatory, så skal paakraevet reglen ikke gælde.
      return externalError || (touched && errors.find(x => x != 'fejlbesked.paakraevet') !== undefined);
    }
    const rulesError = touched && errors.length > 0;
    return rulesError || externalError;
  }

  public erFeltEFormsOgMandatory(erEForms: boolean, rules: RuleExpression<unknown>) {
    return erEForms && this.isRequired(rules);
  }

  public isRequired(rules: RuleExpression<unknown>) {
    if (typeof rules === 'string') {
      return rules.includes('required');
    } else if (typeof rules === 'object') {
      const rulesAsRecord = rules as Record<string, unknown>;
      return (
        rulesAsRecord.required === true &&
        (rulesAsRecord.readOnly === undefined || rulesAsRecord.readOnly === false) &&
        (rulesAsRecord.disabled === undefined || rulesAsRecord.disabled === false)
      );
    }

    return false;
  }

  public isFieldMandatory(field: Field, noticeSubTypeId: string): boolean {
    if (field?.mandatory?.constraints !== undefined) {
      // Gennemgå hver constraints
      for (const constraint of field.mandatory.constraints) {
        // Hvis denne constraint matcher notice subtype, returner da værdien her
        if (constraint.noticeTypes && constraint.noticeTypes.some(x => x === noticeSubTypeId)) {
          if (constraint.condition !== undefined) {
            // MUD-1829 AK3+4: Hvis der er en betingeslse, så skal den betragtes som ikke mandatory:
            console.debug(
              `🚧Constraint på '${field.id}' havde en condition: '${constraint.condition}' for noticeSubType: '${noticeSubTypeId}', men den tager vi ikke stilling til ... Betragtes som ikke mandatory!`
            );
            return false;
          }
          return constraint.value === true;
        }
      }
    }
    // Hvis ingen constraints matchede, returner da den "value" der var angivet på mandatory, ellers false.
    return field?.mandatory?.value ?? false;
  }
}

export const inputUtil = new InputUtil();
