import { v4 as uuidv4 } from 'uuid';
import { Ref } from 'vue';

import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { Field } from '@/main/models/generated';
import i18n from '@/main/services/i18n';

import { DropdownOption } from '../models/base/DropdownOption';

class EFormsUtil {
  public labelTextKey(field: Field, noticeTypeElement?: KonfigureretContent): string {
    const { te } = i18n.global;

    const fieldKeyId = 'field|name|' + field.id;
    const fieldKeyBtId = 'field|name|' + field.btId;
    const noticeTypeKey = noticeTypeElement?._label;

    if (te(fieldKeyId)) {
      return fieldKeyId;
    } else if (te(fieldKeyBtId)) {
      return fieldKeyBtId;
    } else if (noticeTypeKey && te(noticeTypeKey)) {
      return noticeTypeKey;
    } else if (noticeTypeElement?.description) {
      console.warn('Kunne ikke finde oversættelse for: ' + field.id + ' / ' + noticeTypeElement?.id);
      return noticeTypeElement?.description;
    } else {
      console.warn('Kunne ikke finde oversættelse for: ' + field.id + ' / ' + noticeTypeElement?.id);
      return field.id;
    }
  }

  public generateUuid(): string {
    return uuidv4();
  }

  public erStruktur(content: KonfigureretContent): boolean {
    return ['SECTION', 'GROUP'].includes(content.displayType);
  }

  public erFelt(content: KonfigureretContent): boolean {
    return ['COMBOBOX', 'TEXTBOX', 'TEXTAREA', 'RADIO'].includes(content.displayType);
  }

  public skalHaveOverskrift(content: KonfigureretContent, kanVaereRepeat = false): boolean {
    if (content.forceVisOverskrift === true) {
      return true;
    }

    if (content.skalVisesSomMandatoryMenErDetIkkeIMetadata) {
      return true;
    }

    // Skjul overskrift hvis det er explicit slået fra.
    if (content.forceVisOverskrift === false) {
      return false;
    }

    // Skjul overskrift hvis det er en gentagelse og der kun er et felt i.
    if (
      content.content?.length !== undefined &&
      content.content.length === 1 &&
      content._repeatable === true &&
      content.content[0].contentType === 'field'
    ) {
      return false;
    }

    // Skjul overskrift hvis vi er inde i en repeatable
    if (kanVaereRepeat === true && content._repeatable === true) {
      return false;
    }

    // Default, vis overskrift hvis det er et node-id eller en section.
    return content?.nodeId != null || content?.displayType === 'SECTION';
  }

  public beregnIdOfField(activeindex: Ref<number>, index: number, fieldId: string) {
    activeindex.value = index;
    return fieldId;
  }

  public generateTooltipId(forId: string) {
    const fieldDescriptionId = 'field|description|' + forId;
    return fieldDescriptionId;
  }

  public generateHintId(name: string) {
    const fieldHintId = 'field|hint|' + name;
    return fieldHintId;
  }

  public visHintText(name: string): string {
    const { t, te } = i18n.global;
    const hintId = this.generateHintId(name);

    if (te(hintId)) {
      return t(this.generateHintId(name));
    } else if (name === undefined) {
      return '';
    }
    return '';
  }

  public tooltipExists(eformsId: string): boolean {
    const { te } = i18n.global;
    const tooltipDescriptionId = this.generateTooltipId(eformsId);
    return te(`${tooltipDescriptionId}`);
  }

  public scrollToTopView(behaviorSetting: ScrollBehavior, topSetting: number) {
    window.scrollTo({
      top: topSetting,
      behavior: behaviorSetting
    });
  }

  public sortLabelsAlphabetically(codelister?: DropdownOption[]): DropdownOption[] {
    if (!codelister) {
      return [];
    }
    const filteredOptions = codelister.filter((option: DropdownOption) => option.value !== '' && option.value !== 'dropdown.default-label');

    filteredOptions.sort((a: DropdownOption, b: DropdownOption) => {
      const labelA = a.label ?? '';
      const labelB = b.label ?? '';
      return labelA.localeCompare(labelB, 'da-DK');
    });

    return filteredOptions;
  }

  public sortLabelsValuewise(codelister: DropdownOption[]): DropdownOption[] {
    if (!codelister) {
      return [];
    }
    const filteredOptions = codelister.filter((option: DropdownOption) => option.value !== '' && option.value !== 'dropdown.default-label');

    filteredOptions.sort((a: DropdownOption, b: DropdownOption) => {
      const valueA = a.value;
      const valueB = b.value;
      return valueA.localeCompare(valueB);
    });
    return filteredOptions;
  }

  public saetVaerdierOeverst(codelister: DropdownOption[], vaerdier: string[] | undefined): DropdownOption[] {
    const filteredOptions = codelister.filter((option: DropdownOption) => option.value !== '' && option.value !== 'dropdown.default-label');

    let topOptions: DropdownOption[] = [];
    let otherOptions: DropdownOption[] = [];
    if (vaerdier) {
      topOptions = vaerdier.map((x) => filteredOptions.find((y) => x == y.value)).filter((x) => x !== undefined);
      otherOptions = filteredOptions.filter((option: DropdownOption) => !vaerdier.includes(option.value));
    } else {
      otherOptions = filteredOptions;
    }
    return [...topOptions, ...otherOptions];
  }
}

export const eformsUtil = new EFormsUtil();
