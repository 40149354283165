<template>
  <ul class="sorter-modal-vaelger mt-5" data-cy="sortering-modal">
    <li :aria-current="erValgt(relevance, desc)" role="menuitem">
      <SorteringKnapValg navn="relevance.mobil" retning="both" @click="filterValg(relevance, desc)" />
    </li>
    <li :aria-current="erValgt(publikationDato, asc)" role="menuitem">
      <SorteringKnapValg navn="publikationdato.mobil" retning="asc" @click="filterValg(publikationDato, asc)" />
    </li>
    <li :aria-current="erValgt(publikationDato, desc)" role="menuitem">
      <SorteringKnapValg navn="publikationdato.mobil" retning="desc" @click="filterValg(publikationDato, desc)" />
    </li>
    <li :aria-current="erValgt(tilbudsfristdato, asc)" role="menuitem">
      <SorteringKnapValg navn="tilbudsfristdato.mobil" retning="asc" @click="filterValg(tilbudsfristdato, asc)" />
    </li>
    <li :aria-current="erValgt(tilbudsfristdato, desc)" role="menuitem">
      <SorteringKnapValg navn="tilbudsfristdato.mobil" retning="desc" @click="filterValg(tilbudsfristdato, desc)" />
    </li>
  </ul>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';

import SorteringKnapValg from '@/main/components/soegning/SorteringKnapValg.vue';
import { PagineringDtoRetning, PagineringDtoSorteringFelt } from '@/main/models/generated';
import { useModalStore } from '@/main/stores/modal.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';

const store = useModalStore(window.pinia);

const soegningStore = useSoegningValgStore(window.pinia);
const { soegning } = storeToRefs(soegningStore);

const publikationDato = PagineringDtoSorteringFelt.PUBLIKATION_DATO;
const tilbudsfristdato = PagineringDtoSorteringFelt.TILBUDSFRIST_DATO;
const relevance = PagineringDtoSorteringFelt.RELEVANCE;
const asc = PagineringDtoRetning.Asc;
const desc = PagineringDtoRetning.Desc;

function filterValg(field: PagineringDtoSorteringFelt, retning: PagineringDtoRetning) {
  soegning.value.pagineringDto.retning = retning;
  soegning.value.pagineringDto.sorteringFelt = field;
  soegning.value.pagineringDto.aktuelSide = 1;
  store.closeModal();
}

function erValgt(field: PagineringDtoSorteringFelt, retning: PagineringDtoRetning) {
  return soegning.value.pagineringDto.sorteringFelt === field && soegning.value.pagineringDto.retning === retning;
}
</script>
<style lang="scss" scoped>
@import '../../styles/components/resultat-liste';
@import '../../styles/components/sortering';
</style>
