/* eslint-disable no-console */

// https://stackoverflow.com/a/60635583
export function log(message: string, level?: 'info' | 'warn' | 'error') {
  // WHEN RUNNING WEBPACK WITH `PRODUCTION` build,
  // IT WILL REMOVE THE FOLLOWING CODE.

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (import.meta.env.DEV) {
    if (level === 'error') {
      console.error(message);
    } else if (level === 'warn') {
      console.warn(message);
    } else {
      console.log(message);
    }
  }
}
