import { Komponent } from '@/main/enums/komponent.enum';
import { SprogLocale } from '@/main/enums/sprog.enum';
import { ContentfulTekst } from '@/main/models/generated';
import { getSdkController } from '@/main/services/generated/sdk-controller/sdk-controller';
import { getTeksterController } from '@/main/services/generated/tekster-controller/tekster-controller';
import i18n from '@/main/services/i18n';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { computedBruger } from '@/main/stores/auth.state.bruger';
import { useEformsModelStore } from '@/main/stores/eforms-model.state';
import { useSprogStore } from '@/main/stores/sprog.state';

export class SprogService {
  public sprogservice = i18n;

  private teksterFraContentful: { [key: string]: ContentfulTekst[] } = {};
  private reglerErIndlaestForDisseSprog = [] as string[];

  public async initSprogservice() {
    try {
      const sprogStore = useSprogStore(window.pinia);
      const eformsModelStore = useEformsModelStore(window.pinia);

      const configKonfiguration = AuthService.getConfig(Komponent.Konfiguration, true);
      const configUdbud = AuthService.getConfig(Komponent.Udbud, false);

      const teksterPromise = getTeksterController().hentTekster(configKonfiguration);
      const sdkTeksterPromise = getSdkController().hentOversaettelser(
        eformsModelStore.sdkVersion,
        'field,group,notice,business-term',
        'da,en',
        configUdbud
      );

      this.teksterFraContentful = (await teksterPromise).data;
      const fieldsAndGroupsResultMap = await sdkTeksterPromise;

      this.indlaesTekster(this.teksterFraContentful, fieldsAndGroupsResultMap.data);

      this.sprogservice.global.locale.value = sprogStore.sprog;
      if (this.reglerErIndlaestForDisseSprog) {
        this.reglerErIndlaestForDisseSprog = [];
        this.indlaesReglerSDKTekster(sprogStore.sprog);
      }

      sprogStore.$subscribe((mutation, state) => {
        Promise.resolve(state.sprog)
          .then(sprogValue => {
            this.sprogservice.global.locale.value = sprogValue;
            this.indlaesReglerSDKTekster(sprogValue);
          })
          .catch(error => {
            console.error('Fejl i opdateringen af sprog:', error);
          });
      });
    } catch (error) {
      console.log('Hent tekster fejler: ', error);
    }
  }

  public indlaesReglerSDKTekster(sprog: string | undefined) {
    if (sprog === undefined || this.reglerErIndlaestForDisseSprog.includes(sprog)) {
      return;
    }
    // Vi ønsker ikke altid at skulle loade alle eForms tekster, da det bliver for tungt.
    const eformsModelStore = useEformsModelStore(window.pinia);
    const configUdbud = AuthService.getConfig(Komponent.Udbud, false);
    getSdkController()
      .hentOversaettelser(eformsModelStore.sdkVersion, 'rule', sprog, configUdbud)
      .then(x => {
        this.addTekster(x.data);
        this.reglerErIndlaestForDisseSprog.push(sprog);
      });
  }

  private indlaesTekster(
    higherPriorityTranslations: {
      [key: string]: ContentfulTekst[];
    },
    lowerPriorityTranslations: {
      [key: string]: ContentfulTekst[];
    }
  ) {
    const sprogStore = useSprogStore(window.pinia);
    for (const locale of ['da', 'en']) {
      // Grimt, hardcoded fix til at smide hints fra SDK'et væk
      const lowprioInLanguage = lowerPriorityTranslations[locale].filter(x => !x.noegle?.includes("field|hint|"));
      const highprioInLanguage = higherPriorityTranslations[locale];
      let allTexts: ContentfulTekst[] = [...lowprioInLanguage, ...highprioInLanguage];
      allTexts = sprogStore.visNoegleForRedaktor ? this.tilfojNoeglePrefixTilTekster(allTexts) : allTexts;
      const i18nFormatted: any = {};
      if (allTexts) {
        allTexts.forEach(t => {
          this.expandPathToJSON(t.noegle!, t.tekst, t.cdaRichDocument, i18nFormatted);
        });
      }
      this.sprogservice.global.setLocaleMessage(locale, i18nFormatted);
    }
  }

  private addTekster(lowerPriorityTranslations: { [key: string]: ContentfulTekst[] }) {
    for (const locale in lowerPriorityTranslations) {
      const newTekster = this.contentfulTekstToi18nFormat(lowerPriorityTranslations[locale]);
      // Hack grundet åbent issue på i18n, man kan ikke merge uden at overskrive: https://github.com/kazupon/vue-i18n/issues/324
      const oldTekster = JSON.parse(JSON.stringify(this.sprogservice.global.getLocaleMessage(locale)));
      this.sprogservice.global.mergeLocaleMessage(locale, newTekster);
      this.sprogservice.global.mergeLocaleMessage(locale, oldTekster);
    }
  }

  private contentfulTekstToi18nFormat(tekster: ContentfulTekst[]) {
    const sprogStore = useSprogStore(window.pinia);
    const allTexts = sprogStore.visNoegleForRedaktor ? this.tilfojNoeglePrefixTilTekster(tekster) : tekster;
    const i18nFormatted = {};
    allTexts.forEach(t => {
      this.expandPathToJSON(t.noegle!, t.tekst, t.cdaRichDocument, i18nFormatted);
    });
    return i18nFormatted;
  }

  public hentVisNoegleForRedaktor(): boolean {
    const bruger = computedBruger().value;
    const roller = bruger.roller;
    if (bruger.loggetInd && roller.includes('MU_TEKSTREDAKTOER')) {
      return true;
    }
    return false;
  }

  public setVisNoegleForRedaktor(visNoegleForRedaktor: boolean) {
    const sprogStore = useSprogStore(window.pinia);
    sprogStore.updateRedaktorNoegle(visNoegleForRedaktor);
  }

  public konverterLocaleTilSprog(locale: string): string {
    return locale === SprogLocale.Dansk ? 'Dansk' : 'English';
  }

  /**
   * Converts the dot notation string e.g. foo.bar.baz to a nested JSON object suitable for i18n consumption
   * @param path the code path of the message e.g. foo.bar.baz
   * @param value the translated text
   * @param obj  the enclosing object of the expanded JSON structure
   */
  private expandPathToJSON(path: string, value: string | undefined, rigTekstValue: any, obj: any) {
    const keys = path.split('.');
    let accumulator = obj;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        if (value) {
          accumulator[key] = value;
        } else {
          accumulator[key] = this.bygRigText(rigTekstValue, path);
        }
      } else {
        accumulator[key] = accumulator[key] || {};
        accumulator = accumulator[key];
      }
    });
  }

  private tilfojNoeglePrefixTilTekster(alleTekster: ContentfulTekst[]): ContentfulTekst[] {
    return alleTekster.map(item => {
      if (item.tekst) {
        // "|" er speciel i vue i18n, erstat den så den ikke bliver misfortolket.
        return {
          ...item,
          tekst: `[${item.noegle?.replaceAll('|', "{'|'}")}] ${item.tekst}`
        };
      } else if (item.cdaRichDocument) {
        return {
          ...item,
          cdaRichDocument: this.bygRigText(item.cdaRichDocument, item.noegle, true)
        };
      }
      return item;
    });
  }

  private bygRigText(rigTekst: any, noegle?: string, tilfojRedaktorPrefix?: boolean) {
    if (!rigTekst) {
      // Konverter meddelelse for ikke-fundet-noegle til RichTextDocument format
      return {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: noegle,
            data: {},
            marks: []
          }
        ]
      };
    }
    // Hvis tilfojRedaktorPrefix flag er sat, tilfoj noegle til første værdi i rigteksten
    if (tilfojRedaktorPrefix) {
      return {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: `[${noegle}]`,
            data: {},
            marks: []
          },
          ...rigTekst.content
        ]
      };
    }
    return rigTekst;
  }
}

export const sprogService = new SprogService();
