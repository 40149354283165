/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { HentTekster200 } from '../../../models/generated';

export const getTeksterController = () => {
  /**
 * Tekster bliver cachet i løsningen for ikke at genhente fra Contentful hver gang.
Dette betyder, at hvis en tekstredaktør ændrer tekster i Contentful, skal cache ryddes, og tekster skal genhentes, før ændringen træder i kraft.

 * @summary Force reload af tekster.
 */
  const reloadAlleTekster = <TData = AxiosResponse<void>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/tekster:reload`, undefined, options);
  };
  /**
 * Er cachen tom, så hentes tekster først fra kilden. Cachen opdateres på ny, og tekster sendes return.
Ikke beskyttet da SPAen har brug for dette ved opstart.
Type af tekster (offentlige, konfiguration, backend) hentes baseret på rollerettigheder.
Det er altså kun muligt for brugere med den korrekte rolle at hente tekster til konfiguration og backend.

 * @summary Henter alle tekster til cache.
 */
  const hentTekster = <TData = AxiosResponse<HentTekster200>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/tekster`, options);
  };
  return { reloadAlleTekster, hentTekster };
};
export type ReloadAlleTeksterResult = AxiosResponse<void>;
export type HentTeksterResult = AxiosResponse<HentTekster200>;
