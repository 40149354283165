import { User } from 'oidc-client-ts';
import { defineStore } from 'pinia';

export const useAuthPersistenceStore = defineStore('authPersistence', {
  state: () =>
    ({
      user: null,
      fornyelse: false
    } as { user: User | null; fornyelse?: boolean }),
  persist: {
    storage: localStorage,
    serializer: {
      serialize: (value: Record<string, User>) =>
        JSON.stringify({
          user: JSON.parse(value.user?.toStorageString()),
          fornyelse: value.fornyelse
        }),
      deserialize: (valueInStore: string) => {
        const obj = JSON.parse(valueInStore);
        return {
          user: User.fromStorageString(JSON.stringify(obj.user)),
          fornyelse: obj.fornyelse ?? false
        };
      }
    }
  },
  getters: {
    getUser(): User | null {
      return this.user;
    }
  },
  actions: {
    updateEmail(email: string) {
      if (this.user) {
        this.user.profile.email = email;
      }
    }
  }
});
