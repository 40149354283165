import { UserManager } from 'oidc-client-ts';

/**
 * Udstiller events for oidc client.
 *
 * Alle events er prefixet med auth
 *
 * Eg.
 *
 * window.addEventListener('auth:userLoaded', (user) => {
 * console.log('userloaded', user);
 * });
 *
 * userLoaded: (user) => console.log('OIDC user is loaded:', user),
 * userUnloaded: () => console.log('OIDC user is unloaded'),
 * accessTokenExpiring: () => console.log('Access token will expire'),
 * accessTokenExpired: () => console.log('Access token did expire'),
 * silentRenewError: () => console.log('OIDC user is unloaded'),
 * userSignedOut: () => console.log('OIDC user is signed out'),
 * oidcError: (payload) => console.log(`An error occured at ${payload.context}:`, payload.error),
 * automaticSilentRenewError: (payload) => console.log('Automatic silent renew failed.', payload.error)
 */
export default class AuthServiceEvents {
  userManager: UserManager;
  userManagerEvents = ['userLoaded', 'userUnloaded', 'accessTokenExpiring', 'accessTokenExpired', 'silentRenewError', 'userSignedOut'];

  /**
   * Generere events - se klasse for fuld beskrivelse
   * @param usermanager
   */
  constructor(usermanager: UserManager) {
    this.userManager = usermanager;
    this.generateEvents();
  }

  firstLetterUppercase = (str: string) => (str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : '');

  createCustomEvent(eventName: string, detail: any, params: any) {
    let paramsLocal = { ...params };
    const prefixedEventName = `auth:${eventName}`;

    paramsLocal = paramsLocal ?? { bubbles: false, cancelable: false };
    paramsLocal = { ...paramsLocal, detail };

    return new CustomEvent(prefixedEventName, paramsLocal);
  }

  dispatchCustomBrowserEvent(eventName: string, detail = {}, params = {}) {
    if (window) {
      const event = this.createCustomEvent(eventName, { ...detail }, params);
      window.dispatchEvent(event);
    }
  }

  /**
   * Generate a set of events for oidc - see userManagerEvents
   * @param removeEvents if true - it removes events
   */
  generateEvents(removeEvents = false) {
    this.userManagerEvents.forEach(eventName => {
      this.generateUserManagerEventListener(
        this.userManager,
        eventName,
        (detail?: any) => {
          this.dispatchCustomBrowserEvent(eventName, detail ?? {});
        },
        removeEvents
      );
    });
  }

  /**
   * Removes all created events
   * This is only for cleanup
   */
  removeEvents() {
    this.generateEvents(true);
  }

  /**
   * Generates either add event or remove event
   * @param oidcUserManager
   * @param eventName
   * @param eventListener
   * @param removeEvent add or remove
   */
  generateUserManagerEventListener(
    oidcUserManager: UserManager,
    eventName: string,
    eventListener: (detail?: any) => Promise<void> | void,
    removeEvent = false
  ): Promise<void> | void {
    const fnPrefix = removeEvent ? 'remove' : 'add';
    const addFnName = `${fnPrefix}${this.firstLetterUppercase(eventName)}`;
    if (typeof (oidcUserManager.events as any)[addFnName] === 'function' && typeof eventListener === 'function') {
      (oidcUserManager.events as any)[addFnName](eventListener);
    }
  }
}
