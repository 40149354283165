<template>
  <RichTextRenderer
    v-if="!contentfulRigTekstErTom(document) || visNoegleForRedaktor"
    :document="!contentfulRigTekstErTom(document) ? document : defaultDoc"
    :node-renderers="renderNodes" />
</template>

<script setup lang="ts">
import { BLOCKS } from '@contentful/rich-text-types';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { storeToRefs } from 'pinia';
import { h } from 'vue';
import { useI18n } from 'vue-i18n';

import Accordion from '@/main/components/Accordion.vue';
import AccordionGruppe from '@/main/components/modals/AccordionGruppe.vue';
import { useSprogStore } from '@/main/stores/sprog.state';

const { document, tekstNoegle } = defineProps<{ document: any; tekstNoegle?: string }>();
const { tm } = useI18n();

const defaultDoc = {
  data: {},
  content: [
    { data: {}, content: [{ data: {}, marks: [], value: '[' + (tekstNoegle ?? 'ukendt-tekstnøgle') + ']', nodeType: 'text' }], nodeType: 'paragraph' }
  ],
  nodeType: 'document'
};

const sprogStore = useSprogStore(window.pinia);
const { visNoegleForRedaktor } = storeToRefs(sprogStore);

function renderEmbeddedEntry(node: { entryType: string; content: any }) {
  const contentfulKey = node.content;

  if (node.entryType === 'accordion') {
    const accordionBody = tm(contentfulKey);
    const title = accordionBody.titel;
    return h(Accordion, { contentfulKey, title, accordionBody });
  }

  if (node.entryType === 'accordionGruppe') {
    return h(AccordionGruppe, { contentfulKey: node.content });
  }
}

function renderAssetEntry(node: { data: { target: { contentType: any; url: any } } }, key: any) {
  const mimeType = node.data.target.contentType;
  const url = node.data.target.url;

  if (mimeType.startsWith('image/')) {
    return h('img', { key, src: url, alt: 'title' });
  } else if (mimeType.startsWith('video/')) {
    return h('video', { class: 'video-responsive', key, controls: true }, [h('source', { src: url, type: mimeType })]);
  }
}

const renderNodes = {
  [BLOCKS.EMBEDDED_ASSET]: renderAssetEntry,
  [BLOCKS.EMBEDDED_ENTRY]: renderEmbeddedEntry
};

function contentfulRigTekstErTom(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}
</script>
