<template>
  <label :for="forId" class="form-label" :class="hide ? 'sr-only' : ''" :aria-label="'label for ' + name">
    <template v-if="!hide">
      <span v-if="label !== '' && !erEForms">
        {{ label ? label : $t(`${label}.label`) }}
      </span>
      <span v-else>
        {{ label ? label : $t(`${textPrefix}.label`) }}
      </span>
      <span v-if="visObligatoriskFeltIkon(required, disabled, readonly, forceVisObligatorisk)">
        <span class="sr-only">{{ $t('eformsfelt.obligatorisk-tekst.ikon') }}</span>
        {{ $t('eformsfelt.obligatorisk.ikon') }}
      </span>
      <FeltTooltip :name="name" :tooltip-id="tooltipId" :show-tooltip="showTooltip" />
      <span v-if="skalViseHint" class="form-hint">
        {{ $t(`${textPrefix}.hint`) }}
      </span>
      <span v-if="showHint && erEForms && eformsUtil.visHintText(name)" class="form-hint">
        <template v-if="typeof eformsUtil.visHintText(name) === 'string'">
          <template v-for="(line, index) in eformsUtil.visHintText(name).split('\n')" :key="index">
            <span v-if="index > 0"><br /></span>{{ line }}
          </template>
        </template>
      </span>
      <template v-if="eformsModelStore.visBusinessTermId && erEForms">
        <span class="form-hint">{{ '(' + name + ')' }}</span>
      </template>
      <BaseBesked :type="'warning'" v-if="visAdvarsel" :titel="$t(advarsel)">
        <button type="button" class="alert-close" @click="lukAdvarsel()">
          <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#close"></use></svg>{{ $t('besked.luk') }}
        </button>
      </BaseBesked>
    </template>
  </label>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';

import BaseBesked from '@/main/components/base/BaseBesked.vue';
import FeltTooltip from '@/main/components/input/FeltTooltip.vue';
import i18n from '@/main/services/i18n';
import { useEformsModelStore } from '@/main/stores/eforms-model.state';
import { eformsUtil } from '@/main/utils/eforms-util';

const eformsModelStore = useEformsModelStore(window.pinia);

const props = defineProps({
  textPrefix: {
    type: String,
    required: false,
    default: ''
  },
  erEForms: {
    default: true,
    required: false,
    type: Boolean
  },
  visBusinessTermId: {
    required: false,
    type: String,
    default: ''
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  forId: {
    type: String,
    required: true
  },
  advarsel: {
    type: String,
    default: ''
  },
  hide: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  required: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  showHint: {
    type: Boolean,
    default: true
  },
  showTooltip: {
    type: Boolean,
    default: true
  },
  forceVisObligatorisk: {
    type: Boolean,
    default: false
  }
});

const tooltipId = props.forId + '.tooltipid';
const visAdvarsel = ref(false);
const skalViseHint = computed(() => {
  return props.showHint && !props.erEForms && props.textPrefix !== '' && i18n.global.te(`${props.textPrefix}.hint`);
});

onMounted(() => {
  if (props.advarsel !== '') {
    visAdvarsel.value = true;
  }
});

function lukAdvarsel() {
  visAdvarsel.value = false;
}

function visObligatoriskFeltIkon(required: boolean, disabled: boolean, readonly: boolean, forceVisObligatorisk: boolean) {
  if (forceVisObligatorisk) {
    return true;
  }
  return required && !disabled && !readonly;
}
</script>
<style lang="scss" scoped>
@import '../../styles/components/_eformsFormular';
</style>
