<template>
  <div class="modal-body" data-cy="udbudsagent-slet-modal">
    <ContentfulRigTekst :document="$tm('udbudsagentSletBekraeftelseModal.broedtekst')" />
    <span v-if="generiskBackendFejl" class="form-error-message">{{ $t('sletudbudsagent.fejlbesked') }}</span>
  </div>
  <div class="modal-footer">
    <button type="button" @click="sletUdbudsAgent()" class="button button-primary" data-cy="udbudsagent-slet-knap">
      {{ $t('udbudsagent-sletning-bekraeftelse.slet') }}
    </button>
    <button type="button" @click="modalStore.closeModal()" class="button button-secondary" data-cy="udbudsagent-annuller-knap">
      {{ $t('annuller.tekst') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios';
import { PropType, ref } from 'vue';

import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { GemtSoegningDTO } from '@/main/models/generated';
import { soegningService } from '@/main/services/soegning.service';
import { useModalStore } from '@/main/stores/modal.state';

const modalStore = useModalStore(window.pinia);
const generiskBackendFejl = ref(false);

const props = defineProps({
  element: {
    type: Object as PropType<GemtSoegningDTO>,
    required: true
  }
});

async function sletUdbudsAgent() {
  try {
    const soegningId = props.element.soegningId as number;
    await soegningService.slet(soegningId);
    modalStore.closeModal();
  } catch (error) {
    generiskBackendFejl.value = true;
  }
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
