<template>
  <button type="button" class="button-unstyled list-item-button" @click="filterUtil.openFilter(item)" data-cy="filter-knap">
    <div>
      <span class="bold ml-4" data-cy="filter-liste-titel">{{ $t(item.titel!) }}</span>
      <svg class="icon-svg mr-4" focusable="false" aria-hidden="true">
        <use :xlink:href="'#chevron-right'"></use>
      </svg>
      <span v-if="count !== undefined && count !== 0" class="filter-count" data-cy="filter-count"> {{ count }}</span>
    </div>
    <div class="ml-4 filter-details" v-if="item.filterChild">
      <span v-for="(child, index_child) in item.filterChild!" :key="index_child" class="child">
        <span v-if="filterUtil.showAsBodyText(child, index_child)" data-cy="filter-datoer-valgt">
          <!-- Vis pil foran fra dato -->
          <template
            v-if="
              filterUtil.showArrowIndicator(item.filterChild) === 'from' &&
              (index_child === 'tilbudsfristDatoFra' || index_child === 'publikationDatoFra')
            ">
            {{ dateUtil.validFilterFormat(child) }} →
          </template>
          <!-- Vis pil bagved til dato -->
          <template
            v-else-if="
              filterUtil.showArrowIndicator(item.filterChild) === 'to' &&
              (index_child === 'tilbudsfristDatoTil' || index_child === 'publikationDatoTil')
            ">
            → {{ dateUtil.validFilterFormat(child) }}
          </template>
          <!-- Vis pil mellem fra og til dato -->
          <template
            v-else-if="
              filterUtil.showArrowIndicator(item.filterChild) === 'both' &&
              (index_child === 'tilbudsfristDatoFra' || index_child === 'publikationDatoFra')
            ">
            {{ dateUtil.validFilterFormat(child) }} →
          </template>
          <template v-else>
            {{ dateUtil.validFilterFormat(child) }}
          </template>
        </span>
      </span>
    </div>
  </button>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';

import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils/date-util';
import { filterUtil } from '@/main/utils/filter-util';

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const count = ref<string | number>(0);

const soegningStore = useSoegningValgStore(window.pinia);

onMounted(async () => {
  count.value = await filterUtil.countfilter(props.index);
});

watch(
  soegningStore,
  async () => {
    count.value = await filterUtil.countfilter(props.index);
  },
  {
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '../../../styles/components/soegning/filter/_filter.scss';
</style>
