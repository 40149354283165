import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  legacy: false,
  allowComposition: true,
  fallbackLocale: 'da',
  globalInjection: true,
  locale: 'da'
});

export default i18n;
