<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="form-group search">
    <FeltLabel :hide="true" :for-id="inputFeltId" :label="label" :text-prefix="textPrefix" :advarsel="advarsel" name="soegefelt" />
    <span class="input-wrapper">
      <input
        :placeholder="placeholderExists() ? $t(`${textPrefix}.placeholder`) : undefined"
        :value="inputValue"
        :name="name"
        :id="inputFeltId"
        :aria-label="$t('soegefelt.aria.label')"
        :data-cy="name"
        :autocomplete="autocomplete"
        :type="type"
        class="form-input"
        @input="handleOnInput"
        @change="handleOnChange"
        @blur="handleOnBlur"
        :validateOnBlur="false"
        :validateOnChange="false"
        :validateOnInput="false" />
      <button
        v-if="erDerIndholdISoegefelt"
        type="button"
        class="button button-unstyled clear-button"
        data-cy="clear-search"
        :aria-label="$t('fjern.soegning.aria.label')"
        @click="clearInput">
        <svg class="icon-svg" focusable="false" aria-hidden="true">
          <use xlink:href="#close"></use>
        </svg>
      </button>
    </span>
    <button class="button button-search" data-cy="search-button" type="submit">
      <span class="sr-only">{{ $t(textPrefix + '.soeg.knap') }}</span>
      <svg class="icon-svg" focusable="false" aria-hidden="true" :aria-label="$t('soeg.aria.label')">
        <use xlink:href="#search"></use>
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import FeltLabel from '@/main/components/input/FeltLabel.vue';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { useSoegningValgStore } from '@/main/stores/soegning.state';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  textPrefix: {
    type: String,
    required: true
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  advarsel: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  decode: {
    type: Boolean,
    default: false
  }
});

const soegningStore = useSoegningValgStore(window.pinia);
const route = useRoute();
const emit = defineEmits([StandardEmitEvent.CHANGE, StandardEmitEvent.INPUT, StandardEmitEvent.BLUR, StandardEmitEvent.CLEAR]);
const autocomplete = ref(props.autocomplete);
const inputFeltId = ref(props.name + '-id');
const name = ref(props.name);
const {
  value: inputValue,
  setValue,
  setErrors,
  handleBlur,
  handleChange,
  validate
} = useField<string>(props.name, {
  validateOnValueUpdate: false
});

onMounted(() => {
  if (props.preselectedValue) {
    setValueCheckDecode(props.preselectedValue);
  }

  watch(
    () => props.preselectedValue,
    newValue => {
      setValueCheckDecode(newValue);
    }
  );

  watch(
    () => route.fullPath,
    () => {
      const newQueryValue = (route.query.soegeTekst as string) || '';
      setValueCheckDecode(newQueryValue);
    }
  );
});

function handleOnInput(e: Event) {
  handleChange(e, false);
  emit(StandardEmitEvent.INPUT);
}

function handleOnChange(e: Event) {
  handleChange(inputValue.value, true);
  emit(StandardEmitEvent.CHANGE, e);
}

function handleOnBlur(e: Event) {
  handleBlur(e, true);
  emit(StandardEmitEvent.BLUR);
}

const { te } = useI18n();

function setValueCheckDecode(newValue: string | undefined): void {
  if (props.decode) {
    setValue(decodeURI(newValue ?? ''), false);
  } else {
    setValue(newValue ?? '', false);
  }
}

function placeholderExists(): boolean {
  return te(`${props.textPrefix}.placeholder`);
}

const erDerIndholdISoegefelt = computed(() => inputValue.value?.length > 0);

function clearInput(event: Event) {
  event.stopPropagation();
  soegningStore.resetFritekstQuery();
  setValue('', false);
  emit(StandardEmitEvent.CLEAR);
}

defineExpose({
  inputValue,
  validate,
  setErrors,
  setValue
});
</script>
<style lang="scss" scoped>
@import '../../styles/input';
@import '../../styles/components/_soegeFelt.scss';
</style>
