<template>
  <button
    v-if="showTooltip && (!isEForms || eformsUtil.tooltipExists(name))"
    :id="tooltipId"
    :data-tooltip="$t(textOverride ?? eformsUtil.generateTooltipId(name))"
    class="button button-unstyled js-tooltip px-2"
    type="button"
    :aria-label="$t('tooltip-aria-label') + ' ' + tooltipId">
    <svg aria-hidden="true" class="icon-svg mr-0 tooltip-alignment" focusable="false">
      <use xlink:href="#help"></use>
    </svg>
  </button>
</template>

<script setup lang="ts">
import * as DKFDS from 'dkfds';
import { PropType, onMounted } from 'vue';

import { eformsUtil } from '@/main/utils/eforms-util';

onMounted(() => {
  if (document.getElementById(props.tooltipId) === null) {
    return;
  }
  new DKFDS.Tooltip(document.getElementById(props.tooltipId)).init();
});

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true
  },
  tooltipId: {
    type: String as PropType<string>,
    required: true
  },
  showTooltip: {
    type: Boolean,
    default: true
  },
  isEForms: {
    type: Boolean,
    default: true
  },
  textOverride: {
    type: String as PropType<string>,
    default: undefined
  }
});
</script>
