// stores/toastStore.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';

export interface Toast {
  id: number;
  type: 'success' | 'warning' | 'error' | 'info';
  headingKey: string;
  messageKey: string;
}

export const useToastStore = defineStore('toastStore', () => {
  const toasts = ref<Toast[]>([]);

  function createToast(type: 'success' | 'warning' | 'error' | 'info', heading: string, message: string) {
    const id = Date.now();
    toasts.value.push({ id, type, headingKey: heading, messageKey: message });
  }

  function removeToast(id: number) {
    toasts.value = toasts.value.filter(toast => toast.id !== id);
  }

  return {
    toasts,
    createToast,
    removeToast
  };
});
