<template>
  <div class="modal-body" data-cy="tips-til-soegning-modal-window">
    <ContentfulRigTekst :document="$tm('tips-til-soegning-modal.broedtekst')" />
    <div class="my-4">
      <RouterLink :to="Path.HJAELP_SOEG" class="link-text" @click="store.closeModal()" data-cy="link-til-soegning-hjaelpeside">
        {{ $t('tips-til-soegning-link-til-hjaelpeside') }}
      </RouterLink>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" @click="store.closeModal" class="button button-primary" data-cy="tips-til-soegning-modal-knap">
      {{ $t('tips-til-soegning-modal-knap') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { Path } from '@/main/enums/path.enum';
import { useModalStore } from '@/main/stores/modal.state';

const store = useModalStore(window.pinia);
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
