/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { HentPaaLoggetOrganisationsprofilParams, OrganisationsprofilDto } from '../../../models/generated';

export const getOrganisationsprofilController = () => {
  /**
   * @summary Henter organisationsprofilen. Hvis cvrNummer parameteret er undladt, så hentes profilen for den påloggede bruger.
   */
  const hentPaaLoggetOrganisationsprofil = <TData = AxiosResponse<OrganisationsprofilDto>>(
    params?: HentPaaLoggetOrganisationsprofilParams,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/organisationsprofil`, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  return { hentPaaLoggetOrganisationsprofil };
};
export type HentPaaLoggetOrganisationsprofilResult = AxiosResponse<OrganisationsprofilDto>;
