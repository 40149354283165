/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { HaendelseRequestDto } from '../../../models/generated';

export const getHaendelseController = () => {
  /**
   * @summary Forretningslogger brugeren som indlogget. Nødvendigt med selvstændigt endpoint da handlingen foregår mellem SPA og Sector9.
   */
  const logPaa = <TData = AxiosResponse<void>>(haendelseRequestDto: HaendelseRequestDto, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/haendelse/logPaa`, haendelseRequestDto, options);
  };
  /**
   * @summary Forretningslogger brugeren som logget ud. Indigent med selvstændigt endpoint da handlingen foregår mellem SPA og Sector9.
   */
  const logAf = <TData = AxiosResponse<void>>(haendelseRequestDto: HaendelseRequestDto, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/haendelse/logAf`, haendelseRequestDto, options);
  };
  return { logPaa, logAf };
};
export type LogPaaResult = AxiosResponse<void>;
export type LogAfResult = AxiosResponse<void>;
