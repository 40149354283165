import { SpaConfigDto } from '@/main/models/spaConfigDto';

import { SpaConfig } from './spaConfig';

export class SpaConfigService {
  variables: SpaConfigDto = {};

  configLoaded = false;

  public static getSpaConfig(): SpaConfigDto {
    let config: SpaConfigDto;
    const currentUrl = window.location.hostname;
    if (currentUrl.indexOf('lokal.') > -1) {
      config = SpaConfig.localConfig;
    } else if (currentUrl.indexOf('dev.') > -1) {
      config = SpaConfig.devConfig;
    } else if (currentUrl.indexOf('test2.') > -1) {
      config = SpaConfig.testConfig;
    } else if (currentUrl.indexOf('preprod.') > -1) {
      config = SpaConfig.preprodConfig;
    } else if (currentUrl.indexOf('demo.') > -1) {
      config = SpaConfig.demoConfig;
    } else {
      config = SpaConfig.prodConfig;
    }

    return {
      baseUrl: {
        adfaerd: SpaConfig.staticConfig.baseUrl?.adfaerd,
        api: config.baseUrl?.api,
        bruger: SpaConfig.staticConfig.baseUrl?.bruger,
        client: config.baseUrl?.client,
        konfiguration: SpaConfig.staticConfig.baseUrl?.konfiguration,
        soegning: SpaConfig.staticConfig.baseUrl?.soegning,
        udbud: SpaConfig.staticConfig.baseUrl?.udbud
      },
      sector9: {
        authUrl: config.sector9?.authUrl,
        mitIdClient: config.sector9?.mitIdClient,
        mitudbudeidasLegal: config.sector9?.mitudbudeidasLegal,
        mitudbudeidasnatural: config.sector9?.mitudbudeidasnatural,
        mitudbudssi: config.sector9?.mitudbudssi,
        redirectEndpoint: SpaConfig.staticConfig.sector9?.redirectEndpoint,
        tokenUrl: config.sector9?.tokenUrl
      }
    } as SpaConfigDto;
  }
}
