export enum StandardEmitEvent {
  CHANGE = 'change',
  FROM_CHANGE = 'from-changed',
  TO_CHANGE = 'to-changed',
  HANDLE_SUBMIT = 'handleSubmit',
  SUBMIT_BUT_ERRORS = 'submitButErrors',
  INPUT = 'input',
  BLUR = 'blur',
  CLEAR = 'clear',
  CLICK = 'click',
  TOGGLE = 'toggle',
  CHECKED = 'checked',
  ERROR_CHANGE = 'error-change',
  VALID_CHANGE = 'valid-change',
  UPDATE = 'update',
  SUBMIT = 'submit',
  NUMBER_OF_RESULTS_CHANGED = 'number-of-results-changed',
  PAGE_CHANGED = 'page-changed',
  CHANGE_PAGE = 'changePage',
  DELETE = 'delete',
  EDIT = 'edit',
  JUMP_TO_FIELD = 'jumpToField',
  TRIN_CLICKED = 'trinClicked',
  TRIN_FEJL_CLICKED = 'trinFejlClicked',
  ERROR = 'error',
  IKON_LINK_CALLBACK = 'ikonLinkCallback',
  FILTER_CHECKBOX_UPDATE = 'FilterCheckboxUpdate',
  RADIO_BUTTON_UPDATE = 'radioButtonUpdate',
  SKIFT_TRIN = 'skiftTrin',
  UPLOAD_FIL = 'upload-fil',
  ALL_UPLOADS_DONE = 'all-uploads-done',
  ACCORDION_STATE_CHANGE = 'accordion-state-change',
  SELECTED_CHECKBOXES_UPDATE = 'selected-checkboxes-update',
  ADD_CPV = 'add-cpv',
  REMOVE_CPV = 'remove-cpv',
  CHANGE_PRIMARY_CPV = 'change-primary-cpv',
  PRIMARY_CPV = 'primary-cpv',
  TOGGLE_DROPDOWN = 'toggle-dropdown',
  CLOSE_DROPDOWN = 'close-dropdown',
  FORCE_RERENDER = 'force-rerender',
  UPLOAD_COUNT_CHANGED = 'upload-count-changed'
}
