<template>
  <div class="p-4 pb-3" data-cy="cpv-filter-vindue">
    <h2 class="h4 m-0" data-cy="cpv-filter-overskrift">
      {{ $t('filter.' + konfigurationNavn + '.overskift') }}
    </h2>
    <p data-cy="cpv-filter-broedtekst">
      {{ $t('filter.' + konfigurationNavn + '.manchet') }}
    </p>
    <div>
      <div class="form-group mb-0">
        <Soegefelt @change="handleSearch" @clear="handleClear" name="search-cpv-query" :text-prefix="'soegefelt.cpv-koder'" class="soegefelt-hoejde">
        </Soegefelt>
      </div>
      <div class="cpv-kode-wrapper pb-4 px-4 pt-2 mb-6 bt-0 mt-6 bordered-list">
        <div>
          <CPVKodeListe
            v-if="isLoaded && cpvRoot"
            :cpv-liste-item="cpvRoot"
            :selected-checkboxes="selectedCheckboxes"
            :query="searchQuery"
            :konfiguration-navn="konfigurationNavn"
            :is-multi-select="true"
            @add-cpv="onCpvAdd"
            @remove-cpv="onCpvRemove">
          </CPVKodeListe>
          <p v-if="antalAfSoegeResultater === 0 && isLoaded">{{ $t('eforms.cpv.ingen-soege-resultater') }}</p>
        </div>
        <div v-if="!isLoaded">
          <LoadingSpinner :loading-text="'formular.publicering.igang'" :timer-length="200" />
        </div>
      </div>
      <ContentfulRigTekst v-if="!isMobile" :document="$tm('filter.subtekst')" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ComputedRef, PropType, computed, onMounted, ref, watch } from 'vue';

import CPVKodeListe from '@/main/components/CPVKodeListe.vue';
import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import LoadingSpinner from '@/main/components/LoadingSpinner.vue';
import Soegefelt from '@/main/components/input/Soegefelt.vue';
import { CustomFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { SprogLocale } from '@/main/enums/sprog.enum';
import { Visning } from '@/main/enums/visning.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { useEFormsKodelisterStore } from '@/main/stores/eforms-kodelister.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { useSprogStore } from '@/main/stores/sprog.state';
import { applyFilter } from '@/main/utils/cpv-koder-util';
import { filterUtil } from '@/main/utils/filter-util';

const props = defineProps({
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    required: true
  }
});

export interface SelectedCheckbox {
  value: string;
  label: string;
}

export interface CPVListeItem {
  codeValue: string;
  label: string;
  searchLabel: string;
  children: CPVListeItem[];
  filterMatch?: boolean;
  barnMatch?: boolean;
}

const eformsKodelisterStore = useEFormsKodelisterStore();
const soegningValgStore = useSoegningValgStore();
const antalAfSoegeResultater = ref(0);

const cpvRoot = ref<CPVListeItem | undefined>(undefined);
const cpvKodelisteVaerdierList = ref<DropdownOption[]>([]);
const sprogStore = useSprogStore();

const searchQuery = ref<string[]>([]);
const isLoaded = ref<boolean>(false);
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => screenWidth.value < Visning.GRID_BREAKPOINT_MD);

const selectedCheckboxes: ComputedRef<SelectedCheckbox[]> = computed(() => {
  return filterUtil.beregnAktiveCpvKoder(
    soegningValgStore.cpvValg.positivListe,
    soegningValgStore.cpvValg.negativListe,
    cpvKodelisteVaerdierList.value
  );
});

onMounted(async () => {
  await fetchCPVData(sprogStore.sprog);
});

watch(
  () => sprogStore.sprog,
  async newLocale => {
    await fetchCPVData(newLocale);
  }
);

// Få CPV data ind
async function fetchCPVData(locale: SprogLocale) {
  const CPVKodelisteData = await eformsKodelisterStore.hentKodelisteVaerdierMaaskeFraCache(locale, 'cpv');

  cpvKodelisteVaerdierList.value = CPVKodelisteData.map(x => ({
    value: x.codeValue,
    label: x.da! || x.en!
  }));

  cpvRoot.value = applyFilter(cpvKodelisteVaerdierList.value, [], antalAfSoegeResultater);
  isLoaded.value = true;
}

function onCpvAdd(item: SelectedCheckbox, nested: boolean) {
  soegningValgStore.addCPVKode(item.value, nested);
}

function onCpvRemove(item: SelectedCheckbox, nested: boolean) {
  soegningValgStore.removeCPVKode(item.value, nested);
}

function handleSearch(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement) {
    console.error('Event target is not an input element');
    return;
  }
  const query = inputElement.value;
  const cleanQuery = query
    .trim()
    .split(' ')
    .filter(x => x.trim() !== '')
    .map(x => x.toLocaleLowerCase());

  cpvRoot.value = applyFilter(cpvKodelisteVaerdierList.value, cleanQuery, antalAfSoegeResultater);
  searchQuery.value = cleanQuery;
}

function handleClear() {
  handleSearch({ target: { value: '' } } as unknown as Event);
}
</script>

<style lang="scss" scoped>
@import '@/main/styles/components/soegning/filter/_filter.scss';
@import '@/main/styles/components/_mainSoegeFelt';
</style>
