export enum FeltBredde {
  FELTBREDDE_XXS = 'input-width-xxs',
  FELTBREDDE_XS = 'input-width-xs',
  FELTBREDDE_S = 'input-width-s',
  FELTBREDDE_M = 'input-width-m',
  FELTBREDDE_L = 'input-width-l',
  FELTBREDDE_XL = 'input-width-xl',
  FELTBREDDE_XXL = 'input-width-xxl',
  FELTBREDDE_XXXL = 'input-width-xxxl'
}
