/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Formulartype, custom defineret, anvendes som en kombination af OPP-070-notice og BT-03-notice.
 */
export type FilterDtoFormularTypeItem = (typeof FilterDtoFormularTypeItem)[keyof typeof FilterDtoFormularTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterDtoFormularTypeItem = {
  FORVENTET_INDKOEB: 'FORVENTET_INDKOEB',
  MARKEDSDIALOGER: 'MARKEDSDIALOGER',
  FORHAANDSMEDDELELSER: 'FORHAANDSMEDDELELSER',
  EU_UDBUD: 'EU_UDBUD',
  NATIONALE_UDBUD: 'NATIONALE_UDBUD',
  DIREKTE_TILDELINGER: 'DIREKTE_TILDELINGER',
  TILDELINGER: 'TILDELINGER',
  KONTRAKTAENDERINGER: 'KONTRAKTAENDERINGER',
  KONTRAKTAFSLUTNING: 'KONTRAKTAFSLUTNING'
} as const;
