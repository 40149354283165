import { AxiosError, AxiosResponse } from 'axios';
import { ComputedRef } from 'vue';

import { Komponent } from '@/main/enums/komponent.enum';
import { BrugerprofilDto, BrugerprofilDtoSprogValg } from '@/main/models/generated';
import { getBrugerprofilController } from '@/main/services/generated/brugerprofil-controller/brugerprofil-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { authState } from '@/main/stores/auth.state';
import { useModalStore } from '@/main/stores/modal.state';
import { useSprogStore } from '@/main/stores/sprog.state';

import { SprogLocale } from '../enums/sprog.enum';
import { Bruger } from '../models/bruger.model';
import { computedBruger } from '../stores/auth.state.bruger';

export class BrugerprofilService {
  async opretBrugerprofil(email: string): Promise<AxiosResponse<BrugerprofilDto>> {
    const sprogState = useSprogStore(window.pinia);
    const sprogValg = sprogState.hentSprog === SprogLocale.English ? BrugerprofilDtoSprogValg.EN : BrugerprofilDtoSprogValg.DA;
    const dto: BrugerprofilDto = {
      email: email,
      sprogValg: sprogValg
    };
    const config = AuthService.getConfig(Komponent.Bruger, false);
    const result = await getBrugerprofilController().opret(dto, config);
    this.saetBrugerprofilData(result.data);
    return result;
  }

  async redigerBrugerprofil(email?: string): Promise<AxiosResponse<BrugerprofilDto>> {
    const bruger: ComputedRef<Bruger> = computedBruger();
    const emailToUse = email ?? bruger.value.email ?? '';

    const sprogState = useSprogStore(window.pinia);
    const sprogValg = sprogState.hentSprog === SprogLocale.English ? BrugerprofilDtoSprogValg.EN : BrugerprofilDtoSprogValg.DA;
    const dto: BrugerprofilDto = {
      email: emailToUse,
      sprogValg: sprogValg
    };
    const config = AuthService.getConfig(Komponent.Bruger, false);
    return await getBrugerprofilController().rediger(dto, config);
  }

  async hentBrugerprofil(): Promise<BrugerprofilDto | null> {
    const modalStore = useModalStore(window.pinia);
    const config = AuthService.getConfig(Komponent.Bruger, false);

    return await getBrugerprofilController()
      .hentPaaLoggetBrugerprofil(config)
      .then(async response => {
        const data = response.data;
        // Overskriv sprogvalg med brugerprofil
        this.saetBrugerprofilData(data);
        return data;
      })
      .catch((err: AxiosError) => {
        if (err.response?.status == 404) {
          modalStore.openBrugerprofilOprettelseModal();
        }
        return null;
      });
  }

  private saetBrugerprofilData(data: BrugerprofilDto) {
    const sprogStore = useSprogStore(window.pinia);
    sprogStore.updateSprog(data.sprogValg === BrugerprofilDtoSprogValg.DA ? SprogLocale.Dansk : SprogLocale.English);
    if (authState.state) {
      authState.state.userEmail = data.email;
      authState.state.brugerProfilDto = data;
    }
  }
}

export const brugerprofilService = new BrugerprofilService();
