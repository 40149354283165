import { DropdownOption } from '@/main/models/base/DropdownOption';

export const SDK_VERSION_VALGMULIGHEDER = [
  {
    key: '1.7',
    value: 'eforms-sdk-1.7.4'
  },
  {
    key: '1.8',
    value: 'eforms-sdk-1.8.3'
  },
  {
    key: '1.9',
    value: 'eforms-sdk-1.9.3'
  },
  {
    key: '1.10',
    value: 'eforms-sdk-1.10.2'
  },
  {
    key: 'eforms-sdk-dk-1.10',
    value: 'eforms-sdk-dk-1.10.6'
  },
  {
    key: 'eforms-sdk-dk-1.11',
    value: 'eforms-sdk-dk-1.11.0'
  }
] as DropdownOption[];

export const DEFAULT_SDK_VERSION = 'eforms-sdk-dk-1.11.0';

export const DEFAULT_NOTICE_TYPE = 'DKE3';
