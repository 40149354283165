<template>
  <header class="header d-print-none">
    <div class="row no-gutters">
      <div class="col-6 col-md-2 align-self-center">
        <Link :link="{ name: MainRoute.FORSIDE }" class="logo" aria-label-textkey="header-logo-aria_label" />
      </div>
      <div class="col-6 col-md-10 d-flex justify-content-end">
        <div class="d-flex align-items-center">
          <TopNav v-if="isConfigLoaded"></TopNav>
        </div>
      </div>
    </div>
  </header>
</template>
<script lang="ts" setup>
import Link from '@/main/components/Link.vue';
import TopNav from '@/main/components/TopNav.vue';
import { MainRoute } from '@/main/enums/mainRoute.enum';

import { environmentConfig } from '../services/environmentConfig.service';

const isConfigLoaded = environmentConfig.configLoaded;
</script>
<style lang="scss" scoped>
@import '../styles/_header';
</style>
