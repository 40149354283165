export enum CustomFilterKonfigurationNavn {
  FILTER_PUBLICERINGSDATO_DATO = 'FilterPubliceringsDato',
  FILTER_TILBUDSFRIST = 'FilterTilbudsfrist',
  FILTER_ANSLAAET_VAERDI = 'FilterAnslaaetVaerdi',
  FILTER_ORDREGIVER = 'FilterOrdregiver',
  FILTER_CPV_KODER = 'FilterCPVKoder'
}

export enum CodeListFilterKonfigurationNavn {
  FILTER_FORMULARTYPE = 'FilterFormulartype',
  FILTER_SMV_VENLIGHED = 'FilterSMVVenlighed',
  FILTER_TYPE_AF_OPGAVE = 'FilterTypeAfOpgave',
  FILTER_TYPE_AF_PROCEDURE = 'FilterTypeAfProcedure'
}

export enum typeOfFilter {
  FILTERTYPE_FORMULARTYPE = 'form-type-dk',
  FILTERTYPE_SMV_VENLIGHED = 'indicator',
  FILTERTYPE_TYPE_AF_OPGAVE = 'eforms-contract-nature',
  FILTERTYPE_TYPE_AF_PROCEDURE = 'procurement-procedure-type'
}

export enum DatoType {
  DATO_FRA = 'dato-fra',
  DATO_TIL = 'dato-til'
}

export enum VaerdiType {
  ANSLAAET_VAERDI_FRA = 'anslaaet-vaerdi-fra',
  ANSLAAET_VAERDI_TIL = 'anslaaet-vaerdi-til',
  ANSLAAET_VAERDI_VALUTA = 'anslaaet-vaerdi-kode'
}

export type FilterKonfigurationNavn = CustomFilterKonfigurationNavn | CodeListFilterKonfigurationNavn;
