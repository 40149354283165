<template>
  <div v-if="cpvListeItem !== undefined && cpvListeItem.children.length > 0 && cpvListeItem.barnMatch" class="cpv-kode-liste-wrapper">
    <div v-for="item in cpvListeItem.children" :key="item.codeValue">
      <template v-if="item.filterMatch || item.barnMatch">
        <div style="display: inline-flex">
          <button
            @click.stop="toggleExpand(item.codeValue)"
            v-if="item.children.length > 0"
            type="button"
            class="button button-unstyled"
            aria-label="fold ud eller ind">
            <svg v-if="expandedItems[item.codeValue]" class="icon-svg mr-0 mt-0 svg-expanded-ikon" focusable="false" aria-hidden="true">
              <use xlink:href="#chevron-right"></use>
            </svg>
            <svg v-else class="icon-svg mr-0 mt-0" focusable="false" aria-hidden="true">
              <use xlink:href="#chevron-right"></use>
            </svg>
            <span class="sr-only">{{ $t('fold-ud-sr-only') }}</span>
          </button>
          <div
            class="form-group-checkbox cpv-form-group mt-0"
            :class="{ 'cpv-align-checkboxes': item.children.length == 0, 'ml-5': item.children.length == 0 }">
            <input
              type="checkbox"
              :id="item.codeValue"
              :name="item.label"
              class="form-checkbox"
              :value="item.codeValue"
              :indeterminate="isIndeterminateList.find(x => x.cpv == item.codeValue)!.indeterminate"
              :aria-checked="isIndeterminateList.find(x => x.cpv == item.codeValue)!.ariaValue"
              :checked="isIndeterminateList.find(x => x.cpv == item.codeValue)!.checked"
              @click="event => handleCheckboxChange(item, event)"
              @keydown.enter="handleCheckboxChange(item)" />
            <label class="form-label" :for="item.codeValue">
              <!-- NOSONAR -->
              <div v-html="highlightSearchTerms(query, item)"></div>
            </label>
          </div>
        </div>
        <hr class="my-2" />
      </template>

      <div v-if="expandedItems[item.codeValue] && item" class="pl-6">
        <CPVKodeListe
          :cpv-liste-item="item"
          :selected-checkboxes="selectedCheckboxes"
          :query="query"
          :is-multi-select="props.isMultiSelect"
          @add-cpv="emitNestedAddCpv"
          @remove-cpv="emitNestedRemoveCpv" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref, watch } from 'vue';

import { CPVListeItem, SelectedCheckbox } from '@/main/components/eForms/formular/custom/EFormsCPVKodeVaelger.vue';
import { CustomFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { highlightSearchTerms } from '@/main/utils/cpv-koder-util';

import { filterUtil } from '../utils/filter-util';

const emit = defineEmits<{
  'add-cpv': [item: SelectedCheckbox, nested: boolean];
  'remove-cpv': [item: SelectedCheckbox, removeChildren: boolean];
}>();

const props = defineProps({
  cpvListeItem: {
    type: Object as PropType<CPVListeItem>,
    required: true
  },
  selectedCheckboxes: {
    type: Array as PropType<SelectedCheckbox[]>,
    required: true
  },
  query: {
    type: Array as PropType<string[]>,
    required: true
  },
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    default: ''
  },
  isMultiSelect: {
    type: Boolean,
    default: false
  }
});

const results: { [key: string]: boolean } = {};
const expandedItems = ref<{ [key: string]: boolean }>(results);
const queryRef = ref(props.query);

const isChecked = (cpvListeItem: CPVListeItem): boolean => {
  return props.selectedCheckboxes.some(selectedCpvCode => selectedCpvCode.value === cpvListeItem.codeValue);
};

const isIndeterminateList = computed(() => {
  return props.cpvListeItem.children.map(x => {
    const indeterminate = isIndeterminate(x);
    const checked = isChecked(x);
    const maybeIndeterminate = indeterminate ? 'mixed' : 'false';
    return {
      cpv: x.codeValue,
      checked: checked, // Må kun være checked hvis den ikke er indeterminate
      indeterminate: indeterminate && !checked,
      ariaValue: (checked ? 'true' : maybeIndeterminate) as 'mixed' | 'true' | 'false'
    };
  });
});

const isIndeterminate = (cpvListeItem: CPVListeItem): boolean => {
  const currentPrefix = filterUtil.getCpvPrefix(cpvListeItem.codeValue);
  const newIndeterminate = props.selectedCheckboxes.some(x => x.value.startsWith(currentPrefix));
  return newIndeterminate;
};

expandMatches(props.cpvListeItem!, results);

function expandMatches(cpvListeItem: CPVListeItem, result: { [key: string]: boolean }): void {
  if (props.query.length > 0) {
    for (const barn of cpvListeItem.children) {
      if (barn.barnMatch) {
        result[barn.codeValue] = true;
      }
    }
  }
}

watch(
  () => props.query,
  newVal => {
    queryRef.value = [...newVal];
  },
  { deep: true }
);

watch(
  queryRef,
  () => {
    const newResult: { [key: string]: boolean } = {};
    expandMatches(props.cpvListeItem!, newResult);
    expandedItems.value = newResult;
  },
  { deep: true }
);

const handleCheckboxChange = (item: CPVListeItem, event?: MouseEvent, ctrlClicked?: boolean) => {
  const isCtrlPressed = (ctrlClicked || event?.ctrlKey || event?.metaKey) ?? false;
  const isSelected = props.selectedCheckboxes.some(checkbox => checkbox.value === item.codeValue);

  if (isCtrlPressed) {
    if (!isSelected) {
      emit(StandardEmitEvent.ADD_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, false);
    } else {
      emit(StandardEmitEvent.REMOVE_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, false);
    }
  } else if (props.isMultiSelect) {
    if (isSelected) {
      emit(StandardEmitEvent.REMOVE_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, true);
    } else {
      toggleExpandWhenClicked(item.codeValue);
      emit(StandardEmitEvent.ADD_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, true);
    }
  } else if (!isSelected) {
    emit(StandardEmitEvent.ADD_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, true);
  } else {
    emit(StandardEmitEvent.REMOVE_CPV, { value: item.codeValue, label: item.label } as SelectedCheckbox, true);
  }
};

const toggleExpandWhenClicked = (codeValue: string) => {
  if (props.isMultiSelect) {
    expandedItems.value[codeValue] = true;
  }
};

const toggleExpand = (codeValue: string) => {
  expandedItems.value[codeValue] = !expandedItems.value[codeValue];
};

const emitNestedAddCpv = (item: SelectedCheckbox, nested: boolean) => {
  emit('add-cpv', item, nested);
};

const emitNestedRemoveCpv = (item: SelectedCheckbox, nested: boolean) => {
  emit('remove-cpv', item, nested);
};
</script>
<style lang="scss" scoped>
@import '@/main/styles/components/_eformsFormular';
@import '@/main/styles/components/_dkfds10Checkbox';
</style>
