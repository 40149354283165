<template>
  <div
    :id="id"
    v-if="errorMessages && errorCount > 0"
    class="alert alert-error mt-0 mb-8"
    data-module="error-summary"
    data-cy="fejl-opsummering-alert"
    role="alert">
    <div class="alert-body">
      <h2 class="alert-heading">{{ customHeading !== undefined ? $t(customHeading) : $t('fejlopsummering.titel') }}</h2>
      <ul class="alert-text nobullet-list">
        <li v-for="(value, key) in errorMessages" :key="key">
          <router-link :to="'#' + key + '-id'" class="function-link">{{ $t(getErrorKey(key, value)) }}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ErrorSummary } from 'dkfds';
import { PropType, getCurrentInstance, onUpdated, ref, watch } from 'vue';

const props = defineProps({
  errorMessages: {
    type: Object as PropType<Partial<Record<string, string | undefined>>>,
    required: true
  },
  fejlPrefix: {
    type: Object,
    default: undefined
  },
  customHeading: {
    type: String as PropType<string>,
    default: undefined
  }
});

const instance = getCurrentInstance();
const id = 'errorsummary-' + instance?.uid.toString();
const errorCount = ref(0);
countErrors();

onUpdated(() => {
  const errorSummaryElement = document.getElementById(id);
  if (errorSummaryElement && !errorSummaryElement.classList.contains('initialized')) {
    errorSummaryElement.classList.add('initialized');
    new ErrorSummary(errorSummaryElement).init();
  }
});

watch(
  () => props.errorMessages,
  (newValue, prevValue) => {
    countErrors();
  }
);

function countErrors() {
  errorCount.value = Object.keys(props.errorMessages).length;
}

const getErrorKey = (field: string, value: string) => {
  const prefix = props.fejlPrefix?.[field] ? props.fejlPrefix[field] : 'input';
  return prefix + '.' + value;
};
</script>
