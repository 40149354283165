/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { BekendtgoerelseKladdeMetadataDto, HarKladdeDto, VisualModelNode } from '../../../models/generated';

export const getBekendtgoerelseKladdeController = () => {
  /**
   * @summary Henter kladde ud fra noticeId og noticeVersion. Du kan kun hente kladder, som tilhører din bruger.
   */
  const findByNoticeIdAndNoticeVersion = <TData = AxiosResponse<VisualModelNode>>(
    noticeId: string,
    noticeVersion: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/kladde/${noticeId}/${noticeVersion}`, options);
  };
  /**
   * @summary Opdaterer kladde ud fra noticeId og noticeVersion og VisualModel.
   */
  const update = <TData = AxiosResponse<void>>(
    noticeId: string,
    noticeVersion: string,
    visualModelNode: VisualModelNode,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.put(`/kladde/${noticeId}/${noticeVersion}`, visualModelNode, options);
  };
  /**
   * @summary Opretter ny kladde ud fra noticeId, noticeVersion og VisualModel.
   */
  const create = <TData = AxiosResponse<void>>(
    noticeId: string,
    noticeVersion: string,
    visualModelNode: VisualModelNode,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/kladde/${noticeId}/${noticeVersion}`, visualModelNode, options);
  };
  /**
   * @summary Sletter kladde ud fra noticeId og noticeVersion og VisualModel.
   */
  const _delete = <TData = AxiosResponse<void>>(noticeId: string, noticeVersion: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/kladde/${noticeId}/${noticeVersion}`, options);
  };
  /**
   * @summary Henter alle kladder, som tilhører den nuværende bruger. Er ikke pagineret.
   */
  const findAllByUser = <TData = AxiosResponse<BekendtgoerelseKladdeMetadataDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/kladde`, options);
  };
  /**
   * @summary Returnerer true hvis brugeren har nogen kladder
   */
  const harBrugerNogenKladder = <TData = AxiosResponse<HarKladdeDto>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/kladde:harKladder`, options);
  };
  return { findByNoticeIdAndNoticeVersion, update, create, _delete, findAllByUser, harBrugerNogenKladder };
};
export type FindByNoticeIdAndNoticeVersionResult = AxiosResponse<VisualModelNode>;
export type UpdateResult = AxiosResponse<void>;
export type CreateResult = AxiosResponse<void>;
export type _DeleteResult = AxiosResponse<void>;
export type FindAllByUserResult = AxiosResponse<BekendtgoerelseKladdeMetadataDto[]>;
export type HarBrugerNogenKladderResult = AxiosResponse<HarKladdeDto>;
