<template>
  <div :class="[`alert ${alertType}`, { 'alert--show-icon': visIkon }]" :id="id" :role="role">
    <div class="alert-body">
      <h2 class="alert-heading">{{ titel }}</h2>
      <p class="alert-text">
        {{ besked }}
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: false,
    default: ''
  },
  type: {
    type: String,
    default: 'info'
  },
  titel: {
    type: String,
    default: '',
    required: true
  },
  besked: {
    type: String,
    default: '',
    required: false
  },
  visIkon: {
    type: Boolean,
    default: false
  }
});

// Tilføj role: "alert" hvis typen er error eller warning
const role = computed(() => {
  if (props.type === 'error' || props.type === 'warning') {
    return 'alert';
  } else {
    return '';
  }
});

const alertType = computed(() => {
  switch (props.type) {
    case 'info':
      return 'alert-info';
    case 'warning':
      return 'alert-warning';
    case 'error':
      return 'alert-error';
    case 'success':
      return 'alert-success';
    default:
      return 'alert-info';
  }
});
</script>
<style lang="scss" scoped>
@import '../../styles/components/_baseBesked';
</style>
