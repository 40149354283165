/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type {
  EgenBekendtgoerelseDto,
  HarBekendtgoerelserDto,
  HentAlleBekendtgoerelserParams,
  HentBekendtgoerelseSomVisualModelParams,
  HentBekendtgoerelseVMCFraIdParams,
  KoeberInfo,
  MinimalBekendtgoerelseDto,
  OpdaterRolleDataParams,
  OrganisationsAdresseoplysningerDto,
  PubliceringResultat,
  ValideringResultatDto,
  VisualModelContainer,
  VisualModelNode
} from '../../../models/generated';

export const getBekendtgoerelseController = () => {
  /**
   * @summary Validerer VisualModel ved at oversætte til PhysicalModel og køre den samlede for bekendtgørelsen relevante schematron- og XSD-pakke.
   */
  const valider1 = <TData = AxiosResponse<ValideringResultatDto>>(
    sdkVersion: string,
    visualModelNode: VisualModelNode,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/bekendtgoerelse/${sdkVersion}/valider`, visualModelNode, options);
  };
  /**
 * Valideres mod for bekendtgørelsen relevante schematronregler samt XSD-regler.

 * @summary Forsøger at publicere bekendtgørelsen ud fra den visuelle model.
 */
  const forsoegPublicer = <TData = AxiosResponse<PubliceringResultat>>(
    sdkVersion: string,
    visualModelNode: VisualModelNode,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/bekendtgoerelse/${sdkVersion}/publicer`, visualModelNode, options);
  };
  /**
 * Hvis tidspunkt ikke er angivet, vil alle roller opdateres.

 * @summary Opdaterer alle rolledata, som er last-modified før angivne tidspunkt, f.eks. ved opdatering af format.
 */
  const opdaterRolleData = <TData = AxiosResponse<boolean>>(params?: OpdaterRolleDataParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/bekendtgoerelse/rolledata:opdater`, undefined, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  /**
   * @summary Backfill rolle-data: Prøver at udfylde rolledata for alle bekendtgørelser, der mangler dette.
   */
  const backfillRolleData = <TData = AxiosResponse<boolean>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/bekendtgoerelse/rolledata:backfill`, undefined, options);
  };
  /**
 * Mapper disse til VisualModelContainer.

 * @summary Henter alle bekendtgørelser fra databasen (på en page)
 */
  const hentAlleBekendtgoerelser = <TData = AxiosResponse<VisualModelContainer[]>>(
    params?: HentAlleBekendtgoerelserParams,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/bekendtgoerelse`, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  /**
   * @summary Henter en VisualModelNode fra en bekendtgørelse ud fra noticeId, noticeVersion og noticePublicationNumber.
   */
  const hentBekendtgoerelseSomVisualModel = <TData = AxiosResponse<VisualModelNode>>(
    noticeId: string,
    noticeVersion: string,
    params?: HentBekendtgoerelseSomVisualModelParams,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/bekendtgoerelse:hentBekendtgoerelseSomVisualModel/${noticeId}/${noticeVersion}`, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  /**
 * Henter 10 bekendtgørelser ud fra en noticeSubType, så man kan bruge dem til genbrug.

 * @summary Bruges kun til enabler/demo funktionalitet!
 */
  const hentBekendtgoerelseAfNoticeSubType = <TData = AxiosResponse<MinimalBekendtgoerelseDto[]>>(
    type: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/bekendtgoerelse:hentBekendtgoerelseAfNoticeSubType/${type}`, options);
  };
  /**
   * @summary Tjekker og brugeren har nogen bekendtgørelser publiceret
   */
  const harBrugerNogenBekendtgoerelser = <TData = AxiosResponse<HarBekendtgoerelserDto>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/bekendtgoerelse:harNogle`, options);
  };
  /**
   * @summary Henter metadata for alle bekendtgørelser, som er relevante for den indloggede bruger.
   */
  const hentEgneBekendtgoerelser = <TData = AxiosResponse<EgenBekendtgoerelseDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/bekendtgoerelse:egne`, options);
  };
  /**
   * @summary Henter en VisualModelContainer fra en bekendtgørelse ud fra noticeId, noticeVersion og noticePublicationNumber.
   */
  const hentBekendtgoerelseVMCFraId = <TData = AxiosResponse<VisualModelContainer>>(
    noticeId: string,
    noticeVersion: string,
    params?: HentBekendtgoerelseVMCFraIdParams,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/bekendtgoerelse/${noticeId}/${noticeVersion}`, {
      ...options,
      params: { ...params, ...options?.params }
    });
  };
  /**
   * @summary Henter alle organisationer med mindst en bekendtgørelse, hvor organisationen har rollen køber.
   */
  const hentAlleKoebersRolledata = <TData = AxiosResponse<KoeberInfo[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/bekendtgoerelse/rolledata:alleKoeber`, options);
  };
  /**
   * @summary Bruges til at hente virksomhedsdata for et givent CVR-nummer.
   */
  const hentOrganisationsOplysninger = <TData = AxiosResponse<OrganisationsAdresseoplysningerDto>>(
    cvr: string,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.get(`/bekendtgoerelse/organisationsOplysninger/${cvr}`, options);
  };
  return {
    valider1,
    forsoegPublicer,
    opdaterRolleData,
    backfillRolleData,
    hentAlleBekendtgoerelser,
    hentBekendtgoerelseSomVisualModel,
    hentBekendtgoerelseAfNoticeSubType,
    harBrugerNogenBekendtgoerelser,
    hentEgneBekendtgoerelser,
    hentBekendtgoerelseVMCFraId,
    hentAlleKoebersRolledata,
    hentOrganisationsOplysninger
  };
};
export type Valider1Result = AxiosResponse<ValideringResultatDto>;
export type ForsoegPublicerResult = AxiosResponse<PubliceringResultat>;
export type OpdaterRolleDataResult = AxiosResponse<boolean>;
export type BackfillRolleDataResult = AxiosResponse<boolean>;
export type HentAlleBekendtgoerelserResult = AxiosResponse<VisualModelContainer[]>;
export type HentBekendtgoerelseSomVisualModelResult = AxiosResponse<VisualModelNode>;
export type HentBekendtgoerelseAfNoticeSubTypeResult = AxiosResponse<MinimalBekendtgoerelseDto[]>;
export type HarBrugerNogenBekendtgoerelserResult = AxiosResponse<HarBekendtgoerelserDto>;
export type HentEgneBekendtgoerelserResult = AxiosResponse<EgenBekendtgoerelseDto[]>;
export type HentBekendtgoerelseVMCFraIdResult = AxiosResponse<VisualModelContainer>;
export type HentAlleKoebersRolledataResult = AxiosResponse<KoeberInfo[]>;
export type HentOrganisationsOplysningerResult = AxiosResponse<OrganisationsAdresseoplysningerDto>;
