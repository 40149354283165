<template>
  <div>
    <BaseCheckboxGroup
      name="checkbox-small"
      :options="mappedOptions"
      :preselected-values="getSelected()"
      @filter-checkbox-update="setFilterChild"
      />
  </div>
</template>

<script lang="ts" setup>
import { PropType, computed } from 'vue';

import BaseCheckboxGroup from '@/main/components/base/BaseCheckboxGroup.vue';
import { CustomFilterKonfigurationNavn } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { filterUtil } from '@/main/utils/filter-util';

const props = defineProps({
  cvrList: {
    type: Array as PropType<KoeberInfo[]>,
    default: () => []
  },
  selectedCheckboxes: {
    type: Array as PropType<KoeberInfo[]>,
    default: () => []
  },
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    required: true
  }
});

const emit = defineEmits<(event: 'filter-checkbox-update', values: string[]) => void>();

const soegningStore = useSoegningValgStore();

const mappedOptions = computed((): RadioOgCheckboxModel[] =>
  props.cvrList.map(item => ({
    value: item.organisationsIdDatavasket,
    label: `${item.organisationsNavn} (${item.organisationsIdDatavasket})`,
    id: item.organisationsIdDatavasket,
    checked: props.selectedCheckboxes.some(checkbox => checkbox.organisationsIdDatavasket === item.organisationsIdDatavasket)
  }))
);

function setFilterChild(values: string[]) {
  if (!filterUtil.arrayMatch(values, soegningStore.getSelectedCustomValue(props.konfigurationNavn) as string[])) {
    soegningStore.updateCodelistToCheckboxFilter(props.konfigurationNavn, values);
    emit('filter-checkbox-update', values);
  }
}

function getSelected(): string[] {
  const options = mappedOptions.value.filter(i => soegningStore.getSelectedCustomValue(props.konfigurationNavn)?.includes(i.id as string));
  return options.map<string>(i => (i.id ? i.id : ''));
}
</script>
