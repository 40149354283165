export enum FieldType {
  ID = 'id',
  ID_REF = 'id-ref',
  TEXT_MULTILINGUAL = 'text-multilingual',
  TEXT = 'text',
  DATE = 'date',
  INDICATOR = 'indicator',
  CODE = 'code',
  AMOUNT = 'amount',
  MEASURE = 'measure',
  INTEGER = 'integer',
  NUMBER = 'number',
  TIME = 'time',
  URL = 'url',
  EMAIL = 'email',
  PHONE = 'phone',
  DOKUMENT_URL_FELT = 'EFormsDokumentUrlFelt'
}
