/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { OpdaterVirksomhedsRolleDto, OpretVirksomhedsRolleDto, VirksomhedsRolleDto } from '../../../models/generated';

export const getVirksomhedsRolleController = () => {
  /**
   * Henter kun hvorvidt et CVR-nummer er ordregiver, hvis den indloggede bruger repræsenterer det angivne CVR-nummer
   * @summary Henter virksomhedsrolle for CVR-nummeret.
   */
  const hentForCvr = <TData = AxiosResponse<VirksomhedsRolleDto>>(cvr: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/virksomhedsrolle/${cvr}`, options);
  };
  /**
   * @summary Opdaterer en enkel virksomhedsrolle.
   */
  const opdater = <TData = AxiosResponse<VirksomhedsRolleDto>>(
    cvr: string,
    opdaterVirksomhedsRolleDto: OpdaterVirksomhedsRolleDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.put(`/virksomhedsrolle/${cvr}`, opdaterVirksomhedsRolleDto, options);
  };
  /**
   * @summary Sletter en specifik virksomhedsrolle
   */
  const slet = <TData = AxiosResponse<void>>(cvr: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/virksomhedsrolle/${cvr}`, options);
  };
  /**
   * @summary Henter alle VirksomhedsRoller.
   */
  const hentAlle = <TData = AxiosResponse<VirksomhedsRolleDto[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/virksomhedsrolle`, options);
  };
  /**
   * @summary Opretter en enkel virksomhedsrolle.
   */
  const opret = <TData = AxiosResponse<VirksomhedsRolleDto>>(
    opretVirksomhedsRolleDto: OpretVirksomhedsRolleDto,
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/virksomhedsrolle`, opretVirksomhedsRolleDto, options);
  };
  return { hentForCvr, opdater, slet, hentAlle, opret };
};
export type HentForCvrResult = AxiosResponse<VirksomhedsRolleDto>;
export type OpdaterResult = AxiosResponse<VirksomhedsRolleDto>;
export type SletResult = AxiosResponse<void>;
export type HentAlleResult = AxiosResponse<VirksomhedsRolleDto[]>;
export type OpretResult = AxiosResponse<VirksomhedsRolleDto>;
