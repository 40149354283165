<template>
  <div class="language-switcher">
    <div class="container">
      <ul aria-label="Vælg sprog fra listen">
        <li v-for="(option, i) of options" :key="option">
          <a
            :class="{ active: selected === option }"
            @click="event => selectOption(event, option, i)"
            :aria-selected="selected === option"
            :aria-label="$t('sprogvaelger-aria-label') + ' ' + option">
            <svg v-if="selected === option" class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#done"></use></svg>{{ option }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ComputedRef, PropType, ref, watch } from 'vue';

import { sprogService } from '@/main/services/sprog.service';
import { useSprogStore } from '@/main/stores/sprog.state';

import { SprogLocale } from '../enums/sprog.enum';
import { Bruger } from '../models/bruger.model';
import { brugerprofilService } from '../services/brugerprofil.service';
import { computedBruger } from '../stores/auth.state.bruger';

const props = defineProps({
  options: {
    type: Array as PropType<string[]>,
    required: true
  },
  default: {
    type: String,
    required: false,
    default: null
  },
  id: {
    type: String,
    required: false,
    default: 'sprogvaelger'
  }
});

const sprogStore = useSprogStore(window.pinia);
const selected = ref(sprogService.konverterLocaleTilSprog(sprogStore.hentSprog));
const bruger: ComputedRef<Bruger> = computedBruger();

sprogStore.$subscribe((mutation, state) => {
  try {
    const selectedValue = sprogService.konverterLocaleTilSprog(state.sprog);
    selected.value = selectedValue;
  } catch (error) {
    console.error('Fejl i konverteringen af sprog', error);
  }
});

watch(
  () => selected,
  async () => {
    await sprogService.initSprogservice();
  }
);

function selectOption(event: MouseEvent | null, option: string, index: number) {
  selected.value = option;
  sprogStore.updateSprog(option === 'Dansk' ? SprogLocale.Dansk : SprogLocale.English);
  if (bruger.value.loggetInd) {
    brugerprofilService.redigerBrugerprofil();
  }
}
</script>
<style lang="scss" scoped>
@import '../styles/sprogvaelger';
</style>
