import { AxiosError } from 'axios';

import { VirksomhedsRolleDto } from '@models';

import { getVirksomhedsRolleController } from '@/main/services/generated/virksomheds-rolle-controller/virksomheds-rolle-controller';

import { Komponent } from '../enums/komponent.enum';
import AuthService from './s9-oidc/auth.service';

export class VirksomhedsRolleService {
  public async hentForCvr(cvr: string): Promise<VirksomhedsRolleDto | null> {
    try {
      const controller = getVirksomhedsRolleController();
      const config = AuthService.getConfig(Komponent.Konfiguration, false);
      const axiosResponse = await controller.hentForCvr(cvr, config);
      console.log('Axios response:', axiosResponse);
      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Axios error: ${error.response.status} - ${error.response.data}`);
        return null;
      } else {
        console.error('Unexpected error fetching search results:', error);
        throw error;
      }
    }
  }
}

export const virksomhedsRolleService = new VirksomhedsRolleService();
