import _ from 'lodash';
import { isNumber } from 'lodash';
import { Log, UserManagerSettings } from 'oidc-client-ts';

/* c8 ignore start */

export interface AuthConfigOptions {
  clientId?: string;
  sector9AuthUrl?: string;
  redirectUri?: string;
  postLogoutRedirectUri?: string;
  clockSkewInSeconds?: number;
  response_type?: string;
  scope?: string;
  claims?: unknown;
}

export const AUTOLOGOUT_LOCALSTORAGE_KEY = 'auto-logud';

/**
 * Simplificere UserManagerSettings konfigurering
 * Laver selv claims om til string
 */
export default class AuthConfigService {
  userManagerSettings: UserManagerSettings;

  constructor(options: AuthConfigOptions) {
    this.userManagerSettings = {
      authority: options.sector9AuthUrl ?? 'exception',
      client_id: options.clientId ?? 'exception',
      redirect_uri: options.redirectUri ?? 'exception',
      response_type: options.response_type ?? 'code',
      scope: options.scope ?? 'openid',
      extraQueryParams: {
        claims: this.parseClaims(options.claims)
      },
      post_logout_redirect_uri: options.postLogoutRedirectUri,
      loadUserInfo: true,
      automaticSilentRenew: false,
      accessTokenExpiringNotificationTimeInSeconds: getNotificationTimeSeconds()
    };
  }

  parseClaims(claimsObject: unknown): string {
    try {
      return JSON.stringify(claimsObject);
    } catch (error) {
      console.error('Could not parse claims', error);
    }
    return '';
  }
}

export function getNotificationTimeSeconds(): number {
  const notificationTimeNumber = laesLocalstorageVaerdiSomNumber('notification_time_override');

  // Default: 10 minutter FØR udløb
  return notificationTimeNumber ?? 60 * 10;
}

export function getAutologoutTimeSeconds(): number {
  const autologoutTimeNumber = laesLocalstorageVaerdiSomNumber('autologout_time_override');

  // Default: 5 minutter FØR udløb
  return autologoutTimeNumber ?? 60 * 5;
}

function laesLocalstorageVaerdiSomNumber(key: string): number | undefined {
  const localStorageNotification = localStorage.getItem(key);
  if (localStorageNotification != null && isNumber(+localStorageNotification)) {
    const notificationTimeNumber = +localStorageNotification;
    console.log(`'${key}' er sat til: ${notificationTimeNumber}`);
    return notificationTimeNumber;
  }
  return undefined;
}
