<!-- Fra CVR på virk -->
<template>
  <div class="multiselect-container form-group" :class="{ 'form-error': visFejl }">
    <label class="form-label">{{ uniqueKey ? $t(textPrefix + '.' + uniqueKey + '.label') : $t(textPrefix + '.label') }}</label>
    <span v-if="visFejl" class="form-error-message"
      >{{ $t((customErrormessages ? textPrefix : 'input') + '.' + errors[0]!) }}
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span>
    </span>
    <Multiselect
      :id="feltId"
      v-model="selected"
      ref="multiselect"
      mode="multiple"
      :close-on-select="false"
      :hide-selected="false"
      :options="options"
      :placeholder="$t(textPrefix + placeholder)"
      :required="rules.includes('required')"
      @change="handleOnChange"
      :disabled="disabled">
      <template #beforelist v-if="visAlleOption">
        <div class="p-3" @click="toggleAll" @keydown.enter.stop="toggleAll">
          <input type="checkbox" class="form-checkbox" :checked="allSelected" :aria-label="$t(textPrefix + '.alle-option-label')" />
          <label>{{ $t(textPrefix + '.alle-option-label') }}</label>
        </div>
        <hr />
      </template>
      <template #option="{ option }: { option: MultiselectModel }">
        <div @keydown.enter.stop="toggleOption(option)">
          <input :aria-label="option.label" :checked="selected.find(val => val === option.value) != null" class="form-checkbox" type="checkbox" />
          <label>{{ $t(textPrefix + '.' + option.label) }}</label>
        </div>
      </template>
      <template #multiplelabel="{ values }: { values: MultiselectModel[] }">
        <div class="multiselect-multiple-label">{{ values.map(x => $t(textPrefix + '.' + x.label)).join(', ') }}</div>
      </template>
    </Multiselect>
  </div>
</template>
<script lang="ts" setup>
import Multiselect from '@vueform/multiselect';
import { useField } from 'vee-validate';
import { PropType, computed, onMounted, ref, watch } from 'vue';

export interface MultiselectModel {
  value: string;
  label: string;
}

const props = defineProps({
  name: {
    type: String,
    required: true
  },
  rules: {
    type: String,
    required: true
  },
  customErrormessages: {
    type: Boolean,
    default: false
  },
  textPrefix: {
    type: String,
    required: true
  },
  uniqueKey: {
    type: String,
    default: undefined
  },
  options: {
    type: Array as PropType<MultiselectModel[]>,
    default: () => []
  },
  preselected: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  placeholder: {
    type: String,
    default: '.dropdown-default-placeholder'
  },
  visAlleOption: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  emitKey: {
    type: Boolean,
    default: false
  }
});

const name = ref(props.name);
const {
  errors,
  meta,
  value: inputValue,
  setValue,
  setErrors,
  setTouched,
  validate
} = useField(name, props.rules, {
  validateOnValueUpdate: false
});
const visFejl = computed(() => meta.touched && errors.value.length > 0);

const selected = ref<string[]>(props.preselected);
const allSelected = ref(false);
const feltId: string = props.uniqueKey ?? props.textPrefix;

onMounted(async () => {
  if (props.preselected) {
    setSelected(props.preselected);
  }

  watch(
    () => props.preselected,
    (newValue, prevValue) => {
      setSelected(newValue);
    }
  );

  if (selected.value.length === props.options?.length) {
    allSelected.value = true;
  }
});

function setSelected(value: string[], validate = false) {
  setValue(value, validate);
  selected.value = value;
}

watch(
  () => props.disabled,
  () => {
    selected.value = [];
  }
);

const handleOnChange = (selectedOpts: any): void => {
  setSelected(selectedOpts, true);
  setTouched(true);
  allSelected.value = selectedOpts.length === props.options?.length;
};

function toggleAll(): void {
  allSelected.value = !allSelected.value;
  if (allSelected.value) {
    setSelected(
      props.options?.map((option: any) => option.value),
      true
    );
  } else {
    setSelected([], true);
  }
  handleOnChange(selected.value);
}

function toggleOption(option: MultiselectModel): void {
  const index = selected.value.indexOf(option.value);
  if (index === -1) {
    selected.value.push(option.value);
  } else {
    selected.value.splice(index, 1);
  }
  setSelected(selected.value, true);
}

defineExpose({
  inputValue,
  validate,
  setErrors,
  setValue
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss">
@import '../../styles/multiselectDropdown';
</style>
