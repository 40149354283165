<template>
  <div class="modal-body" data-cy="opret-annonce-modal">
    <ContentfulRigTekst :document="$tm('opret-annonce-modal-broedtekst')" />
  </div>
  <div class="modal-footer">
    <button type="button" class="button button-primary">
      <RouterLink class="unstyle-router" :to="Path.OPRET_NYT" @click="store.closeModal()">
        {{ $t('opret-annonce-modal-primary-knap') }}
      </RouterLink>
    </button>
    <button type="button" @click="store.closeModal()" class="button button-secondary">
      {{ $t('opret-annonce-modal-secondary-knap') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { Path } from '@/main/enums/path.enum';
import { useModalStore } from '@/main/stores/modal.state';

const store = useModalStore(window.pinia);
</script>

<style scoped lang="scss">
@import '/src/main/styles/_modal';
</style>
