import { DirectiveBinding } from 'vue';

export default {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    el.clickOutsideEvent = function (event: any) {
      const targetElement = event.target;
      if ([...targetElement.classList].some(className => className.startsWith('date-picker__calendar'))) {
        return; // Clicked inside the datepicker, so don't trigger the outside click event
      }
      if (el !== event.target && !el.contains(event.target)) {
        binding.value(event);
      }
    };
    if (binding.value) {
      document.addEventListener('click', el.clickOutsideEvent);
    }
  },
  unmounted(el: HTMLElement) {
    document.removeEventListener('click', el.clickOutsideEvent);
  }
};
