<template>
  <ul class="accordion" :id="contentfulKey">
    <li>
      <h2>
        <button class="accordion-button" aria-expanded="false" :aria-controls="'content-' + contentfulKey" type="button">
          {{ title }}
        </button>
      </h2>
      <div :id="'content-' + contentfulKey" aria-hidden="true" class="accordion-content">
        <RichTextRenderer :document="accordionBody" />
      </div>
    </li>
  </ul>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import * as DKFDS from 'dkfds';

export default {
  components: {
    RichTextRenderer
  },
  props: ['contentfulKey', 'title', 'accordionBody'],
  mounted() {
    new DKFDS.Accordion(document.getElementById(this.contentfulKey)).init();
  }
};
</script>
