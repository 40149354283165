<template>
  <div class="modal-body" data-cy="driftmeddelelse-oprettelse-modal">
    <div>
      <FormGroup
        ref="form"
        @handleSubmit="gemDriftsmeddelelse"
        :fejl-prefix="{ synligPaaSiderFelt: 'internal.driftsmeddelelese-oprettelse.synligpaasider' }">
        <MultiselectDropdown
          name="synligPaaSiderFelt"
          ref="synligPaaSiderFelt"
          rules="required"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.synligpaasider'"
          :custom-errormessages="true"
          :preselected="synligPaaSiderValgmulighederPreselected"
          :options="synligPaaSiderValgmuligheder"
          :vis-alle-option="true"
          :emit-key="true" />

        <InputFelt
          name="overskriftDanskFelt"
          ref="overskriftDanskFelt"
          rules="required|max:255"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.overskriftDansk.input'"
          :preselected-value="element.overskriftDansk"
          :readonly="false"
          :maxlength="255"
          :is-textarea="false"
          :aria-label="'internal.driftsmeddelelese-oprettelse.overskriftDansk.aria'"
          data-cy="driftmeddelelse-overskriftDansk-felt"
          :show-hint="false" />

        <InputFelt
          name="overskriftEngeleskFelt"
          ref="overskriftEngeleskFelt"
          rules="required|max:255"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.overskriftEngelsk.input'"
          :preselected-value="element.overskriftEngelsk"
          :readonly="false"
          :maxlength="255"
          :is-textarea="false"
          :aria-label="'internal.driftsmeddelelese-oprettelse.overskriftEngelsk.aria'"
          data-cy="driftmeddelelse-overskriftEngelsk-felt"
          :show-hint="false" />

        <RichTekstInput
          name="broedtekstDanskFelt"
          ref="broedtekstDanskFelt"
          :include-links="true"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.broedtekstDansk'"
          :preselected-value="element.broedtekstDansk" />

        <RichTekstInput
          name="broedtekstEngeleskFelt"
          ref="broedtekstEngeleskFelt"
          :include-links="true"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.broedtekstEngelsk'"
          :preselected-value="element.broedtekstEngelsk" />

        <Dropdown
          name="beskedTypeFelt"
          ref="beskedTypeFelt"
          class="form-label"
          :options="beskedTyper"
          :preselected-value="beskedTyper.find(x => x.key == element.beskedtype)"
          :text-prefix="'internal.driftsmeddelelese-oprettelse.beskedtyper.dropdown'" />

        <div class="row ml-1 mt-5">
          <InputDatovaelger
            :show-hint="true"
            name="starttidspunktFelt"
            ref="starttidspunktFelt"
            :bind-format="dateUtil.standardDatoFormat"
            :rules="element.id != null ? '' : 'required|validDate|dateTodayOrAfter'"
            text-prefix="internal.driftsmeddelelese-oprettelse.overskriftDatoStart.input"
            :preselected-value="dateUtil.getDateFromIso(element.startTidspunkt)" />
          <InputFeltTid
            name="tidsFeltStart"
            ref="tidsFeltStart"
            :show-hint="true"
            class="mt-0"
            :text-prefix="'internal.driftsmeddelelese-oprettelse.overskriftTidStart.input'"
            :preselected-value="dateUtil.getTimeFromIso(element.startTidspunkt)"
            :rules="element.id != null ? '' : 'required|max:5|numberTime|valid24hours|dateTimeAfterNow:@starttidspunktFelt'" />
        </div>
        <div class="row ml-1 mt-5">
          <InputDatovaelger
            :show-hint="true"
            name="sluttidspunktFelt"
            ref="sluttidspunktFelt"
            :bind-format="dateUtil.standardDatoFormat"
            :rules="'required|validDate|dateTodayOrAfter|filterdateAfter:@starttidspunktFelt'"
            text-prefix="internal.driftsmeddelelese-oprettelse.overskriftDatoSlut.input"
            :preselected-value="dateUtil.getDateFromIso(element.slutTidspunkt)" />
          <InputFeltTid
            name="tidsFeltSlut"
            ref="tidsFeltSlut"
            :show-hint="true"
            class="mt-0"
            :text-prefix="'internal.driftsmeddelelese-oprettelse.overskriftTidSlut.input'"
            :preselected-value="dateUtil.getTimeFromIso(element.slutTidspunkt)"
            :rules="'required|max:5|numberTime|valid24hours|dateTimeAfter:@tidsFeltStart,@starttidspunktFelt,@sluttidspunktFelt'" />
        </div>
      </FormGroup>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" @mousedown="form.submit()" @keydown.enter="form.submit()" class="button button-primary" data-cy="driftmeddelelse-gem">
      {{ element.id != null ? $t('internal.driftsmeddelelese-oprettelse.rediger') : $t('internal.driftsmeddelelese-oprettelse.opret') }}
    </button>
    <button type="button" @click="onAnnuller()" class="button button-secondary logout-btn" data-cy="driftmeddelelse-annuller">
      {{ $t('internal.driftsmeddelelese-oprettelse.annuller') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { GenericObject } from 'vee-validate';
import { PropType, ref } from 'vue';

import Dropdown from '@/main/components/input/Dropdown.vue';
import FormGroup from '@/main/components/input/FormGroup.vue';
import InputDatovaelger from '@/main/components/input/InputDatovaelger.vue';
import InputFelt from '@/main/components/input/InputFelt.vue';
import InputFeltTid from '@/main/components/input/InputFeltTid.vue';
import MultiselectDropdown, { MultiselectModel } from '@/main/components/input/MultiselectDropdown.vue';
import RichTekstInput from '@/main/components/input/RichTekstInput.vue';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { DriftsmeddelelseDto, DriftsmeddelelseDtoBeskedtype, DriftsmeddelelseDtoSynligPaaSiderItem } from '@/main/models/generated';
import { driftsmeddelelseService } from '@/main/services/driftsmeddelelse.service';
import { useModalStore } from '@/main/stores/modal.state';
import { dateUtil } from '@/main/utils/date-util';

const form = ref<typeof FormGroup>();

const modalStore = useModalStore(window.pinia);

const props = defineProps({
  element: {
    type: Object as PropType<DriftsmeddelelseDto>,
    required: true
  }
});

const tidsFeltStart = ref<InstanceType<typeof InputFeltTid>>();
const tidsFeltSlut = ref<InstanceType<typeof InputFeltTid>>();
const overskriftDanskFelt = ref<InstanceType<typeof InputFelt>>();
const overskriftEngeleskFelt = ref<InstanceType<typeof InputFelt>>();
const broedtekstDanskFelt = ref<InstanceType<typeof RichTekstInput>>();
const broedtekstEngeleskFelt = ref<InstanceType<typeof RichTekstInput>>();
const synligPaaSiderFelt = ref<InstanceType<typeof MultiselectDropdown>>();
const beskedTypeFelt = ref<InstanceType<typeof Dropdown>>();
const starttidspunktFelt = ref<InstanceType<typeof InputDatovaelger>>();
const sluttidspunktFelt = ref<InstanceType<typeof InputDatovaelger>>();

const beskedTyper: DropdownOption[] = [
  {
    key: DriftsmeddelelseDtoBeskedtype.DRIFTS_BESKED,
    value: DriftsmeddelelseDtoBeskedtype.DRIFTS_BESKED,
    label: 'internal.driftsmeddelelese-oprettelse.beskedtype.DRIFTS_BESKED'
  },
  {
    key: DriftsmeddelelseDtoBeskedtype.INFORMATIV_BESKED,
    value: DriftsmeddelelseDtoBeskedtype.INFORMATIV_BESKED,
    label: 'internal.driftsmeddelelese-oprettelse.beskedtype.INFORMATIV_BESKED'
  }
];

const synligPaaSiderValgmuligheder: MultiselectModel[] = [
  {
    label: DriftsmeddelelseDtoSynligPaaSiderItem.FORSIDE,
    value: DriftsmeddelelseDtoSynligPaaSiderItem.FORSIDE
  },
  {
    label: DriftsmeddelelseDtoSynligPaaSiderItem.OM_MITUDBUD_SIDE,
    value: DriftsmeddelelseDtoSynligPaaSiderItem.OM_MITUDBUD_SIDE
  }
];

const synligPaaSiderValgmulighederPreselected = synligPaaSiderValgmuligheder
  .filter(x => props.element.synligPaaSider.some(s => s == x.value))
  .map(x => x.value);

function onAnnuller() {
  const store = useModalStore(window.pinia);
  store.closeModal();
}

function erAktuel(el: DriftsmeddelelseDto) {
  const start = DateTime.fromISO(el.startTidspunkt);
  const slut = DateTime.fromISO(el.slutTidspunkt);
  return start.diffNow().toMillis() < 0 && slut.diffNow().toMillis() > 0;
}

async function gemDriftsmeddelelse(data: GenericObject) {
  const el: DriftsmeddelelseDto = {} as DriftsmeddelelseDto;
  el.overskriftDansk = data.values.overskriftDanskFelt;
  el.overskriftEngelsk = data.values.overskriftEngeleskFelt;
  el.broedtekstDansk = broedtekstDanskFelt.value?.getHtml();
  el.broedtekstEngelsk = broedtekstEngeleskFelt.value?.getHtml();
  el.startTidspunkt = dateUtil.fromDatotidsvaelgerFormatToIsoFormat(
    dateUtil.validFilterDTOFormat(starttidspunktFelt.value!.fieldValue) + ' ' + tidsFeltStart.value!.fieldValue
  );
  el.slutTidspunkt = dateUtil.fromDatotidsvaelgerFormatToIsoFormat(
    dateUtil.validFilterDTOFormat(sluttidspunktFelt.value!.fieldValue) + ' ' + tidsFeltSlut.value!.fieldValue
  );
  el.beskedtype = data.values.beskedTypeFelt as DriftsmeddelelseDtoBeskedtype;
  el.synligPaaSider = data.values.synligPaaSiderFelt as DriftsmeddelelseDtoSynligPaaSiderItem[];
  el.id = props.element.id;
  let result = null;
  if (el.id == null) {
    // Opret ny:
    result = await driftsmeddelelseService.opret(el);
  } else if (erAktuel(el)) {
    // Rediger
    // Nu er imellem før og
    modalStore.openDriftsmeddelelseRedigeringBekraeftelse(el);
    return;
  } else {
    result = await driftsmeddelelseService.rediger(el.id, el);
  }
  if (result === false) {
    form.value.validate();
  } else {
    modalStore.closeModal();
  }
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/components/_driftmeddelelse';
</style>
