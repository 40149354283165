/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Sprogvalget for brugeren som oprettes.
 */
export type BrugerprofilDtoSprogValg = (typeof BrugerprofilDtoSprogValg)[keyof typeof BrugerprofilDtoSprogValg];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BrugerprofilDtoSprogValg = {
  DA: 'DA',
  EN: 'EN'
} as const;
