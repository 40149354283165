<template>
  <div class="modal-body" data-cy="brugerprofil-oprettelse-modal">
    <FormGroup ref="form" @handleSubmit="gemBrugerprofil">
      <div class="pb-4">
        <RichTextRenderer v-if="$tm('brugerprofil.rig-tekst')" :document="$tm('brugerprofil.rig-tekst')" />
        <InputFelt
          name="navn"
          rules="required"
          :preselected-value="bruger.navn"
          :text-prefix="'brugerprofil.navn.input'"
          :readonly="true"
          data-cy="brugerprofil-navn-felt" />
        <InputFelt
          name="email"
          rules="required"
          :preselected-value="bruger.email"
          :text-prefix="'brugerprofil.email.input'"
          type="email"
          data-cy="brugerprofil-email-felt" />
        <template v-if="organisationsId">
          <InputFelt
            name="organisationsprofil.id"
            :text-prefix="'organisationsprofil.id.input'"
            :preselected-value="organisationsId"
            rules="required"
            :readonly="true"
            :aria-label="'organisationsprofil.id.aria'"
            :show-function-link="false"
            data-cy="organisationsprofil-id-felt" />
          <template v-if="organisationsnavn">
            <InputFelt
              name="organisationsprofil.navn"
              :text-prefix="'organisationsprofil.navn.input'"
              :preselected-value="organisationsnavn"
              rules="required"
              :readonly="true"
              :aria-label="'organisationsprofil.navn.aria'"
              :show-function-link="false"
              data-cy="organisationsprofil-navn-felt"
          /></template>
        </template>
      </div>
    </FormGroup>
  </div>
  <div class="modal-footer">
    <button type="button" @mousedown="form.submit()" @keydown.enter="form.submit()" class="button button-primary" data-cy="brugerprofil-gem">
      {{ $t('brugerprofil.opret') }}
    </button>
    <button type="button" @click="onLogout()" class="button button-secondary logout-btn" data-cy="brugerprofil-logout">
      {{ $t('brugerprofil.annuller-og-log-ud') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import RichTextRenderer from 'contentful-rich-text-vue-renderer';
import { GenericObject } from 'vee-validate';
import { ComputedRef, ref } from 'vue';

import FormGroup from '@/main/components/input/FormGroup.vue';
import InputFelt from '@/main/components/input/InputFelt.vue';
import { Komponent } from '@/main/enums/komponent.enum';
import { Bruger } from '@/main/models/bruger.model';
import { OrganisationsprofilDto } from '@/main/models/generated';
import { brugerprofilService } from '@/main/services/brugerprofil.service';
import { getOrganisationsprofilController } from '@/main/services/generated/organisationsprofil-controller/organisationsprofil-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { authState } from '@/main/stores/auth.state';
import { computedBruger } from '@/main/stores/auth.state.bruger';
import { useModalStore } from '@/main/stores/modal.state';

const form = ref(typeof FormGroup);

const modalStore = useModalStore(window.pinia);
const bruger: ComputedRef<Bruger> = computedBruger();

async function onLogout() {
  await authState?.state?.logout().catch(err => {
    console.error(err);
  });
  modalStore.closeModal();
}

async function gemBrugerprofil(data: GenericObject) {
  brugerprofilService.opretBrugerprofil(data.values.email).then(result => {
    if (result.status === 200) {
      modalStore.openBrugerprofilOprettetModal();
    }
  });
}

const startNavn = computedBruger().value.navn;

const config = AuthService.getConfig(Komponent.Bruger, false);
const organisationsId = computedBruger().value.cvrNummer;

const result = await getOrganisationsprofilController()
  .hentPaaLoggetOrganisationsprofil({}, config)
  .catch(() => void 0);
let organisationsnavn: string | undefined;
if (result?.status == 200) {
  const organisation: OrganisationsprofilDto = result.data;
  organisationsnavn = organisation.organisationsnavn;
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
