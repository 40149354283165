<template>
  <div class="form-group">
    <fieldset role="radiogroup">
      <legend v-if="legend" class="form-label">{{ legend }}</legend>
      <div class="form-group-radio" v-for="(option, index) in options" :key="option.id" data-cy="form-group-radio">
        <input
          type="radio"
          :id="`${name}-${index}`"
          :name="name"
          class="form-radio"
          :value="option.value"
          v-model="selectedOption"
          :disabled="disabled" />
        <label class="form-label" :for="`${name}-${index}`">{{ option.label }}</label>
      </div>
    </fieldset>
  </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from 'vue';

import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';

type RadioButtonValue = string | number | boolean | Record<string, any>;

const props = defineProps({
  legend: {
    type: String,
    required: false,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  options: {
    type: Array as () => RadioOgCheckboxModel[],
    required: true
  },
  preselectedValue: {
    type: [String, Number, Boolean, Object] as PropType<RadioButtonValue>,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const selectedOption = ref(props.preselectedValue);

const emit = defineEmits<(e: 'radioButtonUpdate', value: RadioButtonValue) => void>();

watch(selectedOption, newValue => {
  emit(StandardEmitEvent.RADIO_BUTTON_UPDATE, newValue);
});

watch(
  () => props.preselectedValue,
  newValue => {
    if (newValue !== selectedOption.value) {
      selectedOption.value = newValue;
    }
  },
  { immediate: true }
);
</script>
