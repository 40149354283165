<template>
  <div class="form-group" data-cy="base-checkbox-group">
    <fieldset>
      <legend v-if="legend" class="form-label">{{ legend }}</legend>
      <div class="form-group-checkbox" v-for="(option, index) in options" :key="index" data-cy="form-group-checkbox">
        <input :type="inputType" :id="`${name}-${index}`" :name="name" :class="inputClass" :value="option.value" v-model="selectedOptions" />
        <label class="form-label" :for="`${name}-${index}`">{{
            option.label !== undefined && i18n.global.te(option.label) ? $t(option.label) : option.label
          }}</label>
      </div>
    </fieldset>
  </div>
</template>
<script lang="ts" setup>
import { PropType, onMounted, ref, watch } from 'vue';

import { RadioOgCheckboxModel } from '@/main/models/base/radioogcheckbox.model';
import i18n from '@/main/services/i18n';

const props = defineProps({
  legend: {
    type: String,
    required: false,
    default: undefined
  },
  name: {
    type: String,
    required: true
  },
  options: {
    type: Array as () => RadioOgCheckboxModel[],
    required: true
  },
  inputType: {
    type: String,
    default: 'checkbox'
  },
  inputClass: {
    type: String,
    default: 'form-checkbox'
  },
  preselectedValues: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  id: {
    type: String,
    default: 'checkbox-id'
  }
});

const selectedOptions = ref<string[]>([]);

const emit = defineEmits<{
  filterCheckboxUpdate: [selectedValues: string[]];
}>();

// Vi bruger kun watch i stedet for onMounted fordi at order på mount ikke er garanteret og mount beslut kan laves ovenfor i ordning
watch(
  () => props.preselectedValues,
  newValue => {
    selectedOptions.value = newValue;
  }
);

onMounted(() => {
  selectedOptions.value = props.preselectedValues;

  watch(selectedOptions, () => {
    emit('filterCheckboxUpdate', selectedOptions.value);
  });
});
</script>
