import { DateTime } from 'luxon';

import { InputFejl } from '../enums/inputFejl.enum';
import { dateUtil } from './date-util';
import InputFormatUtil from './input-format-util';

class Validation {
  public static integer(value: string) {
    if (!value?.length) {
      return true;
    }

    const integer = '^-?(([0-9]+)|([0-9]{1,3}(\\.[0-9]{3})+)|([0-9]{1,3}(,[0-9]{3})+)|([0-9]{1,3}( [0-9]{3})+)|$)$';
    const regex = new RegExp(integer);
    if (!regex.test(value)) {
      return InputFejl.IKKE_GYLDIG_INTEGER;
    }
    return true;
  }

  public static currency(value: string) {
    if (!value?.length) {
      return true;
    }
    const r =
      '^-?(([0-9]+([,\\.][0-9]{2})?)|([0-9]{1,3}(\\.[0-9]{3})+(,[0-9]{2})?)|([0-9]{1,3}(,[0-9]{3})+(\\.[0-9]{2})?)|([0-9]{1,3}( [0-9]{3})+([,\\.][0-9]{2})?)|$)$';
    const regex = new RegExp(r);
    if (!regex.test(value)) {
      return InputFejl.IKKE_GYLDIG_VALUTA;
    }
    return true;
  }

  public static number(value: string) {
    if (!value?.length) {
      return true;
    }

    const number = '^([+-]?\\d*([.,]\\d+)?)?$';
    const regex = new RegExp(number);
    if (!regex.test(value)) {
      return InputFejl.IKKE_GYLDIGT_NUMBER;
    }
    return true;
  }

  public static numberTime(value: string) {
    if (!value?.length) {
      return true;
    }

    const number = '^[0-9.:]+$';

    const regex = new RegExp(number);
    if (!regex.test(value)) {
      return InputFejl.IKKE_GYLDIGT_NUMBER;
    }
    return true;
  }

  public static required(value: string) {
    if (!value?.length) {
      return InputFejl.PAAKRAEVET_FELT;
    }
    return true;
  }

  public static email(value: string) {
    if (!value?.length) {
      return true;
    }

    const email_regex =
      '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
    const regex = new RegExp(email_regex);
    if (!regex.test(value)) {
      return InputFejl.IKKE_GYLDIG_EMAIL;
    }
    return true;
  }

  public static phone(value: string) {
    if (!value?.length) {
      return true;
    }

    const normaliseret = InputFormatUtil.formaterTelefon(value);

    let match = false;
    if (normaliseret.startsWith('+')) {
      const e164 = '^\\+\\d{7,15}$';
      const regex = new RegExp(e164, 'g');
      match = regex.test(normaliseret);
    } else {
      const dansk = '^\\d{8,8}$';
      const regex = new RegExp(dansk, 'g');
      match = regex.test(normaliseret);
    }
    if (!match) {
      return InputFejl.IKKE_GYLDIG_TELEFON;
    }
    return true;
  }

  /**
   * Max number of characters entered
   * @param value entered value
   * @param max highest number of allowed characters
   * @returns true on value and error enum on invalid
   */
  public static max(value: string, max: number) {
    if (!value?.length) {
      return true;
    }

    if (value.length > max) {
      return InputFejl.OVER_ANTAL_TEGN;
    }
    return true;
  }

  public static validDate(value: string) {
    const format = dateUtil.verifyIfDateIsValid(value);
    if (format === InputFejl.IKKE_GYLDIG_DATO) {
      return InputFejl.IKKE_GYLDIG_DATO;
    }
    return true;
  }

  /**
   * Chosen date should be after another chosen date
   * @param value String in format 'dd-mm-yyyy'
   * @param target String in format 'dd-mm-yyyy'
   * @returns true on valid and error enum on invalid
   */
  public static filterdateBefore(value: string, target: string) {
    const dateFormat = 'dd-MM-yyyy';
    const formattedValue = value;
    const formattedTarget = target;
    if (formattedValue !== undefined && formattedTarget !== undefined) {
      const formattedValueFromFormat = DateTime.fromFormat(formattedValue, dateFormat);
      const formattedTargetFromFormat = DateTime.fromFormat(formattedTarget, dateFormat);
      if (formattedValueFromFormat.diff(formattedTargetFromFormat).toMillis() > 0) {
        return InputFejl.DATO_FOER_START;
      }
    }
    return true;
  }

  /**
   * Chosen date should be after another chosen date
   * @param value String in format 'dd-mm-yyyy'
   * @param target String in format 'dd-mm-yyyy'
   * @returns true on valid and error enum on invalid
   */
  public static filterdateAfter(value: string, target: string) {
    const dateFormat = 'dd-MM-yyyy';
    const formattedValue = value;
    const formattedTarget = target;
    if (formattedValue !== undefined && formattedTarget !== undefined) {
      const formattedValueFromFormat = DateTime.fromFormat(formattedValue, dateFormat);
      const formattedTargetFromFormat = DateTime.fromFormat(formattedTarget, dateFormat);
      if (formattedValueFromFormat.diff(formattedTargetFromFormat).toMillis() < 0) {
        return InputFejl.DATO_FOER_START;
      }
    }

    return true;
  }

  /**
   * Validate that the selected date is today or after
   * @param value String in format 'dd-mm-yyyy'
   * @returns true on value - error enum on invalid
   */
  public static dateTodayOrAfter(value: string) {
    const dateFormat = 'dd-MM-yyyy';
    const formattedValue = dateUtil.getValidDateformatFromString(value);
    const formattedValueFromFormat = DateTime.fromFormat(formattedValue, dateFormat);

    if (formattedValueFromFormat.diffNow().toMillis() < -86400000) {
      return InputFejl.DATO_FOER_NU;
    }

    return true;
  }

  public static valid24hours(value: string) {
    if (!value?.length) {
      return true;
    }
    const regex24HourFormat = /^(0[0-9]|1[0-9]|2[0-3]):?[0-5][0-9]$/;
    const regex = new RegExp(regex24HourFormat);
    if (!regex.test(value)) {
      return InputFejl.TID_FORKERT;
    }
    return true;
  }

  public static pattern(value: string, pattern: string) {
    if (!value || (typeof value === 'string' && !value.length)) {
      return true;
    }
    const regex = new RegExp(pattern);
    if (typeof value === 'string') {
      if (!regex.test(value)) {
        return InputFejl.IKKE_GYLDIG_FELT;
      }
    }

    return true;
  }

  public static dateTimeAfterNow(value: string, target: string) {
    const dateTimeFormat = 'dd-MM-yyyy HH:mm';
    if (!target || target.length === 0) {
      return true;
    }
    const dateTime = target + ' ' + value;
    const formattedValueFromFormat = DateTime.fromFormat(dateTime, dateTimeFormat);
    if (formattedValueFromFormat.diffNow().toMillis() < -60000) {
      return InputFejl.TID_FOER_NU;
    }

    return true;
  }

    public static dateTimeAfter(value: string, args: string[]) {
      const dateTimeFormat = 'dd-MM-yyyy HH:mm';
      const startTime = args[0];
      const startDate = args[1];
      const endDate = args[2];
      if (!startDate || startDate.length === 0 || !endDate || endDate.length === 0) {
        return true;
      }
      const startDateTime = DateTime.fromFormat(startDate + ' ' + startTime, dateTimeFormat);
      const endDateTime = DateTime.fromFormat(endDate + ' ' + value, dateTimeFormat);
      if (endDateTime.diff(startDateTime).toMillis() < 0) {
        return InputFejl.TID_FOER_START;
      }

      return true;
    }
}

export default Validation;
