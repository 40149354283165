import linkifyHtml from 'linkify-html';
import { isString } from 'lodash';
import { Ref, ref } from 'vue';

import { TabelColumn } from '../models/tabel.model';
import { useModalStore } from '../stores/modal.state';

class VisningUtil {
  // Truncate string til at tilpasse sig med en maxlength på mobilvisning
  public truncateString(value: string | undefined, maxLength: number, isMobile: boolean, filter: boolean): string {
    if (isMobile || filter) {
      if (value === undefined) {
        return '';
      }
      if (String(value).length <= maxLength) {
        return String(value);
      }
      return String(value).slice(0, maxLength) + '...';
    }
    return String(value);
  }

  // Toggle for hvornår noget skal åbnes eller lukkes
  public toggleShowMore(isOpen: Ref<boolean>, showFullText: Ref<boolean>): void {
    isOpen.value = !isOpen.value;
    showFullText.value = !showFullText.value;
  }

  //Linkify html
  public linkifyContent(content: any): string {
    return content ? linkifyHtml(content, { attributes: { rel: 'noopener noreferrer', target: '_blank' } }) : '';
  }

  public sortColumn(
    sortedData: any[],
    columns: TabelColumn[],
    columnIndex: number,
    sortDirection: 'ascending' | 'descending' | 'none' | 'other' | undefined
  ) {
    const key = columns[columnIndex].key;
    sortedData?.sort((a: string, b: string) => {
      const rawA = this.getValueAt(a, key) ?? '';
      const rawB = this.getValueAt(b, key) ?? '';
      const valueA = isString(rawA) ? rawA.trim() : rawA;
      const valueB = isString(rawB) ? rawB.trim() : rawB;

      // Regex format "dd-MM-YYYY"
      const dateRegex = /^\d{2}-\d{2}-\d{4}$/;

      // Tjek om værdien er en gyldig dato i "dd-MM-YYYY" format
      const isValidDate = (value: string) => dateRegex.test(value) && this.parseDate(value) !== undefined;

      // Hvis begge værdier er gyldige datoer
      if (isValidDate(valueA) && isValidDate(valueB)) {
        // Konverter til dato
        const [dateA, dateB] = [this.parseDate(valueA)!, this.parseDate(valueB)!];
        return sortDirection === 'ascending' ? dateA.getTime() - dateB.getTime() : dateB.getTime() - dateA.getTime();
      } else {
        // Hvis ikke, konverter til tal
        const [numA, numB] = [Number(valueA), Number(valueB)];
        // Hvis begge værdier er tal, sammenlign dem
        if (!isNaN(numA) && !isNaN(numB)) {
          return sortDirection === 'ascending' ? numA - numB : numB - numA;
        } else {
          // Hvis ingen af værdierne er gyldige datoer eller tal, sammenlign dem som strings
          return this.compareStrings(sortDirection ?? 'none', valueA, valueB);
        }
      }
    });
  }

  public parseDate(dateString: string): Date | undefined {
    const parts = dateString.split('-').map(Number);
    // Opret en ny dato (år, måned, dag)
    if (parts.length === 3 && parts.every(part => !isNaN(part))) {
      const date = new Date(parts[2], parts[1] - 1, parts[0]);
      // Tjek om datoen er gyldig
      if (!isNaN(date.getTime())) {
        return date;
      }
    }
    return undefined; // Returner undefined hvis string ikke er en gyldig dato
  }

  public compareStrings(sortedDirection: 'ascending' | 'descending' | 'none' | 'other' | undefined, a: string, b: string) {
    const direction = sortedDirection === 'descending' ? -1 : 1;
    return direction * a.localeCompare(b, undefined, { sensitivity: 'base' });
  }

  public getValueAt(item: any, prop: string) {
    return item[prop];
  }

  public openLogin(redirectPath?: string) {
    const store = useModalStore(window.pinia);
    store.openLoginModal({ redirectPath });
  }

  public openAnnonceModal() {
    const store = useModalStore(window.pinia);
    store.openOpretAnnonceModal();
  }
}
export const visningUtil = new VisningUtil();
