export enum InputFejl {
  PAAKRAEVET_FELT = 'fejlbesked.paakraevet',
  IKKE_GYLDIG_FELT = 'fejlbesked.ikkegyldig',
  IKKE_GYLDIG_EMAIL = 'email.fejlbesked.ikkegyldig',
  IKKE_GYLDIG_TELEFON = 'telefon.fejlbesked.ikkegyldig',
  IKKE_GYLDIG_DATO = 'dato.fejlbesked.ikkegyldig',
  OVER_ANTAL_TEGN = 'fejlbesked.overantaltegn',
  FOER_MIN_DATO = 'fejlbesked.foermindato',
  EFTER_MAX_DATO = 'fejlbesked.eftermaxdato',
  TIME_FORKERT = 'fejlbesked.timefeltforkert',
  MINUT_FORKERT = 'fejlbesked.minutfeltforkert',
  TID_FORKERT = 'fejlbesked.tidfeltforkert',
  TID_FOER_NU = 'fejlbesked.tidfoernu',
  TID_FOER_START = 'fejlbesked.tidfoerstart',
  DATO_PAAKRAEVET = 'fejlbesked.datofeltpaakraevet',
  TIME_PAAKRAEVET = 'fejlbesked.timefeltpaakraevet',
  MINUT_PAAKRAEVET = 'fejlbesked.minutfeltpaakraevet',
  DATO_FOER_START = 'fejlbesked.foerstart',
  DATO_FOER_NU = 'fejlbesked.foernu',
  IKKE_GYLDIG_INTEGER = 'fejlbesked.ikkegyldiginteger',
  IKKE_GYLDIG_VALUTA = 'fejlbesked.ikkegyldigvaluta',
  IKKE_GYLDIGT_NUMBER = 'fejlbesked.ikkegyldigtnumber'
}
