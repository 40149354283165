import { ComputedRef } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

import { CommonRoute } from '@/main/enums/commonRoute.enum';
import { authState } from '@/main/stores/auth.state';

import { MainRoute } from '../enums/mainRoute.enum';
import { Bruger } from '../models/bruger.model';
import { computedBruger } from '../stores/auth.state.bruger';

/**
 * Har bruger nødvendig rolle adgang til rute
 * ud fra meta.requiredRoles på rute
 */
export function harRolleAdgang(to: RouteLocationNormalized): boolean {
  const routeToMeta = to.meta
    ? to.matched
        .slice()
        .reverse()
        .find(r => r?.meta?.requiredRoles)
    : null;

  if (!routeToMeta?.meta?.requiredRoles) {
    return true;
  }

  const requiredRoles = routeToMeta.meta.requiredRoles as string[];
  try {
    const user = authState.state?.user;

    if (!user) {
      return false;
    }

    const roller = authState.state?.getProfile<string[]>('dk:gov:virk:roles') ?? [];

    return requiredRoles.some(x => roller.includes(x));
  } catch {
    return false;
  }
}

/**
 * Har besøgende adgang til siden uden login
 * ud fra meta.requiredAuth på rute
 */

export function harAdgang(to: RouteLocationNormalized) {
  if (to.meta.requiredAuth === true) {
    const user = authState.state?.user;
    if (!user) {
      return false;
    }
  }

  if (to.meta.requiredOrganisationsProfil === true) {
    const bruger: ComputedRef<Bruger> = computedBruger();

    if (bruger.value.privatPerson) {
      return false;
    }

    const user = authState.state?.user;
    if (!user) {
      return false;
    }

    const isOrganisationAttached = bruger.value.organisationsProfil !== null;

    if (!isOrganisationAttached) {
      return false;
    }

    return true;
  }

  return true;
}

/**
 * Finder META data for nuværende route
 * @param to
 * @returns
 */
export function getRouteMeta(to: RouteLocationNormalized) {
  try {
    const routeMeta = to.meta ? [...to.matched].reverse().find(m => m.meta) : null;
    return routeMeta?.meta;
  } catch (error) {
    return null;
  }
}

export async function routeBeforeEach(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  document.title = `${typeof to.meta?.title === 'string' ? to.meta.title : ''} | udbud.dk`;
  const meta = getRouteMeta(to);

  // Er route public tilgængelig
  if (meta?.isPublic) {
    return next();
  }

  // Brugers roller har ikke adgang til route
  if (!authState.state) {
    await authState.initState();
  }

  if (!harRolleAdgang(to) || !harAdgang(to)) {
    const bruger: ComputedRef<Bruger> = computedBruger();
    if (bruger.value.privatPerson) {
      return next({ name: MainRoute.FORSIDE });
    }

    return next({ name: CommonRoute.UNAUTHORIZED });
  }

  return next();
}
