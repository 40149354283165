import { DateTime, Duration } from 'luxon';

import { InputFejl } from '../enums/inputFejl.enum';

class DateUtil {
  //Zones
  public centraleuropaeiskNormaltid = 'Europe/Copenhagen';

  //DateTime Formats
  public uiDateTimeFormat = 'dd.MM.yy HH:mm';
  public datotidsvaelgerFormat = 'dd/MM/yyyy HH:mm:ss';
  public internalDatotidsvaelgerFormat = 'yyyy-MM-dd HH:mm';
  public arkivUdbudDatotidsFormat = 'dd-MM-yyyy HH:mm:ss.uu';
  public visningsOpsummeringDatotidsFormat = 'dd-MM-yyyy - HH:mm:ss';

  //Date Formats
  public internalDatovaelgerFormat = 'yyyy-MM-dd';
  public datovaelgerFormat = 'dd/MM/yyyy';
  public standardDatoFormat = 'dd-MM-yyyy';
  public dateWithTimezone = 'yyyy-MM-ddZZ';
  public datepickerFormats = [
    'dd-MM-yyyy',
    'dd/MM/yyyy',
    'dd.MM.yyyy',
    'dd,MM,yyyy',
    'dd/MM.yyyy',
    'dd MM yyyy',
    'ddMMyyyy',
    'dd-M-yyyy',
    'dd/M/yyyy',
    'dd.M.yyyy',
    'd-MM-yyyy',
    'd/MM/yyyy',
    'd.MM.yyyy',
    'd-M-yyyy',
    'd/M/yyyy',
    'd.M.yyyy',
    'yyyy-MM-dd',
    'yyyy-MM-ddZZ',
    'dd-MM.yyyy',
    'dd.MM-yyyy',
    'yyyy.MM.dd',
    'yyyy-MM-dd',
    'yyyy.MM-dd',
    'yyyy-MM.dd'
  ];

  //Time Formats
  public datotidsvaelgerTimeFormat = 'HH:mm';
  public timeWithTimezone = 'HH:mm:ssZZ';

  public frominternalDatovaelgerFormatTostandardDatoFormat(dateString: string) {
    return DateTime.fromFormat(dateString, dateUtil.internalDatovaelgerFormat).toFormat(dateUtil.standardDatoFormat);
  }

  public fromStandardDatoFormatTointernalDatovaelgerFormat(dateString: string) {
    return DateTime.fromFormat(dateString, dateUtil.standardDatoFormat).toFormat(dateUtil.internalDatovaelgerFormat);
  }

  public fromIsoToUiDatetime(dateString: string) {
    return DateTime.fromISO(dateString, { zone: this.centraleuropaeiskNormaltid }).toFormat(dateUtil.uiDateTimeFormat);
  }

  public fromIsoToDatovaelger(dateString: string) {
    return DateTime.fromISO(dateString, { zone: this.centraleuropaeiskNormaltid }).toFormat(dateUtil.datovaelgerFormat);
  }

  public fromIsoToDatotidsvaelger(dateString: string) {
    return DateTime.fromISO(dateString, { zone: this.centraleuropaeiskNormaltid }).toFormat(dateUtil.datotidsvaelgerFormat);
  }

  public nowIso(): string {
    return DateTime.now().toISO()!;
  }

  public nowPlusMsIso(ms: number): string {
    return DateTime.now().plus(Duration.fromMillis(ms)).toISO()!;
  }

  public fromDatotidsvaelgerFormatToIsoFormat(dato: string | undefined): string {
    return dato ? DateTime.fromFormat(dato, dateUtil.internalDatotidsvaelgerFormat, { zone: this.centraleuropaeiskNormaltid }).toISO()! : '';
  }

  public fromDatovaelgerFormatToIsoFormat(dato: string | undefined): string {
    return dato
      ? DateTime.fromFormat(dato, dateUtil.internalDatovaelgerFormat, { zone: this.centraleuropaeiskNormaltid }).toFormat(this.dateWithTimezone)!
      : '';
  }

  public fromDatovaelgerDataFormatToIsoFormatZulu(data: string | undefined): string {
    return data ? DateTime.fromFormat(data, dateUtil.internalDatovaelgerFormat, { zone: 'UTC' }).toFormat('yyyy-MM-dd')! : '';
  }

  public selectedTimePlusTimezone(time: string): string {
    return DateTime.fromISO(time, { zone: this.centraleuropaeiskNormaltid }).toFormat(`${dateUtil.datotidsvaelgerTimeFormat}':00'ZZ`);
  }

  public nowTimeAsCopenhagen(): string {
    return DateTime.now().setZone(this.centraleuropaeiskNormaltid).toFormat(`${dateUtil.datotidsvaelgerTimeFormat}':00'ZZ`);
  }

  public nowDateAsCopenhagen(): string {
    return DateTime.now().setZone(this.centraleuropaeiskNormaltid).toFormat(this.dateWithTimezone);
  }

  public fromDateWithTimezoneToWithoutTimezone(datoWithTz: string): string {
    return DateTime.fromFormat(datoWithTz, this.dateWithTimezone).setZone(this.centraleuropaeiskNormaltid).toFormat('yyyy-MM-dd');
  }

  public fromDateWithTimezoneToDanskPresentation(datoWithTz: string): string {
    return DateTime.fromFormat(datoWithTz, this.dateWithTimezone).setZone(this.centraleuropaeiskNormaltid).toFormat('dd-MM-yyyy');
  }

  public fromTimeWithTimezoneToDanskPresentation(timeWithTz: string): string {
    const time = DateTime.fromFormat(timeWithTz, dateUtil.timeWithTimezone);
    if (!time.isValid) {
      return timeWithTz;
    }
    return time.setZone(this.centraleuropaeiskNormaltid).toFormat(dateUtil.datotidsvaelgerTimeFormat);
  }

  public fromArkivUdbudFormatToDateTime(arkivDato: string): DateTime {
    return DateTime.fromFormat(arkivDato, this.arkivUdbudDatotidsFormat).setZone(this.centraleuropaeiskNormaltid);
  }

  public dateTimeToStandardFormat(date: DateTime): string {
    return date.toFormat(this.standardDatoFormat);
  }

  public getCurrentDanishDateTime(): DateTime {
    const dateTime = DateTime.now();
    return dateTime.setZone(this.centraleuropaeiskNormaltid);
  }

  public getCurrentDate(): string {
    return DateTime.now().toFormat('yyyy-MM-dd');
  }

  public convertDateTimeStrToDanishTimezone(dateTimeStr: string): DateTime {
    const dateTime = DateTime.fromISO(dateTimeStr);
    return dateTime.setZone(this.centraleuropaeiskNormaltid);
  }

  public formatDatetimeToDateWithTimezone(dt: DateTime) {
    return dt.toFormat(this.dateWithTimezone);
  }

  public formatDatetimeToTimeWithTimezone(dt: DateTime) {
    return dt.toFormat(this.timeWithTimezone);
  }

  public formatToStandardDatePresentation(date: DateTime): string {
    return date.toFormat(this.standardDatoFormat);
  }

  public getValidTimeformatFromString(input: string) {
    const possibleFormats = ['HH:mm', 'HH.mm', 'HHmm', 'HH', 'H'];
    for (const format of possibleFormats) {
      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        const formattedTime = dt.toFormat('HH:mm');
        return formattedTime;
      }
    }
    return input;
  }

  public getValidDateformatFromString(input: string): string {
    const possibleFormats = this.datepickerFormats;

    for (const format of possibleFormats) {
      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        return dt.toFormat(this.standardDatoFormat);
      }
    }
    return input;
  }

  public verifyIfDateIsValid(input: string): string {
    if (input === undefined || input === null || input.trim() === '') {
      return '';
    }

    const possibleFormats = this.datepickerFormats;

    for (const format of possibleFormats) {
      if (!format || typeof format !== 'string') {
        continue;
      }

      const dt = DateTime.fromFormat(input, format);
      if (dt.isValid) {
        return dt.toFormat(this.standardDatoFormat);
      }
    }
    return InputFejl.IKKE_GYLDIG_DATO;
  }

  public validFilterDTOFormat(value: string): string {
    if (!value || typeof value !== 'string') {
      return '';
    }
    const dt = DateTime.fromFormat(value, this.standardDatoFormat);
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.internalDatovaelgerFormat);
  }

  public validFilterFormat(value: string) {
    const dt = DateTime.fromFormat(value, this.internalDatovaelgerFormat);
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.standardDatoFormat);
  }

  public validEformsFormat(value: string) {
    if (!value || typeof value !== 'string') {
      return '';
    }
    const dt = DateTime.fromFormat(value, this.standardDatoFormat, { zone: this.centraleuropaeiskNormaltid });
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(this.dateWithTimezone);
  }

  public setFormat(value: string, format: string) {
    const dt = DateTime.fromFormat(value, this.standardDatoFormat, { zone: this.centraleuropaeiskNormaltid });
    if (!dt.isValid) {
      return value;
    }
    return dt.toFormat(format);
  }

  public fromDateTimeToOpsummeringFormat(dateTime: DateTime): string {
    return dateTime.toFormat('dd-MM-yyyy - HH:mm:ss');
  }

  public getDateFromIso(dateString: string) {
    return dateString ? DateTime.fromISO(dateString, { zone: this.centraleuropaeiskNormaltid }).toFormat(dateUtil.standardDatoFormat) : '';
  }

  public getTimeFromIso(dateString: string) {
    return dateString ? DateTime.fromISO(dateString, { zone: this.centraleuropaeiskNormaltid }).toFormat(dateUtil.datotidsvaelgerTimeFormat) : '';
  }

  public getDateOneYearBack(): string {
    return DateTime.now().minus({ years: 1 }).toFormat('yyyy-MM-dd');
  }

  public getDateNinetyNineDaysBack(): string {
    return DateTime.now().minus({ days: 99 }).toFormat('yyyy-MM-dd');
  }

  public formatUniqueSortedDates(fristArray: string[], timezone: string, dateFormat: string): string[] {
    return Array.from(new Set(fristArray))
      .map(dateStr => DateTime.fromISO(dateStr, { zone: timezone }))
      .sort((a, b) => a.toMillis() - b.toMillis())
      .map(date => date.toFormat(dateFormat));
  }

  public getVisningsOpsummeringsFormat(fristArray: string[]): string {
    if (!fristArray || fristArray.length === 0) {
      return '';
    }

    const uniqueSortedFormattedDates = this.formatUniqueSortedDates(
      fristArray,
      this.centraleuropaeiskNormaltid,
      this.visningsOpsummeringDatotidsFormat
    );

    return uniqueSortedFormattedDates.join(', ');
  }
}

export const dateUtil = new DateUtil();
