<template>
  <button @click="gaaTilToppen" v-if="visGaaTilToppenKnap" class="back-to-top-button button button-tertiary d-print-none" type="button">
    <svg class="icon-svg" focusable="false" aria-hidden="true"><use xlink:href="#arrow-upward"></use></svg>
    <span class="sr-only">{{ $t('gaa-til-toppen-knap-sr-only') }}</span>
    <span class="d-none d-md-inline-block" aria-hidden="true">{{ $t('gaa-til-toppen-knap') }}</span>
  </button>
</template>

<script setup>
import { onMounted, onUnmounted, ref } from 'vue';

const visGaaTilToppenKnap = ref(false);

const checkScroll = () => {
  visGaaTilToppenKnap.value = document.documentElement.scrollTop > 1800;
};

const gaaTilToppen = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

onMounted(() => {
  window.addEventListener('scroll', checkScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScroll);
});
</script>
