<template>
  <form novalidate class="form-group" @submit.prevent="onSubmit">
    <FejlOpsummering v-if="showOpsummering" :error-messages="errors ?? {}" :fejl-prefix="fejlPrefix" />
    <slot></slot>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate';

import FejlOpsummering from '@/main/components/input/FejlOpsummering.vue';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';

const props = defineProps({
  fejlPrefix: {
    type: Object,
    default: () => {}
  },
  showOpsummering: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits([StandardEmitEvent.HANDLE_SUBMIT, StandardEmitEvent.SUBMIT_BUT_ERRORS]);

const { values, handleSubmit, errors, validate } = useForm();

const failValidation = ({ values, errors, results }) => {
  emit(StandardEmitEvent.SUBMIT_BUT_ERRORS, { values, errors, results });
};

const onSubmit = handleSubmit(values => {
  emit(StandardEmitEvent.HANDLE_SUBMIT, { values });
}, failValidation);

function submit() {
  onSubmit();
}

defineExpose({
  values,
  validate,
  submit,
  errors
});
</script>
