/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';

import type { NoticeTypeKonfiguration } from '../../../models/generated';

export const getFormularKonfigurationController = () => {
  /**
   * @summary Henter MitUdbud-specifik konfiguration ovenpå SDK'en
   */
  const hentNoticeTypeKonfiguration = <TData = AxiosResponse<NoticeTypeKonfiguration>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/formularkonfiguration/NoticeTypeKonfiguration.json`, options);
  };
  return { hentNoticeTypeKonfiguration };
};
export type HentNoticeTypeKonfigurationResult = AxiosResponse<NoticeTypeKonfiguration>;
