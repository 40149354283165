/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Hvilke sider meddelelsen skal vises på, kan liste alle
 */
export type DriftsmeddelelseDtoSynligPaaSiderItem =
  (typeof DriftsmeddelelseDtoSynligPaaSiderItem)[keyof typeof DriftsmeddelelseDtoSynligPaaSiderItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriftsmeddelelseDtoSynligPaaSiderItem = {
  FORSIDE: 'FORSIDE',
  OM_MITUDBUD_SIDE: 'OM_MITUDBUD_SIDE'
} as const;
