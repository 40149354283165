<template>
  <div class="publicerings-dato p-4" data-cy="publicerings-filter-vindue">
    <h2 class="h4 m-0" data-cy="publicerings-filter-overskrift">{{ $t('filter.' + konfigurationNavn + '.overskift') }}</h2>
    <p data-cy="publicerings-filter-broedtekst">{{ $t('filter.' + konfigurationNavn + '.manchet') }}</p>
    <FormGroup :show-opsummering="false" ref="formGroup">
      <div class="form-error-message" v-if="!isEmpty(errors)">{{ $t('filter.' + konfigurationNavn + '.fejlmeddelelse') }}</div>
      <div class="row pl-4 pb-5">
        <InputFelt
          :label="$t('filter.' + konfigurationNavn + '.anslaaet-vaerdi.Fra.label')"
          ref="anslaaetVaerdiFraFelt"
          :show-hint="false"
          :rules="'currency'"
          :vis-generel-fejlmeddelse="showCommonErrorMessage"
          data-cy="anslaaet-vaerdi-fra-felt"
          name="anslaaetVaerdiFraFelt"
          class="mr-4"
          :text-prefix="'filter.' + konfigurationNavn + '.anslaaet-vaerdi.Fra'"
          :preselected-value="formattedValues.from"
          :sync-value="getSelectedCustomValue(konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_FRA)"
          @change.stop="fromChanged" />
        <InputFelt
          :label="$t('filter.' + konfigurationNavn + '.anslaaet-vaerdi.Til.label')"
          ref="anslaaetVaerdiTilFelt"
          :show-hint="false"
          :rules="'currency'"
          :vis-generel-fejlmeddelse="showCommonErrorMessage"
          data-cy="anslaaet-vaerdi-til-felt"
          name="anslaaetVaerdiTilFelt"
          class="mt-sm-4 mt-md-0 mr-4"
          :text-prefix="'filter.' + konfigurationNavn + '.anslaaet-vaerdi.Fra'"
          :preselected-value="formattedValues.to"
          :sync-value="getSelectedCustomValue(konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_TIL)"
          @change.stop="toChanged" />
        <Dropdown
          v-if="valutaIndhold.length !== 0"
          :label="true"
          :show-hint="false"
          :er-e-forms="false"
          ref="anslaaetVaerdiValuta"
          data-cy="anslaaet-vaerdi-valuta"
          name="anslaaetVaerdiValuta"
          class="mt-sm-4 mt-md-0 mr-4"
          :text-label-override="$t('generisk.felt.valuta.label')"
          :options="valutaIndhold"
          :preselected-value="valutaIndhold.find(x => x.value === selectedCurrency)"
          :sync-value="getSelectedCustomValue(konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_VALUTA)"
          @change="valutaChanged" />
      </div>
    </FormGroup>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { PropType, computed, ref, watch } from 'vue';

import Dropdown from '@/main/components/input/Dropdown.vue';
import FormGroup from '@/main/components/input/FormGroup.vue';
import InputFelt from '@/main/components/input/InputFelt.vue';
import { CustomFilterKonfigurationNavn, VaerdiType } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { InputFejl } from '@/main/enums/inputFejl.enum';
import { DropdownOption } from '@/main/models/base/DropdownOption';
import { KonfigureretContent } from '@/main/models/eforms/KonfigureretContent';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import InputFormatUtil from '@/main/utils/input-format-util';

const props = defineProps({
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  vaerdiFeltNoticeTypeContent: {
    type: Object as PropType<KonfigureretContent | undefined>,
    default: undefined
  },
  noticeTypeContent: {
    type: Object as PropType<KonfigureretContent | undefined>,
    default: undefined
  }
});

const formGroup = ref<InstanceType<typeof FormGroup>>();
const soegningStore = useSoegningValgStore(window.pinia);
const errors = ref();
const showCommonErrorMessage = ref(true);
const { getSelectedCustomValue } = storeToRefs(soegningStore);
const selectedCurrency = ref(getSelectedCustomValue.value(props.konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_VALUTA));

const valutaIndhold = ref<DropdownOption[]>([]);
valutaIndhold.value = [
  { key: 'dropdown.default-label', value: 'dropdown.default-label', label: 'dropdown.default-label' },
  { key: 'DKK', value: 'DKK', label: 'DKK' },
  { key: 'EUR', value: 'EUR', label: 'EUR' }
];

const emit = defineEmits<{
  (e: 'from-changed', konfigurationNavn: CustomFilterKonfigurationNavn, value: string): void;
  (e: 'to-changed', konfigurationNavn: CustomFilterKonfigurationNavn, value: string): void;
  (e: 'valuta-changed', konfigurationNavn: CustomFilterKonfigurationNavn, value: string): void;
}>();

const formattedValues = computed(() => {
  const fromValue = getSelectedCustomValue.value(props.konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_FRA);
  const toValue = getSelectedCustomValue.value(props.konfigurationNavn, VaerdiType.ANSLAAET_VAERDI_TIL);
  return {
    from: fromValue !== undefined ? InputFormatUtil.formaterValutaVisningMedPunktumOgKomma(fromValue) : undefined,
    to: toValue !== undefined ? InputFormatUtil.formaterValutaVisningMedPunktumOgKomma(toValue) : undefined
  };
});

const fromChanged = (event: Event) => {
  if (event.target && event.target instanceof HTMLInputElement) {
    const valueAsNumber = event.target.value;
    emit('from-changed', props.konfigurationNavn, valueAsNumber);
  }
};

const toChanged = (event: Event) => {
  if (event.target && event.target instanceof HTMLInputElement) {
    const valueAsNumber = event.target.value;
    emit('to-changed', props.konfigurationNavn, valueAsNumber);
  }
};

const valutaChanged = (selectedValue: string) => {
  emit('valuta-changed', props.konfigurationNavn, selectedValue);
};

watch(
  () => formGroup.value?.errors,
  () => {
    errors.value = formGroup.value?.errors;
    showCommonErrorMessage.value = true;
    if (containsString(errors.value, InputFejl.IKKE_GYLDIGT_NUMBER)) {
      showCommonErrorMessage.value = false;
    }

    soegningStore.setValidationError(isEmpty(errors.value));
  }
);

function isEmpty(errorObject: Partial<Record<string, string | undefined>> | undefined): boolean {
  if (errorObject === undefined) {
    return true;
  }
  return Object.keys(errorObject).length === 0;
}

function containsString(errorObject: Partial<Record<string, string | undefined>> | undefined, str: string): boolean {
  if (errorObject) {
    for (const key in errorObject) {
      if (errorObject[key] === str) {
        return true;
      }
    }
  }
  return false;
}
</script>
