/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Type af driftsmeddelelse
 */
export type DriftsmeddelelseDtoBeskedtype = (typeof DriftsmeddelelseDtoBeskedtype)[keyof typeof DriftsmeddelelseDtoBeskedtype];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DriftsmeddelelseDtoBeskedtype = {
  DRIFTS_BESKED: 'DRIFTS_BESKED',
  INFORMATIV_BESKED: 'INFORMATIV_BESKED'
} as const;
