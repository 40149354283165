import { AxiosError } from 'axios';

import { BekendtgoerelseNoegle, EgenBekendtgoerelseDto, VisualModelNode } from '@/main/models/generated';

import { Komponent } from '../enums/komponent.enum';
import { HentEgneBekendtgoerelserResult, getBekendtgoerelseController } from './generated/bekendtgoerelse-controller/bekendtgoerelse-controller';
import AuthService from './s9-oidc/auth.service';

export class BekendtgoerelseService {
  public async hentAlleKoebersRolledata(): Promise<KoeberInfo[]> {
    try {
      const controller = getBekendtgoerelseController();
      // console.log('controller', controller);

      const config = AuthService.getConfig(Komponent.Udbud, false);
      // console.log('config', config);

      const axiosResponse = await controller.hentAlleKoebersRolledata(config);
      // console.log('Axios response:', axiosResponse);
      // console.log('axiosResponse.data', axiosResponse.data);

      return axiosResponse.data as KoeberInfo[];
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Axios error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Unexpected error fetching data:', error);
      }
      // Return an empty array to avoid type mismatch
      return [];
    }
  }

  public async harNogenBekendtgoerelser(): Promise<boolean> {
    try {
      const controller = getBekendtgoerelseController();
      const axiosResponse = await controller.harBrugerNogenBekendtgoerelser(this.config);
      return axiosResponse.data.harNogenBekendtgoerelser === true;
    } catch (error) {
      throw error;
    }
  }

  public async hentEgneBekendtgoerelser(): Promise<EgenBekendtgoerelseDto[]> {
    try {
      const controller = getBekendtgoerelseController();
      const axiosResponse = await controller.hentEgneBekendtgoerelser<HentEgneBekendtgoerelserResult>(this.config);
      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error fetching all saved searches:', error);
      }
      throw error;
    }
  }

  public async hentBekendtgoerelseSomVisualModel(noegle: BekendtgoerelseNoegle): Promise<VisualModelNode> {
    try {
      const controller = getBekendtgoerelseController();
      const axiosResponse = await controller.hentBekendtgoerelseSomVisualModel(
        noegle.noticeId?.value!,
        noegle.noticeVersion?.value!,
        {
          noticePublicationNumber: noegle.noticePublicationNumber?.value ?? ''
        },
        this.config
      );
      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error: ' + error, error);
      }
      throw error;
    }
  }

  private get config() {
    return AuthService.getConfig(Komponent.Udbud, false);
  }
}

export const bekendtgoerelseService = new BekendtgoerelseService();
