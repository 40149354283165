<template>
  <div :class="{ 'form-error': visFejl }" class="form-group">
    <FeltLabel
      :for-id="inputFeltId"
      :label="label"
      :text-prefix="textPrefix"
      :advarsel="advarsel"
      :name="name"
      :er-e-forms="erEForms"
      :rules="computedRules"
      :disabled="disabled"
      :show-hint="showHint"
      :show-tooltip="showTooltip"
      :hide="hide"
      :readonly="readonly"
      :force-vis-obligatorisk="forceVisObligatorisk"
      :required="inputUtil.isRequired(props.rules)" />
    <span v-if="visFejl" :data-cy="inputFeltId + '-error'" :id="inputFeltId + '-error'" class="form-error-message">
      <span class="sr-only">{{ $t('fejlmeddelelse-error-label') }}</span>
      <template v-if="extraError">
        {{ $t(extraError) }}
      </template>
      <template v-else>
        {{ $t(customErrorMessages ? textPrefix : 'input' + '.' + errors[0]!) }}
      </template>
    </span>
    <div :class="{ 'd-flex': showFunctionLink || flexDiv }">
      <template v-if="isTextArea()">
        <textarea
          :value="(inputValue as string)"
          :name="name"
          :id="inputFeltId"
          :data-cy="name"
          :autocomplete="autocomplete"
          :type="type"
          :readonly="readonly"
          :required="inputUtil.isRequired(props.rules)"
          :class="['form-input', inputClass, FeltBredde.FELTBREDDE_XXXL, 'rowcount-' + getRowCount()]"
          :rows="getRowCount()"
          @input="handleOnInput"
          @change="handleOnChange"
          @blur="handleOnBlur"
          :aria-describedby="visFejl ? inputFeltId + '-error' : undefined"
          :validateOnBlur="false"
          :validateOnChange="false"
          :validateOnInput="false" />
      </template>
      <template v-else>
        <input
          :value="inputValue"
          :name="name"
          :id="inputFeltId"
          :data-cy="name"
          :autocomplete="autocomplete"
          :type="type"
          :readonly="readonly"
          :required="inputUtil.isRequired(props.rules)"
          :class="['form-input', inputClass]"
          @input="handleOnInput"
          @change="handleOnChange"
          @blur="handleOnBlur"
          :aria-describedby="visFejl ? inputFeltId + '-error' : undefined"
          :validateOnBlur="false"
          :validateOnChange="false"
          :validateOnInput="false"
          :aria-label="label" />
      </template>
      <FunctionLink v-if="showFunctionLink" :icon="functionLinkIcon" class="align-self-end pb-3 pl-3" @click="functionLinkOnClick">
        {{ $t(functionLinkTextkey) }}
      </FunctionLink>
      <slot></slot>
    </div>
    <AntalTegnHint
      v-if="visAntalTegnHint && maxlength != null && maxlength > 0 && antalTegn.tegn > 0"
      ref="antalHint"
      :antal-tegn="antalTegn.tegn"
      :maxlength="maxlength" />
    <button
      v-if="showButton"
      type="button"
      class="button button-unstyled search-btn"
      :aria-label="$t(`${textPrefix}.button`)"
      @click="emitSelectedOnClick">
      <svg class="icon-svg" focusable="false" aria-hidden="true">
        <use xlink:href="#search" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { RuleExpression, useField } from 'vee-validate';
import { PropType, computed, onMounted, reactive, ref, watch } from 'vue';

import FunctionLink from '@/main/components/FunctionLink.vue';
import AntalTegnHint from '@/main/components/input/AntalTegnHint.vue';
import FeltLabel from '@/main/components/input/FeltLabel.vue';
import { MaxLength } from '@/main/enums/eforms/eformsFieldMaxLength.enum';
import { FeltBredde } from '@/main/enums/feltbredde.enum';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { TextRowAmount } from '@/main/enums/textRowAmount.enum';
import InputFormatUtil from '@/main/utils/input-format-util';
import { inputUtil } from '@/main/utils/input-util';

type FunctionLinkType = () => void;
type FormateringVedBlurType = (inputValue: string) => string;

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  textPrefix: {
    type: String,
    required: true
  },
  maxlength: {
    type: Number,
    default: undefined
  },
  visAntalTegnHint: {
    type: Boolean,
    default: true
  },
  erEForms: {
    type: Boolean,
    default: false
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  customErrorMessages: {
    type: Boolean,
    default: false
  },
  advarsel: {
    type: String,
    default: ''
  },
  showButton: {
    type: Boolean,
    default: false
  },
  showFunctionLink: {
    type: Boolean,
    default: false
  },
  flexDiv: {
    type: Boolean,
    default: false
  },
  showHint: {
    type: Boolean,
    default: true
  },
  showTooltip: {
    type: Boolean,
    default: true
  },
  functionLinkOnClick: {
    type: Function as PropType<FunctionLinkType>,
    default: () => {}
  },
  functionLinkTextkey: {
    type: String,
    default: ''
  },
  functionLinkIcon: {
    type: String,
    default: 'mode'
  },
  type: {
    type: String,
    default: 'text'
  },
  autocomplete: {
    type: String,
    default: 'off'
  },
  formateringVedBlur: {
    type: Function as PropType<FormateringVedBlurType>,
    default: (v: string) => v
  },
  inputClass: {
    type: String as PropType<string | FeltBredde>,
    default: ''
  },
  isTextarea: {
    type: Boolean,
    required: false,
    default: undefined
  },
  textareaRows: {
    type: Number,
    default: undefined
  },
  noticeDisplayType: {
    type: String || undefined,
    default: ''
  },
  rules: {
    type: Object as PropType<RuleExpression<unknown>>,
    default: {} as RuleExpression<unknown>
  },
  readonly: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  delAfVaerdiMedEnhedFelt: {
    type: Boolean,
    default: false
  },
  extraError: {
    type: String as PropType<string>,
    default: undefined
  },
  forceVisObligatorisk: {
    type: Boolean,
    default: false
  },
  hide: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits([
  StandardEmitEvent.CHANGE,
  StandardEmitEvent.CLICK,
  StandardEmitEvent.BLUR,
  StandardEmitEvent.ERROR_CHANGE,
  StandardEmitEvent.VALID_CHANGE
]);

const antalTegn = reactive({ tegn: 0 });
const formater = ref(props.formateringVedBlur);
const computedRules = ref(props.rules);
const autocomplete = ref(props.autocomplete);
const inputFeltId = ref(props.id);
const name = ref(props.name);

checkType();
const {
  errors,
  meta,
  value: inputValue,
  setValue,
  setErrors,
  handleBlur,
  handleChange,
  validate
} = useField(name, computedRules.value, {
  validateOnValueUpdate: false
});

const visFejl = computed(() => inputUtil.skalViseFejlPaaEFormsFelt(props.extraError, props.erEForms, computedRules.value, true, errors.value));

onMounted(() => {
  if (props.preselectedValue) {
    setValue(props.preselectedValue, false);
    autoResizeTextarea();
  }

  watch(
    () => props.preselectedValue,
    (newValue, prevValue) => {
      setValue(newValue, false);
      autoResizeTextarea();
    }
  );
});

function isTextArea() {
  if (props.isTextarea !== undefined) {
    return props.isTextarea;
  }

  // MUD-1400: AK1, hvis der er angivet en maxlength så skal det være et textarea.
  return props.maxlength !== undefined;
}

function getRowCount() {
  if (props.textareaRows !== undefined) {
    return props.textareaRows;
  }

  if (props.maxlength !== undefined) {
    if (props.maxlength <= MaxLength.M) {
      return TextRowAmount.ROWAMOUNT_SINGLE;
    } else if (props.maxlength <= MaxLength.L) {
      return TextRowAmount.ROWAMOUNT_XXS;
    } else {
      return TextRowAmount.ROWAMOUNT_XS;
    }
  }

  return TextRowAmount.ROWAMOUNT_XS;
}

function handleOnInput(e: Event) {
  handleChange(e, false);
  antalTegn.tegn = inputValue.value ? (inputValue.value as string).length : 0;
  autoResizeTextarea();
}

function autoResizeTextarea() {
  if (isTextArea()) {
    const textArea = document.getElementById(inputFeltId.value);
    if (textArea !== null) {
      textArea.style.height = '';
      // Height skal enten være scrollHeight
      const scrollHeight = textArea.scrollHeight;

      // Eller beregnet ud fra indholdet:
      const newLinesInInput = (inputValue.value as string) ? ((inputValue.value as string).match(/\n/g) || []).length : 0;
      const lineCount = newLinesInInput + 1;
      const heightFromLines = 16 + lineCount * 24; // In css format: calc(2.4rem * lines + 16 px)

      // Vælg den største :)
      textArea.style.height = Math.max(scrollHeight + 2, heightFromLines) + 'px';
    }
  }
}

function handleOnChange(e: Event) {
  let format = formater.value(inputValue.value as string);
  format = format.trim();
  handleChange(format, true);
  validate().then(validation => {
    console.log('change i InputFelt med ' + errors.value.length + ' fejl til tekst ' + inputValue.value);
    if (validation.valid) {
      emit(StandardEmitEvent.VALID_CHANGE, e);
    } else {
      emit(StandardEmitEvent.ERROR_CHANGE, e);
    }
  });
  emit(StandardEmitEvent.CHANGE, e);
}

function handleOnBlur(e: Event) {
  handleBlur(e, true);
  emit(StandardEmitEvent.BLUR, e);
}

function emitSelectedOnClick(): void {
  if (props.showButton) {
    emit(StandardEmitEvent.CLICK, (inputValue.value as string).trim());
  }
}

function checkType() {
  switch (props.type) {
    case 'tel':
      setPhoneProperties();
      break;
    case 'email':
      setEmailProperties();
      break;
  }
}

function setPhoneProperties() {
  autocomplete.value = 'tel';
  computedRules.value = props.rules + '|phone';
  formater.value = InputFormatUtil.formaterTelefon;
}

function setEmailProperties() {
  autocomplete.value = 'email';
  computedRules.value += '|email';
}

defineExpose({
  inputValue,
  validate,
  setErrors,
  errors,
  setValue
});
</script>
<style lang="scss" scoped>
@import '../../styles/_input';

textarea {
  // Manuelt beregn højden på feltet til antal rækker * lineheight + padding.
  @for $ht from 1 through 15 {
    &.rowcount-#{$ht} {
      min-height: calc($ht * 2.4rem + 16px);
      height: calc($ht * 2.4rem + 16px);
    }
  }
  // overflow: hidden;
  min-width: 32rem;
}
</style>
