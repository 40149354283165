import { AxiosError } from 'axios';

import { BekendtgoerelseKladde, BekendtgoerelseKladdeMetadataDto, VisualModelNode } from '@models';

import { Komponent } from '@/main/enums/komponent.enum';
import { BekendtgoerelseNoegle } from '@/main/models/generated';
import { getBekendtgoerelseKladdeController } from '@/main/services/generated/bekendtgoerelse-kladde-controller/bekendtgoerelse-kladde-controller';
import AuthService from '@/main/services/s9-oidc/auth.service';
import { useEformsModelStore } from '@/main/stores/eforms-model.state';
import { useToastStore } from '@/main/stores/toast.state';

class BekendtgoerelseKladdeService {
  SAVE_INTERVAL_IN_MS = 10_000;
  DEBOUNCE_INTERVAL = 250;
  MAX_RETRY_COUNT = 3;
  SAVE_RETRY_WAIT_INTERVAL = 5000;
  timeoutId?: ReturnType<typeof setTimeout>;

  public async harNogenKladder(): Promise<boolean> {
    try {
      const controller = getBekendtgoerelseKladdeController();
      const config = AuthService.getConfig(Komponent.Udbud, false);
      const axiosResponse = await controller.harBrugerNogenKladder(config);
      return axiosResponse.data.harKladde === true;
    } catch (error) {
      throw error;
    }
  }

  public async hentEgneKladder(): Promise<BekendtgoerelseKladdeMetadataDto[]> {
    try {
      const controller = getBekendtgoerelseKladdeController();
      const config = AuthService.getConfig(Komponent.Udbud, false);
      const axiosResponse = await controller.findAllByUser(config);
      return axiosResponse.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        console.error(`Error: ${error.response.status} - ${error.response.data}`);
      } else {
        console.error('Error fetching all saved searches:', error);
      }
      throw error;
    }
  }

  public async hentKladde(kladdeNoegle: BekendtgoerelseNoegle) {
    const config = AuthService.getConfig(Komponent.Udbud, false);
    try {
      const noticeId = kladdeNoegle.noticeId?.value;
      const noticeVersion = kladdeNoegle.noticeVersion?.value;
      if (noticeId == undefined || noticeVersion == undefined) {
        throw new Error('Manglede kladde info');
      }
      const kladde = await getBekendtgoerelseKladdeController().findByNoticeIdAndNoticeVersion(noticeId, noticeVersion, config);
      const eformsModelStore = useEformsModelStore(window.pinia);
      eformsModelStore.kladde.lastSavedKladde = JSON.stringify(kladde.data);
      return kladde.data;
    } catch (err: any) {
      if (err instanceof AxiosError && err.response) {
        if (err.response.status == 401 || err.response.status == 403) {
          throw new Error('UNAUTHORIZED');
        }
        if (err.response.status == 404) {
          throw new Error('NOT_FOUND');
        }
      }
      console.error(err);
      throw new Error('Ukendt fejl under kladde hentning');
    }
  }

  /**
   * Fra hver ændring, vent DEBOUNCE_INTERVAL indtil vi forsøger at opdatere kladde.
   * Dette undgår at automatiske opdatering udfører en kladde-gemning og
   * så kan brugerens faktisk ændringer ikke gemmes indtil SAVE_INTERVAL_IN_MS.
   */
  public debouncedOpdaterKladde = debounce(async (kladdeNoegle: BekendtgoerelseNoegle) => {
    console.debug('💾debouncedKald => opdaterKladde');
    if (this.timeoutId) {
      console.log('💾this.timeoutId: ' + this.timeoutId + ' springer denne opdater fra');
      return;
    }
    await this.opdaterKladde(kladdeNoegle);
  }, this.DEBOUNCE_INTERVAL);

  public afbrydeKladdeOpdatering() {
    // Ryd enhver eksisterende timeout
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      console.log('💾Afbrød kladde opdatering');
      this.timeoutId = undefined;
    } else {
      console.log('💾Fandt ikke nogen kladde opdatering at afbryde');
    }
  }

  public async forceOpdaterKladde(kladdeNoegle: BekendtgoerelseNoegle): Promise<boolean> {
    if (kladdeNoegle.noticeId == undefined || kladdeNoegle.noticeVersion == undefined) {
      console.log('💾Der var ikke nogen kladde at gemme...');
      return false;
    }
    console.log('💾Force opdaterer kladde!');
    await this.innerOpdaterKladde(kladdeNoegle);
    return true;
  }

  /**
   * Schedulerer en kladde-opdatering.
   * Kan ikke ske oftere end hver SAVE_INTERVAL_IN_MS ms, og kører altid første gang.
   */
  private async opdaterKladde(kladdeNoegle: BekendtgoerelseNoegle): Promise<boolean> {
    const eformsModelStore = useEformsModelStore(window.pinia);
    console.debug('💾opdaterKladde: kaldt. Already scheduled? ' + eformsModelStore.kladde.scheduled);

    // Ryd enhver eksisterende timeout
    if (this.timeoutId) {
      console.debug('💾this.timeoutId: ' + this.timeoutId + ' er sat, rydder');
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }

    const now = Date.now();
    if (eformsModelStore.kladde.lastSaveStartTime !== undefined) {
      console.debug(
        '💾lastSaveStartTime sat: ' +
          eformsModelStore.kladde.lastSaveStartTime +
          ' diff: ' +
          (now - eformsModelStore.kladde.lastSaveStartTime) +
          ' ms'
      );
    }

    eformsModelStore.kladde.lastSaveStartTime = Date.now();

    // Spring over, hvis ingen ændringer er
    if (!eformsModelStore.isKladdeDirty()) {
      eformsModelStore.kladde.scheduled = false;
      console.debug('💾opdaterKladde: Undlader at gemme fordi den gemte kladde er identisk med aktuelt state');
      return false;
    }

    eformsModelStore.kladde.scheduled = true;

    const lastSavedAt = eformsModelStore.kladde.lastSavedAt;

    // Hvis der er gået mere end SAVE_INTERVAL_IN_MS siden sidste gemning, gem straks
    if (lastSavedAt == undefined || now - lastSavedAt >= this.SAVE_INTERVAL_IN_MS) {
      console.debug('💾opdaterKladde: Vi gemmer nu fordi der er gået mere end 30 sekunder siden sidste save.');
      const saved = await this.innerOpdaterKladde(kladdeNoegle);
      if (!saved) {
        // Vi fik faktisk ikke gemt ... Planlæg en ny gem:
        if (eformsModelStore.kladde.continuousErrorCount < this.MAX_RETRY_COUNT) {
          console.debug('💾Gem fejlede. Prøver igen om 5000 ms ...');
          this.timeoutId = setTimeout(() => {
            this.opdaterKladde(kladdeNoegle);
          }, this.SAVE_RETRY_WAIT_INTERVAL);
        } else {
          console.error('Kunne ikke gemme kladde 3 gange i træk, noget er galt!');
          const toastStore = useToastStore(window.pinia);
          toastStore.createToast('warning', 'formular.kladde.toast.gentagende-fejl.header', 'formular.kladde.toast.gentagende-fejl.message');
        }
      } else {
        console.debug('💾opdaterKladde: Ok!');
      }
    } else {
      // Ellers, beregn den resterende tid og sæt en timeout
      const msTilNaesteSave = lastSavedAt + this.SAVE_INTERVAL_IN_MS - now;
      console.debug('💾opdaterKladde: Kan gemme om: ' + (msTilNaesteSave / 1000).toFixed(1) + ' sekunder');

      // Planlæg kørsel af denne metode igen om [0, 30[ sekunder, så det passer med vi er klar til at gemme
      this.timeoutId = setTimeout(() => {
        this.opdaterKladde(kladdeNoegle);
      }, msTilNaesteSave + 1);
    }

    return true;
  }

  private async innerOpdaterKladde(kladdeNoegle: BekendtgoerelseNoegle): Promise<boolean> {
    const config = AuthService.getConfig(Komponent.Udbud, false);
    // Sæt timeout ned så vi kan give fejl tidligere hvis det sker ...
    config.timeout = 5000;
    const eformsModelStore = useEformsModelStore(window.pinia);
    try {
      await getBekendtgoerelseKladdeController().update(
        kladdeNoegle.noticeId!.value!,
        kladdeNoegle.noticeVersion!.value!,
        eformsModelStore.model,
        config
      );
      console.debug('💾Opdateret kladde: ' + JSON.stringify(kladdeNoegle));
      eformsModelStore.updateKladdeBookkeepingSaveDone();
      return true;
    } catch (err) {
      console.error(err);
      eformsModelStore.incrementKladdeErrorCounter();
      return false;
    }
  }

  public async opretKladde(kladdeNoegle: BekendtgoerelseNoegle, model: VisualModelNode): Promise<boolean> {
    const config = AuthService.getConfig(Komponent.Udbud, false);
    const eformsModelStore = useEformsModelStore(window.pinia);
    try {
      eformsModelStore.updateKladdeBookkeepingSaveInProgress();
      await getBekendtgoerelseKladdeController().create(kladdeNoegle.noticeId!.value!, kladdeNoegle.noticeVersion!.value!, model, config);
      console.debug('💾Oprettet kladde: ' + JSON.stringify(kladdeNoegle));
      eformsModelStore.updateKladdeBookkeepingSaveDone();
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
  public async sletKladde(kladdeNoegle: BekendtgoerelseNoegle): Promise<boolean> {
    const config = AuthService.getConfig(Komponent.Udbud, false);
    try {
      await getBekendtgoerelseKladdeController()._delete(kladdeNoegle.noticeId!.value!, kladdeNoegle.noticeVersion!.value!, config);
      console.debug('💾Slettet kladde: ' + JSON.stringify(kladdeNoegle));
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }
}

export const bekendtgoerelseKladdeService = new BekendtgoerelseKladdeService();

/**
 * Debounce fra https://gist.github.com/ca0v/73a31f57b397606c9813472f7493a940
 * @param cb
 * @param wait
 * @returns
 */
function debounce<T extends (...args: Parameters<T>) => ReturnType<T>>(callback: T, delay: number) {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    const p = new Promise<ReturnType<T> | Error>((resolve, reject) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        try {
          let output = callback(...args);
          resolve(output);
        } catch (err) {
          if (err instanceof Error) {
            reject(err);
          }
          reject(new Error(`An error has occurred:${err}`));
        }
      }, delay);
    });
    return p;
  };
}
