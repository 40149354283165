import { defineStore } from 'pinia';

import { SprogLocale } from '../enums/sprog.enum';

export interface SprogValg {
  sprog: SprogLocale;
  visNoegleForRedaktor: boolean;
}

export const useSprogStore = defineStore('Sprog', {
  state: (): SprogValg => ({
    sprog: SprogLocale.Dansk,
    visNoegleForRedaktor: false
  }),
  persist: {
    storage: sessionStorage
  },
  getters: {
    hentSprog: state => state.sprog,
    hentRedaktorNoegle: state => state.visNoegleForRedaktor
  },
  actions: {
    updateSprog(nytSprog: SprogLocale) {
      this.sprog = nytSprog;
    },
    updateRedaktorNoegle(nyRedaktorNoegle: boolean) {
      this.visNoegleForRedaktor = nyRedaktorNoegle;
    }
  }
});
