<template>
  <div class="modal-body" data-cy="soegning-gemt-modal">
    <span>
      {{ $t('udbudsagentGemt.broedtekst') }} <i data-cy="gemt-soegning-navn">"{{ gemtSoegningNavn }}".</i></span
    >
    <ContentfulRigTekst :document="$tm('udbudsagentGemtModal.broedtekst')" />
    <RouterLink :to="Path.UDBUDSAGENT" class="link-text my-6" @click="modalStore.closeModal()" data-cy="udbudsagent-oprettet-link">
      {{ $t('udbudsagent.link') }}
    </RouterLink>
  </div>
  <div class="modal-footer">
    <button type="button" @click="modalStore.closeModal()" class="button button-primary" data-cy="soegning-gemt-luk-knap">
      {{ $t('luk') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { Path } from '@/main/enums/path.enum';
import { useModalStore } from '@/main/stores/modal.state';
import { useSoegningValgStore } from '@/main/stores/soegning.state';

const soegningStore = useSoegningValgStore(window.pinia);
const gemtSoegningNavn = soegningStore.gemtSoegningNavn;

const modalStore = useModalStore(window.pinia);
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
