export enum MetaDescription {
  FORSIDE = 'metaDescription.forside',
  INFORMATION = 'metaDescription.information',
  INDSTILLINGER = 'metaDescription.indstillinger',
  SOEG = 'metaDescription.soeg',
  UDBUDSAGENT = 'metaDescription.udbudsagent',
  ORDREGIVER = 'metaDescription.ordregiver',
  OPRET_NYT = 'metaDescription.opret-nyt',
  ARKIV = 'metaDescription.arkiv',
  OPRET_AENDRING = 'metaDescription.opret-aendring',
  KVITTERING = 'metaDescription.kvittering',
  HJAELPESIDE = 'metaDescription.hjaelpeside'
}
