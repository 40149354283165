/**
 * https://github.com/OP-TED/eforms-notice-editor/blob/develop/src/main/resources/static/js/custom/global.js
 */
export const EformsConstants = {
  DEBUG: true,

  DATA_EDITOR_ID_REFERENCE: 'data-editor-id-reference',
  DATA_EDITOR_ID_REF_PREFIX: 'data-editor-id-ref-',
  DATA_EDITOR_ID_SCHEME: 'data-id-scheme',

  Attributes: {
    CONTENT_ID_ATTRIBUTE: 'data-content-id',
    CONTENT_TYPE_ATTRIBUTE: 'data-content-type', // For the VIS TYPE
    NODE_ID_ATTRIBUTE: 'data-node-id',
    ID_SCHEME_ATTRIBUTE: 'data-id-scheme',
    ID_SCHEMES_ATTRIBUTE: 'data-id-schemes',
    VALUE_SOURCE_ATTRIBUTE: 'data-value-source',
    COUNTER_ATTRIBUTE: 'data-counter'
  },

  HtmlElements: {
    FORM_ELEMENT: 'form-element',
    FORM_CONTENT_ROOT_ELEMENT: 'form-content-root',
    APP_VERSION_ELEMENT: 'app-version'
  },

  // Avoids conflicts with other identifiers in the context of this browser tab.
  EDITOR_ID_PREFIX: 'editor-id-',

  VIS_CONTENT_COUNT: 'contentCount',
  VIS_VALUE: 'value',
  VIS_TYPE: 'visType', // Has nothing to do with HTML element type!
  VIS_TYPE_FIELD: 'field',
  VIS_TYPE_NON_FIELD: 'non-field',

  NTD_LABEL: '_label',

  ContentType: {
    FIELD: 'field',
    GROUP: 'group',
    DATA_CONTAINER: 'notice-data',
    METADATA_CONTAINER: 'notice-metadata',
    ROOT: 'notice'
  },

  StandardIdentifiers: {
    ND_ROOT: 'ND-Root',
    SDK_VERSION_FIELD: 'OPT-002-notice',
    UBL_VERSION_FIELD: 'OPT-001-notice',
    NOTICE_SUBTYPE_FIELD: 'OPP-070-notice',
    NOTICE_UUID_FIELD: 'BT-701-notice',
    NOTICE_VERSION_FIELD: 'BT-757-notice',
    NOTICE_PUBLICATION_NUMBER: 'OPP-010'
  }
};
