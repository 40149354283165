export enum MainRoute {
  DETALJEVISNING = 'Detaljevisning',
  FORSIDE = 'Forside',
  PLAYGROUND = 'playground',
  DEMOEKSEMPLER = 'demoeksempler',
  EFORMSFELTERDEMO = 'eFormsFelterDemo',
  EFORMSNOTICETYPEDEMO = 'eFormsNoticeTypeDemo',
  KONFIGURATION = 'Konfiguration',
  INDSTILLINGER = 'Indstillinger',
  SOEGNING = 'Søgning',
  OPRET_AENDRING = 'Opret ændring',
  OPRET_INDKOEB = 'Opret indkøb',
  ORDREGIVER = 'Ordregiver',
  UDBUDSAGENT = 'Udbudsagent',
  UDBUD_DK = 'udbud.dk - Find samarbejde mellem virksomheder og det offentlige',
  KVITTERING = 'Kvittering',
  ARKIV = 'Arkiv'
}
