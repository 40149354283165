<template>
  <span id="textarea-limit-limit-message" class="sr-only">{{ $t('input.hint.antaltegn.max', { antal_max: maxlength }) }}</span>
  <span v-if="skalViseAntalTegnTilbage()" aria-hidden="true" class="form-hint character-limit">{{
    $t('input.hint.antaltegn.tilbage', { antal_tilbage: tegnTilbage() })
  }}</span>
  <span v-if="skalViseAntalTegnTilbage()" aria-live="polite" class="character-limit-sr-only sr-only">{{
    $t('input.hint.antaltegn.tilbage', { antal_tilbage: tegnTilbage() })
  }}</span>
  <span v-if="skalViseAntalTegnOver()" aria-hidden="true" class="form-hint character-limit limit-exceeded">{{
    $t('input.hint.antaltegn.over', { antal_over: tegnOver() })
  }}</span>
  <span v-if="skalViseAntalTegnOver()" aria-live="polite" class="character-limit-sr-only sr-only">{{
    $t('input.hint.antaltegn.over', { antal_over: tegnOver() })
  }}</span>
</template>

<script lang="ts" setup>
const props = defineProps({
  maxlength: {
    type: Number,
    required: true
  },
  antalTegn: {
    type: Number,
    required: true
  }
});

function skalViseAntalTegnTilbage(): boolean {
  if (props.maxlength > 0) {
    return tegnOver() === 0;
  }
  return false;
}

function skalViseAntalTegnOver(): boolean {
  if (props.maxlength > 0) {
    return tegnOver() > 0;
  }
  return false;
}

function tegnTilbage(): number {
  let limit = props.maxlength - props.antalTegn;
  if (limit < 0) {
    limit = 0;
  }
  return limit;
}

function tegnOver(): number {
  let limit = props.antalTegn - props.maxlength;
  if (limit < 0) {
    limit = 0;
  }
  return limit;
}
</script>
<style lang="scss" scoped>
@import '../../styles/input';
</style>
