/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Vis alle eller kun aktive udbud
 */
export type SoegningQueryDtoUdbudStatusFilter = (typeof SoegningQueryDtoUdbudStatusFilter)[keyof typeof SoegningQueryDtoUdbudStatusFilter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SoegningQueryDtoUdbudStatusFilter = {
  AKTIV: 'AKTIV',
  ALLE: 'ALLE'
} as const;
