<template>
  <button :class="{ toggled: isBold() }" aria-label="bold" class="button-unstyled rich-tekst-menu-button" @click="toggleBold" type="button">
    <svg aria-hidden="true" class="icon-svg mr-0 mt-0 icon-svg--inherit-color" focusable="false">
      <use xlink:href="#format-bold" />
    </svg>
  </button>
  <button :class="{ toggled: isItalic() }" aria-label="italic" class="button-unstyled rich-tekst-menu-button" @click="toggleItalic" type="button">
    <svg aria-hidden="true" class="icon-svg mr-0 mt-0 icon-svg--inherit-color" focusable="false">
      <use xlink:href="#format-italic" />
    </svg>
  </button>
  |
  <button v-if="includeLinks" aria-label="toggle-link" class="button-unstyled rich-tekst-menu-button" @click="toggleLink" type="button">
    <svg aria-hidden="true" class="icon-svg mr-0 mt-0 icon-svg--inherit-color" focusable="false">
      <use xlink:href="#link" />
    </svg>
  </button>
  {{ includeLinks ? '|' : '' }}
  <button aria-label="bullet-list" class="button-unstyled rich-tekst-menu-button" @click="toggleButtonList" type="button">
    <svg aria-hidden="true" class="icon-svg mr-0 mt-0 icon-svg--inherit-color" focusable="false">
      <use xlink:href="#format-list-bulleted" />
    </svg>
  </button>
  <button aria-label="number-list" class="button-unstyled rich-tekst-menu-button" @click="toggleOrderedList" type="button">
    <svg aria-hidden="true" class="icon-svg mr-0 mt-0 icon-svg--inherit-color" focusable="false">
      <use xlink:href="#format-list-numbered" />
    </svg>
  </button>
  <hr />
  <editor-content :id="inputFeltId" :editor="editor" :name="inputFeltId" aria-describedby="textarea-limit-limit-message" class="rich-tekst-content" />
</template>

<script lang="ts" setup>
import Link from '@tiptap/extension-link';
import StarterKit from '@tiptap/starter-kit';
import { Editor, EditorContent, Extension, Mark } from '@tiptap/vue-3';

import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';

const props = defineProps({
  textPrefix: {
    type: String,
    required: true
  },
  includeLinks: {
    type: Boolean,
    default: false
  },
  preselectedValue: {
    type: String,
    default: ''
  },
  inputFeltId: {
    type: String,
    required: true
  }
});

const emit = defineEmits([StandardEmitEvent.UPDATE, StandardEmitEvent.BLUR]);

let extensionList: (Extension | Mark)[] = [
  StarterKit.configure({
    blockquote: false,
    codeBlock: false,
    code: false,
    strike: false,
    heading: false,
    horizontalRule: false
  })
];

if (props.includeLinks) {
  const linkModule = Link.configure({
    protocols: []
  });
  extensionList = extensionList.concat(linkModule);
}

const editor = new Editor({
  content: props.preselectedValue,
  extensions: extensionList
});

function getHtml(): string {
  return editor?.getHTML();
}

function update() {
  emit(StandardEmitEvent.UPDATE);
}

editor.on(StandardEmitEvent.UPDATE, update);

function blur() {
  emit(StandardEmitEvent.BLUR);
}

editor.on(StandardEmitEvent.BLUR, blur);

function toggleBold() {
  editor?.chain().focus().toggleBold().run();
}

function toggleItalic() {
  editor?.chain().focus().toggleItalic().run();
}

function toggleButtonList() {
  editor?.chain().focus().toggleBulletList().run();
}

function toggleOrderedList() {
  editor?.chain().focus().toggleOrderedList().run();
}

function toggleLink() {
  editor?.chain().focus().unsetLink().run();
}

function isBold(): boolean {
  return editor?.isActive('bold');
}

function isItalic(): boolean {
  return editor?.isActive('italic');
}

function getTrimmed(): string {
  return getHtml().replaceAll(new RegExp('</?(p|ul|li|i|b|br|ol|strong|em)>+', 'g'), '').trim();
}

defineExpose({
  getTrimmed,
  getHtml
});
</script>
<style lang="scss" scoped>
@import '../../styles/input';

:deep(:focus-visible) {
  outline: none;
}
</style>
