<template>
  <div class="modal-body" data-cy="privat-person-nag-modal">
    <div v-if="client?.includes(ClientSuffix.EID_NATURAL)">
      <RichTextRenderer v-if="$tm('login.modal.eid.privat-person.rig-tekst')" :document="$tm('login.modal.eid.privat-person.rig-tekst')" />
    </div>
    <div v-else>
      <RichTextRenderer v-if="$tm('login.modal.privat-person.rig-tekst')" :document="$tm('login.modal.privat-person.rig-tekst')" />
    </div>
  </div>
  <div class="modal-footer">
    <button @click="onLogout()" class="button button-primary logout-btn" type="button" data-cy="privatperson-nag-logout">
      <svg class="icon-svg" focusable="true" aria-hidden="true">
        <use xlink:href="#lock-open" />
      </svg>
      {{ $t('topnav.login.logud-label') }}
    </button>
    <button @click="onClose()" class="button button-secondary" type="button" data-cy="privatperson-nag-fortsaet">
      <svg class="icon-svg" focusable="true" aria-hidden="true">
        <use xlink:href="#lock" />
      </svg>
      {{ $t('login.modal.privat-person.fortsaet-som-privat-person-label') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

import { ClientSuffix } from '@/main/enums/clients.enum';
import { authState } from '@/main/stores/auth.state';
import { useModalStore } from '@/main/stores/modal.state';

const store = useModalStore(window.pinia);
const client = authState.state?.getProfile('aud');

async function onLogout() {
  await authState?.state?.logout().catch(err => {
    console.error(err);
  });
}

async function onClose() {
  await authState.hentBrugerprofilVedLoginOgVisModalHvisMangler();
  if (store.$state.modalId === 'login.modal.privat-person') {
    store.closeModal();
  }
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/modal';
</style>
