<template>
  <div class="publicerings-dato p-4" data-cy="publicerings-filter-vindue">
    <h2 class="h4 m-0" data-cy="publicerings-filter-overskrift">{{ $t('filter.' + konfigurationNavn + '.overskift') }}</h2>
    <p data-cy="publicerings-filter-broedtekst">{{ $t('filter.' + konfigurationNavn + '.manchet') }}</p>
    <FormGroup :show-opsummering="false" ref="formGroup">
      <div class="form-error-message" v-if="!isEmpty(errors)">{{ $t('filter.' + konfigurationNavn + '.fejlmeddelelse') }}</div>
      <div class="row pl-4 pb-2">
        <InputDatovaelger
          :label="$t('filter.' + konfigurationNavn + '.datovaelger.Fra.label')"
          ref="datovaelgerFraFelt"
          :bind-format="dateUtil.standardDatoFormat"
          :show-hint="false"
          :vis-generel-fejlmeddelse="showCommonErrorMessage"
          data-cy="datovaelger-fra-felt"
          name="datovaelgerFraFelt"
          :text-prefix="'filter.' + konfigurationNavn + '.datovaelger.Fra'"
          :rules="'filterdateBefore:@datovaelgerTilFelt|validDate'"
          :preselected-value="getSelectedCustomValue(props.konfigurationNavn, DatoType.DATO_FRA)"
          :sync-value="getSelectedCustomValue(props.konfigurationNavn, DatoType.DATO_FRA)"
          :filter-cleared="soegningStore.itemClicked"
          @change="fromChanged" />
        <div class="mt-4 ml-4">
          <div v-if="!isMobile" class="arrow" />
        </div>
        <InputDatovaelger
          :label="$t('filter.' + konfigurationNavn + '.datovaelger.Til.label')"
          ref="datovaelgerTilFelt"
          :bind-format="dateUtil.standardDatoFormat"
          :show-hint="false"
          :vis-generel-fejlmeddelse="showCommonErrorMessage"
          data-cy="datovaelger-til-felt"
          name="datovaelgerTilFelt"
          class="mt-sm-4 mt-md-0 mr-4"
          :text-prefix="'filter.' + konfigurationNavn + '.datovaelger.Til'"
          :rules="'filterdateAfter:@datovaelgerFraFelt|validDate'"
          :preselected-value="getSelectedCustomValue(props.konfigurationNavn, DatoType.DATO_TIL)"
          :sync-value="getSelectedCustomValue(props.konfigurationNavn, DatoType.DATO_TIL)"
          :filter-cleared="soegningStore.itemClicked"
          @change="toChanged" />
      </div>
    </FormGroup>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { PropType, computed, ref, watch } from 'vue';

import FormGroup from '@/main/components/input/FormGroup.vue';
import InputDatovaelger from '@/main/components/input/InputDatovaelger.vue';
import { CustomFilterKonfigurationNavn, DatoType } from '@/main/enums/filter/filterKonfigurationNavn.enum';
import { InputFejl } from '@/main/enums/inputFejl.enum';
import { StandardEmitEvent } from '@/main/enums/standardEmitEvent.enum';
import { Visning } from '@/main/enums/visning.enum';
import { useSoegningValgStore } from '@/main/stores/soegning.state';
import { dateUtil } from '@/main/utils/date-util';

const props = defineProps({
  konfigurationNavn: {
    type: String as PropType<CustomFilterKonfigurationNavn>,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const formGroup = ref<InstanceType<typeof FormGroup>>();
const screenWidth = ref(window.innerWidth);
const isMobile = computed(() => screenWidth.value < Visning.GRID_BREAKPOINT_MD);
const soegningStore = useSoegningValgStore(window.pinia);
const errors = ref();
const showCommonErrorMessage = ref(true);
const { getSelectedCustomValue } = storeToRefs(soegningStore);
const datovaelgerFraFelt = ref<InstanceType<typeof InputDatovaelger>>();
const datovaelgerTilFelt = ref<InstanceType<typeof InputDatovaelger>>();

const emit = defineEmits<{
  (e: StandardEmitEvent.FROM_CHANGE, konfigurationNavn: CustomFilterKonfigurationNavn, value: string): string;
  (e: StandardEmitEvent.TO_CHANGE, konfigurationNavn: CustomFilterKonfigurationNavn, value: string): void;
}>();

const fromChanged = (value: string | undefined) => {
  emit(StandardEmitEvent.FROM_CHANGE, props.konfigurationNavn, value as string);
};

const toChanged = (value: string | undefined) => {
  emit(StandardEmitEvent.TO_CHANGE, props.konfigurationNavn, value as string);
};

watch(
  () => formGroup.value?.errors,
  () => {
    errors.value = formGroup.value?.errors;
    showCommonErrorMessage.value = true;
    if (containsString(errors.value, InputFejl.IKKE_GYLDIG_DATO)) {
      showCommonErrorMessage.value = false;
    }
    soegningStore.setValidationError(isEmpty(errors.value));
  }
);

function isEmpty(errorObject: Partial<Record<string, string | undefined>> | undefined): boolean {
  if (errorObject === undefined) {
    return true;
  }
  return Object.keys(errorObject).length === 0;
}

function containsString(errorObject: Partial<Record<string, string | undefined>> | undefined, str: string): boolean {
  if (errorObject) {
    for (const key in errorObject) {
      if (errorObject[key] === str) {
        return true;
      }
    }
  }
  return false;
}
</script>
<style lang="scss" scoped>
@import '../../../styles/components/soegning/filter/_filterPubliceringsDato.scss';
</style>
