class InputFormatUtil {
  public static fjernWhitespace(value: string): string {
    let norm = value.trim();
    const remove = new RegExp(' ', 'g');
    norm = norm.replaceAll(remove, '');
    return norm;
  }

  public static formaterTelefon(value: string): string {
    let norm = value.trim();
    if (norm.startsWith('00')) {
      norm = norm.replace('00', '+');
    }
    const remove = new RegExp('[ ()]', 'g');
    norm = norm.replaceAll(remove, '');
    if (norm.startsWith('00')) {
      norm = norm.replace('00', '+');
    }
    return norm;
  }

  public static formaterHeltalVisning(value: string): string {
    let norm = value.trim();
    const remove = new RegExp('[., ]', 'g');
    norm = norm.replaceAll(remove, '');
    const parsed = parseInt(norm);
    if (!isNaN(parsed)) {
      return parsed.toLocaleString('da-DK', { maximumFractionDigits: 0 });
    } else {
      return value;
    }
  }

  public static formaterHeltalVisningFraUSFormatTilDK(value: string): string {
    const parsed = parseFloat(value);
    if (!isNaN(parsed)) {
      return parsed.toLocaleString('da-DK', { maximumFractionDigits: 0 });
    } else {
      return value;
    }
  }

  public static formatterNumber(value: string): string {
    const parsed = parseInt(value.replaceAll(/[., ]/g, ''), 10);
    return isNaN(parsed) ? value : parsed.toString();
  }

  public static erValidValutaFormatAmerican(input: string): boolean {
    if (this.isValidSpecificFormat(input)) {
      return true;
    }
    const trimmedInput = input.trim();

    const regexPattern = /^-?(\d{1,3}(?:[ ,]\d{3})*)(\.\d{2})?$/;
    if (!regexPattern.test(trimmedInput)) {
      return false;
    }
    const hasMultipleDecimals = (trimmedInput.match(/\./g) || []).length > 1;
    const hasMultipleCommas = (trimmedInput.match(/,/g) || []).length > 1;
    const hasMultipleSpaces = (trimmedInput.match(/ /g) || []).length > 1;

    if (hasMultipleDecimals || (hasMultipleCommas && hasMultipleSpaces)) {
      return false;
    }
    const sanitizedInput = trimmedInput.replace(/^-/, '').replace(/,/g, '').replace(/ /g, '');
    const parts = sanitizedInput.split('.');
    const decimalPart = parts.length > 1 ? parts[1] : '';
    if (decimalPart.length > 2) {
      return false;
    }

    return true;
  }

  public static erValidValutaFormatScandinavian(input: string): boolean {
    if (this.isValidSpecificFormat(input)) {
      return true;
    }
    const trimmedInput = input.trim();
    const regexPattern = /^-?(\d{1,3}(?:[.\s]\d{3})*)(,\d{2})?$/;
    if (!regexPattern.test(trimmedInput)) {
      return false;
    }

    const hasMultipleCommas = (trimmedInput.match(/,/g) || []).length > 1;
    const hasMultipleDots = (trimmedInput.match(/\./g) || []).length > 1;
    const hasMultipleSpaces = (trimmedInput.match(/\s/g) || []).length > 1;

    if (hasMultipleCommas || (hasMultipleDots && hasMultipleSpaces)) {
      return false;
    }

    const sanitizedInput = trimmedInput.replace(/^-/, '').replace(/\./g, '').replace(/\s/g, '');
    const parts = sanitizedInput.split(',');
    const decimalPart = parts.length > 1 ? parts[1] : '';
    if (decimalPart.length !== 0 && decimalPart.length !== 2) {
      return false;
    }
    return true;
  }

  public static isValidSpecificFormat(input: string): boolean {
    //komma eller punktum før de 2 sidste tal i input
    const regex = /^\d+([,.]\d{2})?$/;
    return regex.test(input);
  }

  public static isValidSpecificFormatSkandinavisk(input: string): boolean {
    //komma før de 2 sidste tal i input
    const regex = /^\d+([,]\d{2})?$/;
    return regex.test(input);
  }

  public static hasNoSeparators(value: string) {
    const regex = /^-?\d+$/;
    return regex.test(value);
  }

  public static formaterValutaVisningMedPunktumOgKomma(value: string): string {
    const isAmericanFormat = this.erValidValutaFormatAmerican(value);
    const isScandinavianFormat = this.erValidValutaFormatScandinavian(value);
    const isJustANumber = this.hasNoSeparators(value);

    if (isAmericanFormat || isScandinavianFormat || isJustANumber) {
      let sanitizedValue = value.replace(/\s/g, '');
      let valuta: number;
      if (isAmericanFormat) {
        valuta = parseFloat(sanitizedValue.replace(/,/g, ''));
      } else if (isScandinavianFormat || isJustANumber) {
        valuta = parseFloat(sanitizedValue.replace(/\./g, '').replace(',', '.'));
      } else {
        return value;
      }
      if (!isNaN(valuta)) {
        if (this.isValidExactFormat(value)) {
          return value;
        } else if (this.isValidSpecificFormatSkandinavisk(value)) {
          sanitizedValue = value.replace(/\./g, '').replace(',', '.');
          valuta = parseFloat(sanitizedValue);
          return valuta.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
        return valuta.toLocaleString('da-DK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      } else {
        return value;
      }
    }
    return value;
  }

  public static isValidExactFormat(value: string): boolean {
    const regexPattern = /^\d{3},\d{2}$/;
    return regexPattern.test(value.trim());
  }

  public static formaterCurrency(value: string): string {
    const valuta = this.valutaStringTilFloat(value);
    if (!isNaN(valuta)) {
      return valuta.toString();
    } else {
      return value;
    }
  }

  public static formaterValutaVisning(value: string): string {
    const valuta = this.valutaStringTilFloat(value);
    if (!isNaN(valuta)) {
      return valuta.toLocaleString('da-DK', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    } else {
      return value;
    }
  }

  private static valutaStringTilFloat(value: string): number {
    const remove = new RegExp('[, .]', 'g');
    let norm = value.trim();
    if (value.lastIndexOf(',') === value.length - 3 && value.length > 2) {
      const fraction = value.substring(value.length - 2);
      norm = norm.substr(0, value.length - 3);
      norm = norm.replaceAll(remove, '');
      norm = norm + '.' + fraction;
    } else {
      norm = norm.replaceAll(remove, '');
    }
    return parseFloat(norm);
  }
}

export default InputFormatUtil;
