<template>
  <div class="modal-body" data-cy="login-modal">
    <div class="spacer"></div>
    <button class="mitid-box" @click="onLogin('mitId')" data-cy="mitid-box" type="button">
      <div class="mitid-container row text-center">
        <div class="mitid-logo col-12 col-md-5 d-flex align-items-center justify-content-center">
          <ContentfulRigTekst :document="$tm('login.modal.logo')" />
        </div>
        <span class="mitid-tekst h4 col-12 col-md-7 d-flex align-items-center justify-content-center">
          {{ $t('login.mitid.link.label') }}
        </span>
      </div>
    </button>
    <div class="spacer"></div>
    <div class="andre-login">
      <h3 class="h3">
        {{ $t('login.andre-login-overskrift') }}
      </h3>
      <div>
        <p>
          <span>{{ $t('login.eid.legal.forklaring') }}</span>
          <button class="button-unstyled" @click="onLogin('eidLegal')" data-cy="eidlegal-btn" type="button">
            {{ $t('login.eid.legal.link.label') }}
          </button>
        </p>
        <p>
          <span>{{ $t('login.eid.natural.forklaring') }}</span>
          <button class="button-unstyled" @click="onLogin('eidNatural')" data-cy="eidnatural-btn" type="button">
            {{ $t('login.eid.natural.link.label') }}
          </button>
        </p>
        <p>
          <span>{{ $t('login.email.link.forklaring') }}</span>
          <button class="button-unstyled" @click="onLogin('ssi')" data-cy="email-adgangskode-btn" type="button">
            {{ $t('login.email.link.label') }}
          </button>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ContentfulRigTekst from '@/main/components/ContentfulRigTekst.vue';
import { authState } from '@/main/stores/auth.state';

const props = defineProps<{
  redirectPath?: string;
}>();

async function onLogin(loginType: 'mitId' | 'eidNatural' | 'eidLegal' | 'ssi' | 'default' = 'default') {
  sessionStorage.setItem('loginType', loginType);

  authState.initState().then(() => {
    let redirectPath = props.redirectPath;
    if (!redirectPath) {
      const { pathname, search } = window.location;
      const basePath = pathname.startsWith('/udbud') ? pathname.replace('/udbud', '') : pathname;
      redirectPath = `${basePath}${search}`;
    }
    authState?.state?.login({ redirectPath }).catch(err => {
      console.error(err);
    });
  });
}
</script>

<style scoped lang="scss">
@import 'src/main/styles/_modal';
@import '@/main/styles/_loginModal';
</style>
