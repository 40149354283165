/**
 * Generated by orval v6.29.1 🍺
 * Do not edit manually.
 */

/**
 * Hvilken retning der sorteres efter
 */
export type PagineringDtoRetning = (typeof PagineringDtoRetning)[keyof typeof PagineringDtoRetning];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PagineringDtoRetning = {
  Asc: 'Asc',
  Desc: 'Desc'
} as const;
